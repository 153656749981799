import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable, signal, WritableSignal } from '@angular/core';
import { Breakpoint } from '@models';
import { BehaviorSubject, map, Observable } from 'rxjs';

export enum ThemeStyle {
  Light = 'light',
  Dark = 'dark',
}

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  public theme$: BehaviorSubject<ThemeStyle>;
  public isLandscape$: Observable<boolean>;

  public theme: WritableSignal<ThemeStyle>;

  private prefersDarkMediaQuery: MediaQueryList | null;

  constructor(breakpointObserver: BreakpointObserver) {
    this.prefersDarkMediaQuery = null;

    if (window.matchMedia) {
      this.prefersDarkMediaQuery = window.matchMedia(
        '(prefers-color-scheme: dark)',
      );

      this.prefersDarkMediaQuery.addEventListener('change', (event) => {
        if (event.matches) {
          this.theme.set(ThemeStyle.Dark);
          this.theme$.next(ThemeStyle.Dark);
        } else {
          this.theme.set(ThemeStyle.Light);
          this.theme$.next(ThemeStyle.Light);
        }
      });
    }

    this.theme = signal(
      this.prefersDarkMediaQuery?.matches ? ThemeStyle.Dark : ThemeStyle.Light,
    );

    this.theme$ = new BehaviorSubject<ThemeStyle>(
      this.prefersDarkMediaQuery?.matches ? ThemeStyle.Dark : ThemeStyle.Light,
    );

    this.isLandscape$ = breakpointObserver
      .observe([Breakpoint.Medium])
      .pipe(map((result: BreakpointState) => result.matches));
  }
}
