import {
  Component,
  EventEmitter,
  Input,
  Output,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormControlStatus,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Observable, first, of } from 'rxjs';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormControlSearchComponent),
            multi: true,
        },
    ],
    standalone: false
})
export class FormControlSearchComponent implements ControlValueAccessor {
  @Input()
  isLoading$: Observable<boolean>;

  @Input()
  tabindex: number = 0;

  @Input()
  placeholder: string = 'Search in datasets';

  @Output()
  focusChange: EventEmitter<{ isFocused: boolean; event: FocusEvent }>;

  public isDisabled: boolean;
  public inputFormControl: FormControl;
  public isAdvancedSearchOpen: boolean;

  constructor() {
    this.isLoading$ = of(false);

    this.focusChange = new EventEmitter<{
      isFocused: boolean;
      event: FocusEvent;
    }>();

    this.isAdvancedSearchOpen = false;
    this.isDisabled = false;
    this.inputFormControl = new FormControl();
  }

  public blur(event: Event) {
    (event.target as HTMLInputElement)?.blur();
  }

  writeValue(obj: string): void {
    this.inputFormControl.setValue(obj, {
      emitEvent: false,
    });
  }

  registerOnChange(fn: (v: string) => void): void {
    this.inputFormControl.valueChanges.subscribe((v: string) => {
      fn(v);
    });
  }

  registerOnTouched(fn: (v: FormControlStatus) => void): void {
    this.inputFormControl.statusChanges
      .pipe(first(() => this.inputFormControl.touched))
      .subscribe((v: FormControlStatus) => {
        fn(v);
      });
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
