<!--https://apastyle.apa.org/style-grammar-guidelines/references/examples/data-set-references-->
<!--https://citation.doi.org/-->
@switch (type()) {
  @case ('apa') {
    <span
      >{{ authorsString() }} ({{ publicationYear() }}).
      <em>{{ dataset().content.title }}</em> [Dataset]. Norwegian Polar
      Institute.
      <a
        href="{{ url() }}"
        target="_blank"
        rel="nofollow noopener noreferrer"
        >{{ url() }}</a
      ></span
    >
  }
  @case ('short') {
    <span
      >{{ authorsString() }} ({{ publicationYear() }}).
      <em>{{ dataset().content.title }}</em> [Dataset]. Norwegian Polar
      Institute.</span
    >
  }
}
