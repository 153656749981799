<h2 mat-dialog-title>Edit file</h2>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <mat-form-field appearance="outline">
      <mat-label>Filename</mat-label>
      <input type="text" matInput formControlName="filename" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Prefix</mat-label>
      <input type="text" matInput formControlName="prefix" />
      @if (form.controls['prefix'].hasError('leadingslash')) {
        <mat-error>Prefix must start with '/'</mat-error>
      } @else if (form.controls['prefix'].hasError('trailingslash')) {
        <mat-error>Prefix must end with '/'</mat-error>
      }
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Title</mat-label>
      <input type="text" matInput formControlName="title" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Description</mat-label>
      <textarea rows="4" matInput formControlName="description"></textarea>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Release date</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="releaseDate" />
      <mat-hint>yyyy-MM-DD</mat-hint>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-flat-button type="submit" [disabled]="!form.valid">
      Save
    </button>
    <button mat-button type="button" (click)="onCancel()"> Cancel </button>
  </mat-dialog-actions>
</form>
