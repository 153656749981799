import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Dataset, DatasetContent, FormState, Organisation } from '@models';
import { DatasetFormOrganisationFormComponent } from '../form/form.component';
import { BehaviorSubject } from 'rxjs';
import { DatasetService, NotificationService } from '@services';
import { MatSelectionListChange } from '@angular/material/list';

@Component({
  selector: 'app-dataset-form-organisation-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  standalone: false,
})
export class DatasetFormOrganisationListComponent implements OnInit {
  @Input({ required: true })
  dataset!: Dataset;

  @Input({ required: true })
  formState!: BehaviorSubject<FormState>;

  public dataSource: MatTableDataSource<Organisation>;
  public selection: SelectionModel<Organisation>;

  constructor(
    private dialog: MatDialog,
    private datasetService: DatasetService,
    private notificationService: NotificationService,
  ) {
    this.dataSource = new MatTableDataSource<Organisation>();
    this.selection = new SelectionModel<Organisation>(true, []);
  }

  ngOnInit() {
    this.dataSource.data.push(...(this.dataset.content.organisations || []));
    this.updateValidity();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected == numRows;
  }

  toggleAllRows() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  public openForm(organisation?: Organisation | null) {
    const dialogRef = this.dialog.open(DatasetFormOrganisationFormComponent, {
      data: organisation,
      disableClose: true,
      minWidth: '360px',
    });

    dialogRef
      .afterClosed()
      .subscribe((updatedOrganisation: Organisation | null) => {
        if (!updatedOrganisation) {
          return;
        }

        const datasetContent = {
          ...this.dataset.content,
        };

        datasetContent.organisations = [
          ...(datasetContent.organisations || []),
        ];

        if (organisation) {
          const index = datasetContent.organisations.indexOf(organisation);
          if (index !== -1) {
            datasetContent.organisations[index] = updatedOrganisation;
          }
        } else {
          datasetContent.organisations.push(updatedOrganisation);
        }

        this.updateDataset(datasetContent);
      });
  }

  public onDelete() {
    const organisations = [];
    if (this.dataset.content?.organisations?.length) {
      for (const organisation of this.dataset.content.organisations) {
        const index = this.selection.selected.indexOf(organisation);
        if (index === -1) {
          organisations.push(organisation);
        }
      }
    }

    const datasetContent = {
      ...this.dataset.content,
    };

    if (!organisations.length) {
      delete datasetContent.organisations;
    } else {
      datasetContent.organisations = organisations;
    }

    this.updateDataset(datasetContent);
  }

  public onListSelectionChange(event: MatSelectionListChange) {
    for (const option of event.options) {
      if (option.selected) {
        this.selection?.select(option.value);
      } else {
        this.selection?.deselect(option.value);
      }
    }
  }

  private updateDataset(datasetContent: DatasetContent) {
    this.datasetService
      .updateDataset(this.dataset.id, datasetContent)
      .subscribe({
        next: () => {
          this.dataSource.data = [...(datasetContent.organisations || [])];
          this.dataset.content = datasetContent;
          this.selection.clear();
          this.updateValidity();
        },
        error: (err) => {
          console.error(err);
          this.notificationService.error('Failed to save changes.');
        },
      });
  }

  private updateValidity() {
    if (this.dataSource.data.length) {
      this.formState.next(FormState.Valid);
    } else {
      this.formState.next(FormState.Invalid);
    }
  }
}
