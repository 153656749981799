import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Dataset, DatasetContent, FormState, Harvester } from '@models';
import { DatasetService, NotificationService } from '@services';
import { BehaviorSubject } from 'rxjs';

class HarvesterField {
  label!: string;
  data!: Harvester;
  checked!: boolean;

  constructor(label: string, data: Harvester) {
    this.label = label;
    this.data = data;
    this.checked = false;
  }

  public get value() {
    return JSON.stringify(this.data);
  }
}

@Component({
  selector: 'app-dataset-form-harvesters',
  templateUrl: './harvesters.component.html',
  styleUrls: ['./harvesters.component.scss'],
  standalone: false,
})
export class DatasetFormHarvestersComponent implements OnInit {
  @Input({ required: true })
  dataset!: Dataset;

  @Input({ required: true })
  formState!: BehaviorSubject<FormState>;

  public form: FormGroup;
  harvesterFieldRegistry!: { [key: string]: HarvesterField };

  constructor(
    private datasetService: DatasetService,
    private notificationService: NotificationService,
  ) {
    this.form = new FormGroup({
      nmdc: new FormControl('', []),
      sios: new FormControl('', []),
      aen: new FormControl('', []),
    });
    this.harvesterFieldRegistry = {
      aen: new HarvesterField('the Nansen Legacy', {
        set: 'aen',
        url: 'https://arvenetternansen.com/',
      }),
      nmdc: new HarvesterField('NMDC (Norwegian Marine Data Centre)', {
        set: 'nmdc',
        url: 'https://www.nmdc.no/',
      }),
      sios: new HarvesterField(
        'SIOS (Svalbard Integrated Arctic Earth Observing System)',
        { set: 'sios', url: 'https://sios-svalbard.org/' },
      ),
    };
  }

  get harvesterfields(): Array<HarvesterField> {
    return Object.values(this.harvesterFieldRegistry);
  }

  ngOnInit() {
    if (this.dataset.content.harvesters) {
      for (const h of this.dataset.content.harvesters) {
        if (h.set in this.harvesterFieldRegistry) {
          this.harvesterFieldRegistry[h.set].checked = true;
        } else {
          console.warn('Unhandled harvester', h.set);
        }
      }
    }
  }

  onChange($event: MatCheckboxChange) {
    const set = $event.source.name;
    // update local registry
    if (set && set in this.harvesterFieldRegistry) {
      this.harvesterFieldRegistry[set].checked = $event.source.checked;
    }

    const datasetContent = {
      ...this.dataset.content,
      harvesters: [] as Harvester[],
    };

    for (const h of this.harvesterfields) {
      if (h.checked) {
        datasetContent.harvesters.push(h.data);
      }
    }

    this.updateDataset(datasetContent);
  }

  private updateDataset(datasetContent: DatasetContent) {
    this.datasetService
      .updateDataset(this.dataset.id, datasetContent)
      .subscribe({
        next: () => {
          this.dataset.content = datasetContent;
        },
        error: (err) => {
          console.error(err);
          this.notificationService.error('Failed to save changes.');
        },
      });
  }
}
