import { Pipe, PipeTransform } from '@angular/core';
import { Citation, Dataset } from '@models';

// transforms a list of names as to a single string in APA style
@Pipe({
    name: 'nameListString',
    standalone: false
})
export class NameListStringPipe implements PipeTransform {
  transform(dataset: Dataset): string {
    const citation = new Citation(dataset);
    return citation.authors;
  }
}
