import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Permission, PermissionListResponse, User } from '@models';
import { PermissionService, NotificationService, AuthService } from '@services';
import { Observable, Subscription, from, map, mergeAll } from 'rxjs';
import { PermissionFormComponent } from '../form/form.component';
import { PromptComponent } from '@components/prompt/prompt.component';
import { MatSelectionListChange } from '@angular/material/list';

@Component({
    selector: 'app-permission-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    standalone: false
})
export class PermissionTableComponent implements OnInit {
  @Input()
  public datasetId = '';

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  public dataSource: MatTableDataSource<Permission>;
  public selection: SelectionModel<Permission>;
  public currentUser: User | null;

  private subscriptions: Subscription[];

  constructor(
    private notificationService: NotificationService,
    private dialog: MatDialog,
    public authService: AuthService,
    public permissionService: PermissionService,
  ) {
    this.dataSource = new MatTableDataSource<Permission>();
    this.selection = new SelectionModel<Permission>(true, []);
    this.subscriptions = [];
    this.currentUser = null;
  }

  ngOnInit() {
    const userSubscription = this.authService.user$.subscribe(
      (user: User | null) => {
        this.currentUser = user;
      },
    );
    this.subscriptions.push(userSubscription);

    this.reloadData();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    let numRows = this.dataSource.data.length;

    if (this.isCurrentUserInView()) {
      numRows--;
    }

    return numSelected == numRows;
  }

  isCurrentUserInView() {
    return !!this.dataSource.data.find(
      (p: Permission) => p.userId === this.currentUser?.id,
    );
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.dataSource.data.forEach((row) => {
        if (row.userId !== this.currentUser?.id) {
          this.selection.select(row);
        }
      });
    }
  }

  loadPage(event: PageEvent, doCount: boolean) {
    this.permissionService
      .getPermissions(
        this.datasetId,
        event.pageIndex * event.pageSize,
        event.pageSize,
        doCount,
      )
      .subscribe({
        next: (result: PermissionListResponse) => {
          if (doCount) {
            this.paginator.length = result.count;
          }

          this.dataSource = new MatTableDataSource<Permission>(result.items);
        },
        error: (err) => {
          console.error(err);

          if (err?.status !== 401) {
            this.notificationService.error('Failed to load permissions.');
          }
        },
      });
  }

  public openForm(permission?: Permission) {
    const dialogRef = this.dialog.open(PermissionFormComponent, {
      data: permission,
      minWidth: '360px',
    });

    dialogRef
      .afterClosed()
      .subscribe((updatedPermission: Permission | null) => {
        if (!updatedPermission) {
          return;
        }

        let actionResult: Observable<Permission>;

        if (permission?.userId) {
          actionResult = this.permissionService.updatePermission(
            this.datasetId,
            updatedPermission,
          );
        } else {
          actionResult = this.permissionService.createPermission(
            this.datasetId,
            updatedPermission,
          );
        }

        actionResult.subscribe({
          next: () => {
            this.reloadData();
          },
          error: (err) => {
            switch (err?.status) {
              case 409:
                this.notificationService.info(
                  'User already has a role. No changes were made.',
                );
                break;
              default:
                console.error(err);
                this.notificationService.error('Failed to update permissions.');
            }
          },
        });
      });
  }

  public onDelete() {
    const dialogRef = this.dialog.open(PromptComponent, {
      data: {
        title: 'Delete permissions',
        content: 'Are you sure you want to revoke selected user permissions?',
      },
      minWidth: '360px',
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (!result) {
        return;
      }

      const selectedPermissions = this.selection?.selected || [];
      from(selectedPermissions)
        .pipe(
          map((permission: Permission) => {
            return this.permissionService.deletePermission(
              this.datasetId,
              permission.userId,
            );
          }),
          mergeAll(),
        )
        .subscribe({
          next: () => {
            this.reloadData();
          },
          error: (err) => {
            console.error(err);
            this.notificationService.error(
              'Failed to delete one or multiple permissions.',
            );
            this.reloadData();
          },
        });
    });
  }

  onListSelectionChange(event: MatSelectionListChange) {
    for (const option of event.options) {
      if (option.selected) {
        this.selection?.select(option.value);
      } else {
        this.selection?.deselect(option.value);
      }
    }
  }

  private reloadData() {
    this.selection.clear();
    this.loadPage(
      {
        pageIndex: 0,
        pageSize: 10,
      } as PageEvent,
      true,
    );
  }
}
