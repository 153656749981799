import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatListModule } from '@angular/material/list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';

import { RootComponent } from '@components/root/root.component';
import { PromptComponent } from '@components/prompt/prompt.component';
import { SidenavComponent } from '@components/sidenav/sidenav.component';
import { ToolbarComponent } from '@components/toolbar/toolbar.component';
import { BreadcrumbComponent } from '@components/breadcrumb/breadcrumb.component';
import { LoginComponent } from '@components/login/login.component';
import { AboutComponent } from '@components/about/about.component';
import { DatasetListComponent } from '@components/dataset/list/list.component';
import { DatasetItemComponent } from '@components/dataset/item/item.component';
import { DatasetFormComponent } from '@components/dataset/form/form.component';
import {} from '@components/dataset/form/form.component';
import { SnackBarComponent } from '@components/snack-bar/snack-bar.component';
import { LeafletGroupComponent } from '@components/leaflet/group/group.component';
import { LeafletComponent } from '@components/leaflet/leaflet.component';
import { GeoJSONFormComponent } from '@components/leaflet/geojson-form/geojson-form.component';
import { AttachmentListComponent } from '@components/attachment/list/list.component';
import { AttachmentFormComponent } from '@components/attachment/form/form.component';
import { AttachmentItemComponent } from '@components/attachment/item/item.component';
import { PermissionPageComponent } from '@components/permission/page/page.component';
import { PermissionTableComponent } from '@components/permission/table/table.component';
import { DatasetCreateFormComponent } from '@components/dataset/create-form/form.component';
import { DatasetPublishFormComponent } from '@components/dataset/publish-form/form.component';

import {
  AuthService,
  NotificationService,
  DatasetService,
  AttachmentService,
  PermissionService,
  CacheService,
  UserService,
  TitleService,
  MediaService,
  RecordService,
} from '@services';
import {
  RoleListStringPipe,
  YearRangeStringPipe,
  HumanReadableSizePipe,
  CompactNumberPipe,
  ResolveUserPipe,
  MarkdownPipe,
  RoleStringPipe,
} from '@pipes';
import {
  PreventDefaultDirective,
  StopPropagationDirective,
  AuthorizeDirective,
} from '@directives';
import { AuthInterceptor } from '@interceptors';
import { DatasetFormMetadataComponent } from '@components/dataset/form/metadata/metadata.component';
import { DatasetFormGeocoverageComponent } from '@components/dataset/form/geocoverage/geocoverage.component';
import { FormControlMarkdownComponent } from '@components/form-control/markdown/markdown.component';
import { FormControlAutocompleteComponent } from '@components/form-control/autocomplete/autocomplete.component';
import { DatasetFormLinkListComponent } from '@components/dataset/form/link/list/list.component';
import { DatasetFormLinkFormComponent } from '@components/dataset/form/link/form/form.component';
import { DatasetFormOrganisationListComponent } from '@components/dataset/form/organisation/list/list.component';
import { DatasetFormOrganisationFormComponent } from '@components/dataset/form/organisation/form/form.component';
import { DatasetFormPersonListComponent } from '@components/dataset/form/person/list/list.component';
import { DatasetFormPersonFormComponent } from '@components/dataset/form/person/form/form.component';
import { DatasetFormTimeframeListComponent } from '@components/dataset/form/timeframe/list/list.component';
import { DatasetFormTimeframeFormComponent } from '@components/dataset/form/timeframe/form/form.component';
import { DatasetFormHarvestersComponent } from '@components/dataset/form/harvesters/harvesters.component';
import { ISODateAdapter } from './adapters/iso-date.adapter';
import { PermissionFormComponent } from '@components/permission/form/form.component';
import { DatasetCitationComponent } from '@components/dataset/citation/citation.component';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { NotFoundComponent } from '@components/404/404.component';
import { UserListComponent } from '@components/admin/user/list/list.component';
import { UserFormComponent } from '@components/admin/user/form/form.component';
import { MatSortModule } from '@angular/material/sort';
import { AccountPasswordComponent } from '@components/account/password/password.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { FormControlSearchComponent } from '@components/form-control/search/search.component';
import { A11yModule } from '@angular/cdk/a11y';
import { AttachmentUploadDialogComponent as AttachmentUploadDialogComponent } from '@components/attachment/upload-dialog/upload-dialog.component';
import { AttachmentSearchDialogComponent } from '@components/attachment/search-dialog/search-dialog.component';
import { RecordListComponent } from '@components/record/list/list.component';
import { RecordItemComponent } from '@components/record/item/item.component';
import { DatasetLicenseComponent } from '@components/dataset/license/license.component';

@NgModule({
  declarations: [
    YearRangeStringPipe,
    RoleListStringPipe,
    HumanReadableSizePipe,
    CompactNumberPipe,
    ResolveUserPipe,
    MarkdownPipe,
    RoleStringPipe,
    RootComponent,
    SidenavComponent,
    ToolbarComponent,
    PromptComponent,
    BreadcrumbComponent,
    LoginComponent,
    AboutComponent,
    NotFoundComponent,
    DatasetListComponent,
    DatasetItemComponent,
    DatasetFormComponent,
    DatasetFormMetadataComponent,
    DatasetFormGeocoverageComponent,
    DatasetFormLinkListComponent,
    DatasetFormLinkFormComponent,
    DatasetFormOrganisationListComponent,
    DatasetFormOrganisationFormComponent,
    DatasetFormPersonListComponent,
    DatasetFormPersonFormComponent,
    DatasetFormTimeframeListComponent,
    DatasetFormTimeframeFormComponent,
    DatasetFormHarvestersComponent,
    SnackBarComponent,
    LeafletComponent,
    LeafletGroupComponent,
    GeoJSONFormComponent,
    RecordListComponent,
    RecordItemComponent,
    AttachmentListComponent,
    AttachmentFormComponent,
    AttachmentItemComponent,
    AttachmentSearchDialogComponent,
    FormControlMarkdownComponent,
    FormControlAutocompleteComponent,
    PermissionPageComponent,
    PermissionTableComponent,
    PermissionFormComponent,
    DatasetCreateFormComponent,
    DatasetPublishFormComponent,
    PreventDefaultDirective,
    StopPropagationDirective,
    AuthorizeDirective,
    UserListComponent,
    UserFormComponent,
    AccountPasswordComponent,
    FormControlSearchComponent,
    AttachmentUploadDialogComponent,
  ],
  bootstrap: [RootComponent],
  imports: [
    // standalone
    DatasetLicenseComponent,
    DatasetCitationComponent,
    // modules
    ClipboardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    A11yModule,
    CdkDrag,
    CdkDropList,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    OverlayModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatSidenavModule,
    MatExpansionModule,
    MatDialogModule,
    MatToolbarModule,
    MatRadioModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatListModule,
    MatDividerModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    MatCardModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatPaginatorModule,
    MatChipsModule,
    MatSelectModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatSortModule,
  ],
  providers: [
    DatePipe,
    YearRangeStringPipe,
    RoleListStringPipe,
    HumanReadableSizePipe,
    CompactNumberPipe,
    ResolveUserPipe,
    MarkdownPipe,
    RoleStringPipe,
    TitleCasePipe,
    MediaService,
    TitleService,
    AuthService,
    NotificationService,
    DatasetService,
    AttachmentService,
    RecordService,
    PermissionService,
    UserService,
    CacheService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: DateAdapter,
      useClass: ISODateAdapter,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic',
      },
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
