import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenav } from '@angular/material/sidenav';
import { Event, NavigationEnd, Router } from '@angular/router';
import { MediaService, NotificationService } from '@services';
import { Subscription, filter, withLatestFrom } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
  standalone: false,
})
export class RootComponent implements OnDestroy {
  @ViewChild('sidenav') sidenav!: MatSidenav;

  private routerEventsSubscription: Subscription;

  constructor(
    public mediaService: MediaService,
    router: Router,
    notificationService: NotificationService,
    iconRegistry: MatIconRegistry,
  ) {
    iconRegistry.setDefaultFontSetClass('material-symbols-rounded');

    this.routerEventsSubscription = router.events
      .pipe(
        filter((e: Event) => e instanceof NavigationEnd),
        withLatestFrom(mediaService.isLandscape$),
      )
      // eslint-disable-next-line
      .subscribe(([_, isLandscape]) => {
        notificationService.dismiss();

        if (!isLandscape) {
          this.sidenav?.close();
        }
      });
  }

  ngOnDestroy(): void {
    this.routerEventsSubscription?.unsubscribe();
  }
}
