<h2 mat-dialog-title>{{ data.content['title'] || data.id }}</h2>

<mat-dialog-content>
  <mat-tab-group>
    <mat-tab label="Description">
      @switch (data.type) {
        @case ('station') {
          <ng-container [ngTemplateOutlet]="stationContent"> </ng-container>
        }
        @default {
          <ng-container [ngTemplateOutlet]="rawContent"> </ng-container>
        }
      }
    </mat-tab>
    <mat-tab label="API">
      <p>
        Records are organized in a nested data structure. This query fetches the
        5 most recent entries for the current record.
      </p>
      <p>
        Refer to the
        <a
          target="_blank"
          href="https://beta.data.npolar.no/-/docs/dataset/#/record/get_dataset__datasetID__record_"
          >API documentation</a
        >
        for additional filtering and querying options.
      </p>
      <h3>Browser</h3>
      <p>
        <a target="_blank" href="{{ url }}">{{ url }}</a>
      </p>
      <h3>cURL</h3>
      <pre>
curl -X 'GET' \
  '{{ url }}' \
  -H 'accept: application/json'</pre
      >
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button type="button" (click)="copyToClipboard()"
    >Copy JSON to clipboard</button
  >
  <button mat-button type="button" (click)="dialogRef.close()">Close</button>
</mat-dialog-actions>

<ng-template #stationContent>
  <p style="white-space: pre-line">
    {{ data.content['description'] }}
  </p>

  <h3>Coordinates</h3>
  <p>
    {{ data.content['coordinates']?.join(', ') }}
  </p>

  <h3>Values</h3>
  <mat-list>
    @for (element of data.content['values']; track element['key']) {
      <mat-list-item lines="3">
        <span matListItemTitle>{{ element['key'] }}</span>
        <span matListItemLine>{{ element['value'] }} </span>
        <span matListItemLine>Unit: {{ element['unit'] }}</span>
      </mat-list-item>
    }
  </mat-list>

  <h3>Other</h3>
  <mat-list>
    <!--async resolution of user email here scrolls the dialog to the bottom for some reason-->
    <mat-list-item>
      <div matListItemTitle>Created</div>
      <div matListItemLine>
        {{ data.created | date: 'yyyy-MM-dd HH:mm:ss' }} by {{ data.createdBy }}
      </div>
    </mat-list-item>
    <mat-list-item>
      <div matListItemTitle>Modified</div>
      <div matListItemLine>
        {{ data.modified | date: 'yyyy-MM-dd HH:mm:ss' }} by
        {{ data.modifiedBy }}
      </div>
    </mat-list-item>
  </mat-list>
</ng-template>

<ng-template #rawContent>
  <code>{{ data | json }}</code>
</ng-template>
