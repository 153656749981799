import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoginComponent } from '@components/login/login.component';
import { User } from '@models';
import { AuthService } from '@services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  standalone: false,
})
export class ToolbarComponent implements OnInit, OnDestroy {
  public currentUser: User | null;
  public isAccountMenuOpen: boolean;

  private subscriptions: Subscription[];

  constructor(
    private router: Router,
    private dialog: MatDialog,
    public authService: AuthService,
  ) {
    this.subscriptions = [];
    this.currentUser = null;
    this.isAccountMenuOpen = false;
  }

  ngOnInit() {
    const userSubscription = this.authService.user$.subscribe(
      (user: User | null) => {
        this.currentUser = user;
      },
    );
    this.subscriptions.push(userSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  openLoginDialog() {
    const dialogRef = this.dialog.open(LoginComponent, {
      minWidth: '360px',
    });

    dialogRef.afterClosed().subscribe((hasLoggedIn: boolean) => {
      if (!hasLoggedIn) {
        return;
      }

      this.router.navigateByUrl('/');
    });
  }

  logOut() {
    this.authService.logOut();
  }
}
