import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PrefixValidator } from '@validators';

@Component({
    selector: 'app-search-dialog',
    templateUrl: './search-dialog.component.html',
    styleUrls: ['./search-dialog.component.scss'],
    standalone: false
})
export class AttachmentSearchDialogComponent {
  public form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AttachmentSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      search: string;
      from: string | null;
      until: string | null;
      prefix: string | null;
    },
  ) {
    this.form = new FormGroup({
      search: new FormControl(data?.search || ''),
      from: new FormControl(data?.from || null),
      until: new FormControl(data?.until || null),
      prefix: new FormControl(data?.prefix || '', [PrefixValidator]),
    });
  }

  public onSubmit() {
    if (this.form.pristine || !this.form.valid) {
      return;
    }

    this.dialogRef.close(this.form.value);
  }

  public onCancel() {
    this.dialogRef.close();
  }
}
