[
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "AGRICULTURAL AQUATIC SCIENCES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "AGRICULTURAL AQUATIC SCIENCES",
    "variableLevel1": "AQUACULTURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "AGRICULTURAL AQUATIC SCIENCES",
    "variableLevel1": "FISHERIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "AGRICULTURAL AQUATIC SCIENCES",
    "variableLevel1": "ICE STUPA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "AGRICULTURAL CHEMICALS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "AGRICULTURAL CHEMICALS",
    "variableLevel1": "FERTILIZERS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "AGRICULTURAL CHEMICALS",
    "variableLevel1": "PESTICIDES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "AGRICULTURAL ENGINEERING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "AGRICULTURAL ENGINEERING",
    "variableLevel1": "AGRICULTURAL EQUIPMENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "AGRICULTURAL ENGINEERING",
    "variableLevel1": "FARM STRUCTURES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "AGRICULTURAL PLANT SCIENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "AGRICULTURAL PLANT SCIENCE",
    "variableLevel1": "CROP/PLANT YIELDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "AGRICULTURAL PLANT SCIENCE",
    "variableLevel1": "CROPPING SYSTEMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "AGRICULTURAL PLANT SCIENCE",
    "variableLevel1": "IRRIGATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "AGRICULTURAL PLANT SCIENCE",
    "variableLevel1": "PLANT BREEDING AND GENETICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "AGRICULTURAL PLANT SCIENCE",
    "variableLevel1": "PLANT DISEASES/DISORDERS/PESTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "AGRICULTURAL PLANT SCIENCE",
    "variableLevel1": "RECLAMATION/REVEGETATION/RESTORATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "AGRICULTURAL PLANT SCIENCE",
    "variableLevel1": "WEEDS, NOXIOUS PLANTS OR INVASIVE PLANTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "ANIMAL COMMODITIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "ANIMAL COMMODITIES",
    "variableLevel1": "DAIRY PRODUCTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "ANIMAL COMMODITIES",
    "variableLevel1": "LIVESTOCK PRODUCTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "ANIMAL COMMODITIES",
    "variableLevel1": "POULTRY PRODUCTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "ANIMAL SCIENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "ANIMAL SCIENCE",
    "variableLevel1": "ANIMAL BREEDING AND GENETICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "ANIMAL SCIENCE",
    "variableLevel1": "ANIMAL DISEASES/DISORDERS/PESTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "ANIMAL SCIENCE",
    "variableLevel1": "ANIMAL ECOLOGY AND BEHAVIOR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "ANIMAL SCIENCE",
    "variableLevel1": "ANIMAL MANAGEMENT SYSTEMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "ANIMAL SCIENCE",
    "variableLevel1": "ANIMAL MANURE AND WASTE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "ANIMAL SCIENCE",
    "variableLevel1": "ANIMAL NUTRITION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "ANIMAL SCIENCE",
    "variableLevel1": "ANIMAL PHYSIOLOGY AND BIOCHEMISTRY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "ANIMAL SCIENCE",
    "variableLevel1": "ANIMAL YIELDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "ANIMAL SCIENCE",
    "variableLevel1": "APICULTURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "ANIMAL SCIENCE",
    "variableLevel1": "SERICULTURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "FEED PRODUCTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "FEED PRODUCTS",
    "variableLevel1": "FEED COMPOSITION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "FEED PRODUCTS",
    "variableLevel1": "FEED CONTAMINATION AND TOXICOLOGY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "FEED PRODUCTS",
    "variableLevel1": "FEED PROCESSING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "FEED PRODUCTS",
    "variableLevel1": "FEED STORAGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "FOOD SCIENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "FOOD SCIENCE",
    "variableLevel1": "FOOD ADDITIVES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "FOOD SCIENCE",
    "variableLevel1": "FOOD CONTAMINATION AND TOXICOLOGY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "FOOD SCIENCE",
    "variableLevel1": "FOOD PACKAGING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "FOOD SCIENCE",
    "variableLevel1": "FOOD PROCESSING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "FOOD SCIENCE",
    "variableLevel1": "FOOD QUALITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "FOOD SCIENCE",
    "variableLevel1": "FOOD STORAGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "FOREST SCIENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "FOREST SCIENCE",
    "variableLevel1": "AFFORESTATION/REFORESTATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "FOREST SCIENCE",
    "variableLevel1": "DEFOLIANTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "FOREST SCIENCE",
    "variableLevel1": "FOREST CONSERVATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "FOREST SCIENCE",
    "variableLevel1": "FOREST FIRE SCIENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "FOREST SCIENCE",
    "variableLevel1": "FOREST HARVESTING AND ENGINEERING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "FOREST SCIENCE",
    "variableLevel1": "FOREST MANAGEMENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "FOREST SCIENCE",
    "variableLevel1": "FOREST MENSURATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "FOREST SCIENCE",
    "variableLevel1": "FOREST PRODUCTS/COMMODITIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "FOREST SCIENCE",
    "variableLevel1": "FOREST PROTECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "FOREST SCIENCE",
    "variableLevel1": "FOREST YIELDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "FOREST SCIENCE",
    "variableLevel1": "REFORESTATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "FOREST SCIENCE",
    "variableLevel1": "SILVICULTURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "PLANT COMMODITIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "PLANT COMMODITIES",
    "variableLevel1": "FIELD CROP PRODUCTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "PLANT COMMODITIES",
    "variableLevel1": "FRUIT PRODUCTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "PLANT COMMODITIES",
    "variableLevel1": "HORTICULTURAL PRODUCTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "PLANT COMMODITIES",
    "variableLevel1": "VEGETABLE PRODUCTS"
  },
  { "category": "EARTH SCIENCE", "topic": "AGRICULTURE", "term": "SOILS" },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "CALCIUM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "CARBON"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "CATION EXCHANGE CAPACITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "DENITRIFICATION RATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "ELECTRICAL CONDUCTIVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "HEAVY METALS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "HYDRAULIC CONDUCTIVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "IRON"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "MACROFAUNA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "MAGNESIUM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "MICROFAUNA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "MICROFLORA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "MICRONUTRIENTS/TRACE ELEMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "NITROGEN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "ORGANIC MATTER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "PERMAFROST"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "PHOSPHORUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "POTASSIUM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "RECLAMATION/REVEGETATION/RESTORATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "SOIL ABSORPTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "SOIL BIOGEOCHEMISTRY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "SOIL BULK DENSITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "SOIL CHEMISTRY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "SOIL CLASSIFICATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "SOIL COLOR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "SOIL COMPACTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "SOIL CONSISTENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "SOIL DEPTH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "SOIL EROSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "SOIL FERTILITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "SOIL GAS/AIR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "SOIL HEAT BUDGET"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "SOIL HORIZONS/PROFILE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "SOIL IMPEDANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "SOIL INFILTRATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "SOIL MECHANICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "SOIL MOISTURE/WATER CONTENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "SOIL PH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "SOIL PLASTICITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "SOIL POROSITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "SOIL PRODUCTIVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "SOIL RESPIRATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "SOIL ROOTING DEPTH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "SOIL SALINITY/SOIL SODICITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "SOIL STRUCTURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "SOIL TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "SOIL TEXTURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "SOIL WATER HOLDING CAPACITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "SULFUR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "AGRICULTURE",
    "term": "SOILS",
    "variableLevel1": "THERMAL CONDUCTIVITY"
  },
  { "category": "EARTH SCIENCE", "topic": "ATMOSPHERE", "term": "AEROSOLS" },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AEROSOLS",
    "variableLevel1": "AEROSOL ABSORPTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AEROSOLS",
    "variableLevel1": "AEROSOL BACKSCATTER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AEROSOLS",
    "variableLevel1": "AEROSOL BACKSCATTER",
    "variableLevel2": "AEROSOL FRACTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AEROSOLS",
    "variableLevel1": "AEROSOL EXTINCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AEROSOLS",
    "variableLevel1": "AEROSOL FORWARD SCATTER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AEROSOLS",
    "variableLevel1": "AEROSOL OPTICAL DEPTH/THICKNESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AEROSOLS",
    "variableLevel1": "AEROSOL OPTICAL DEPTH/THICKNESS",
    "variableLevel2": "ANGSTROM EXPONENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AEROSOLS",
    "variableLevel1": "AEROSOL OPTICAL DEPTH/THICKNESS",
    "variableLevel2": "UV AEROSOL INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AEROSOLS",
    "variableLevel1": "AEROSOL PARTICLE PROPERTIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AEROSOLS",
    "variableLevel1": "AEROSOL PARTICLE PROPERTIES",
    "variableLevel2": "AEROSOL CONCENTRATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AEROSOLS",
    "variableLevel1": "AEROSOL PARTICLE PROPERTIES",
    "variableLevel2": "AEROSOL SIZE DISTRIBUTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AEROSOLS",
    "variableLevel1": "AEROSOL RADIANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AEROSOLS",
    "variableLevel1": "BLACK CARBON"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AEROSOLS",
    "variableLevel1": "CARBONACEOUS AEROSOLS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AEROSOLS",
    "variableLevel1": "CHEMICAL COMPOSITION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AEROSOLS",
    "variableLevel1": "CHEMICAL COMPOSITION",
    "variableLevel2": "ELEMENTAL CARBON"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AEROSOLS",
    "variableLevel1": "CHEMICAL COMPOSITION",
    "variableLevel2": "NON-REFRACTORY AEROSOL ORGANIC MASS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AEROSOLS",
    "variableLevel1": "CHEMICAL COMPOSITION",
    "variableLevel2": "WATER-SOLUBLE AEROSOL ORGANIC MASS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AEROSOLS",
    "variableLevel1": "CLOUD CONDENSATION NUCLEI"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AEROSOLS",
    "variableLevel1": "DEPOSITION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AEROSOLS",
    "variableLevel1": "DUST/ASH/SMOKE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AEROSOLS",
    "variableLevel1": "NITRATE PARTICLES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AEROSOLS",
    "variableLevel1": "ORGANIC PARTICLES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AEROSOLS",
    "variableLevel1": "PARTICULATE MATTER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AEROSOLS",
    "variableLevel1": "SEA SALT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AEROSOLS",
    "variableLevel1": "SULFATE PARTICLES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AIR QUALITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AIR QUALITY",
    "variableLevel1": "CARBON MONOXIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AIR QUALITY",
    "variableLevel1": "EMISSIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AIR QUALITY",
    "variableLevel1": "LEAD"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AIR QUALITY",
    "variableLevel1": "NITROGEN OXIDES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AIR QUALITY",
    "variableLevel1": "PARTICULATES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AIR QUALITY",
    "variableLevel1": "SMOG"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AIR QUALITY",
    "variableLevel1": "SULFUR OXIDES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AIR QUALITY",
    "variableLevel1": "TROPOSPHERIC OZONE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AIR QUALITY",
    "variableLevel1": "TURBIDITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AIR QUALITY",
    "variableLevel1": "VISIBILITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "AIR QUALITY",
    "variableLevel1": "VOLATILE ORGANIC COMPOUNDS"
  },
  { "category": "EARTH SCIENCE", "topic": "ATMOSPHERE", "term": "ALTITUDE" },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ALTITUDE",
    "variableLevel1": "BAROMETRIC ALTITUDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ALTITUDE",
    "variableLevel1": "GEOPOTENTIAL HEIGHT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ALTITUDE",
    "variableLevel1": "MESOPAUSE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ALTITUDE",
    "variableLevel1": "PLANETARY BOUNDARY LAYER HEIGHT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ALTITUDE",
    "variableLevel1": "STATION HEIGHT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ALTITUDE",
    "variableLevel1": "STRATOPAUSE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ALTITUDE",
    "variableLevel1": "TROPOPAUSE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "CARBON AND HYDROCARBON COMPOUNDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "CARBON AND HYDROCARBON COMPOUNDS",
    "variableLevel2": "ACETYLENE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "CARBON AND HYDROCARBON COMPOUNDS",
    "variableLevel2": "ATMOSPHERIC CARBON DIOXIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "CARBON AND HYDROCARBON COMPOUNDS",
    "variableLevel2": "ATMOSPHERIC CARBON DIOXIDE",
    "variableLevel3": "CARBON DIOXIDE PROFILES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "CARBON AND HYDROCARBON COMPOUNDS",
    "variableLevel2": "ATMOSPHERIC CARBON DIOXIDE",
    "variableLevel3": "CARBON DIOXIDE SURFACE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "CARBON AND HYDROCARBON COMPOUNDS",
    "variableLevel2": "ATMOSPHERIC CARBON DIOXIDE",
    "variableLevel3": "PARTIAL PRESSURE OF CARBON DIOXIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "CARBON AND HYDROCARBON COMPOUNDS",
    "variableLevel2": "ATMOSPHERIC CARBON MONOXIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "CARBON AND HYDROCARBON COMPOUNDS",
    "variableLevel2": "ATMOSPHERIC CARBON MONOXIDE",
    "variableLevel3": "CARBON MONOXIDE PROFILES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "CARBON AND HYDROCARBON COMPOUNDS",
    "variableLevel2": "ATMOSPHERIC CARBON MONOXIDE",
    "variableLevel3": "CARBON MONOXIDE SURFACE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "CARBON AND HYDROCARBON COMPOUNDS",
    "variableLevel2": "CHLORINATED HYDROCARBONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "CARBON AND HYDROCARBON COMPOUNDS",
    "variableLevel2": "DICARBON MONOXIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "CARBON AND HYDROCARBON COMPOUNDS",
    "variableLevel2": "ETHANE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "CARBON AND HYDROCARBON COMPOUNDS",
    "variableLevel2": "FORMALDEHYDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "CARBON AND HYDROCARBON COMPOUNDS",
    "variableLevel2": "FORMIC ACID"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "CARBON AND HYDROCARBON COMPOUNDS",
    "variableLevel2": "HYDROGEN CYANIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "CARBON AND HYDROCARBON COMPOUNDS",
    "variableLevel2": "HYPOCHLOROUS MONOXIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "CARBON AND HYDROCARBON COMPOUNDS",
    "variableLevel2": "ISOPRENE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "CARBON AND HYDROCARBON COMPOUNDS",
    "variableLevel2": "METHANE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "CARBON AND HYDROCARBON COMPOUNDS",
    "variableLevel2": "METHYL CYANIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "CARBON AND HYDROCARBON COMPOUNDS",
    "variableLevel2": "NON-METHANE HYDROCARBONS/VOLATILE ORGANIC COMPOUNDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "HALOCARBONS AND HALOGENS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "HALOCARBONS AND HALOGENS",
    "variableLevel2": "BROMINE MONOXIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "HALOCARBONS AND HALOGENS",
    "variableLevel2": "CARBON TETRACHLORIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "HALOCARBONS AND HALOGENS",
    "variableLevel2": "CARBON TETRAFLUORIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "HALOCARBONS AND HALOGENS",
    "variableLevel2": "CARBONYL FLUORIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "HALOCARBONS AND HALOGENS",
    "variableLevel2": "CHLORINE DIOXIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "HALOCARBONS AND HALOGENS",
    "variableLevel2": "CHLORINE MONOXIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "HALOCARBONS AND HALOGENS",
    "variableLevel2": "CHLORINE NITRATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "HALOCARBONS AND HALOGENS",
    "variableLevel2": "CHLOROFLUOROCARBONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "HALOCARBONS AND HALOGENS",
    "variableLevel2": "CHLOROFLUOROCARBONS",
    "variableLevel3": "CFC-11"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "HALOCARBONS AND HALOGENS",
    "variableLevel2": "CHLOROFLUOROCARBONS",
    "variableLevel3": "CFC-12"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "HALOCARBONS AND HALOGENS",
    "variableLevel2": "HALOCARBONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "HALOCARBONS AND HALOGENS",
    "variableLevel2": "HALONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "HALOCARBONS AND HALOGENS",
    "variableLevel2": "HYDROCHLOROFLUOROCARBONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "HALOCARBONS AND HALOGENS",
    "variableLevel2": "HYDROCHLOROFLUOROCARBONS",
    "variableLevel3": "HCFC-22"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "HALOCARBONS AND HALOGENS",
    "variableLevel2": "HYDROFLUOROCARBONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "HALOCARBONS AND HALOGENS",
    "variableLevel2": "HYDROGEN CHLORIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "HALOCARBONS AND HALOGENS",
    "variableLevel2": "HYDROGEN FLUORIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "HALOCARBONS AND HALOGENS",
    "variableLevel2": "HYPOCHLOROUS ACID"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "HALOCARBONS AND HALOGENS",
    "variableLevel2": "METHANOL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "HALOCARBONS AND HALOGENS",
    "variableLevel2": "METHYL BROMIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "HALOCARBONS AND HALOGENS",
    "variableLevel2": "METHYL CHLORIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "HALOCARBONS AND HALOGENS",
    "variableLevel2": "METHYL FLUORIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "HYDROGEN COMPOUNDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "HYDROGEN COMPOUNDS",
    "variableLevel2": "DEUTERIUM OXIDE/HEAVY WATER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "HYDROGEN COMPOUNDS",
    "variableLevel2": "HYDROGEN OXIDES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "HYDROGEN COMPOUNDS",
    "variableLevel2": "HYDROGEN OXIDES",
    "variableLevel3": "HYDROGEN OXIDE PROFILES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "HYDROGEN COMPOUNDS",
    "variableLevel2": "HYDROGEN OXIDES",
    "variableLevel3": "HYDROGEN OXIDE SURFACE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "HYDROGEN COMPOUNDS",
    "variableLevel2": "HYDROGEN-DEUTERIUM OXIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "HYDROGEN COMPOUNDS",
    "variableLevel2": "HYDROPEROXY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "HYDROGEN COMPOUNDS",
    "variableLevel2": "HYDROXYL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "HYDROGEN COMPOUNDS",
    "variableLevel2": "MOLECULAR HYDROGEN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "NITROGEN COMPOUNDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "NITROGEN COMPOUNDS",
    "variableLevel2": "AMMONIA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "NITROGEN COMPOUNDS",
    "variableLevel2": "ATMOSPHERIC NITRIC ACID"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "NITROGEN COMPOUNDS",
    "variableLevel2": "CLOUD-SCREENED TOTAL COLUMN NITROGEN DIOXIDE (NO2)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "NITROGEN COMPOUNDS",
    "variableLevel2": "CLOUD-SCREENED TROPOSHERIC COLUMN NO2"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "NITROGEN COMPOUNDS",
    "variableLevel2": "DINITROGEN PENTOXIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "NITROGEN COMPOUNDS",
    "variableLevel2": "MOLECULAR NITROGEN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "NITROGEN COMPOUNDS",
    "variableLevel2": "NITRIC OXIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "NITROGEN COMPOUNDS",
    "variableLevel2": "NITROGEN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "NITROGEN COMPOUNDS",
    "variableLevel2": "NITROGEN DIOXIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "NITROGEN COMPOUNDS",
    "variableLevel2": "NITROGEN OXIDES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "NITROGEN COMPOUNDS",
    "variableLevel2": "NITROUS OXIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "NITROGEN COMPOUNDS",
    "variableLevel2": "PEROXYACETYL NITRATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "NITROGEN COMPOUNDS",
    "variableLevel2": "PEROXYNITRIC ACID"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "NITROGEN COMPOUNDS",
    "variableLevel2": "Peroxyacyl Nitrate"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "NOBLE GAS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "NOBLE GAS",
    "variableLevel2": "ATMOSPHERIC RADON"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "NOBLE GAS",
    "variableLevel2": "ATMOSPHERIC RADON",
    "variableLevel3": "RADON PROFILES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "NOBLE GAS",
    "variableLevel2": "ATMOSPHERIC RADON",
    "variableLevel3": "RADON SURFACE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "OXYGEN COMPOUNDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "OXYGEN COMPOUNDS",
    "variableLevel2": "ATMOSPHERIC OZONE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "OXYGEN COMPOUNDS",
    "variableLevel2": "ATMOSPHERIC OZONE",
    "variableLevel3": "OZONE PROFILES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "OXYGEN COMPOUNDS",
    "variableLevel2": "ATMOSPHERIC OZONE",
    "variableLevel3": "OZONE SURFACE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "OXYGEN COMPOUNDS",
    "variableLevel2": "ATMOSPHERIC OZONE",
    "variableLevel3": "TOTAL OZONE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "OXYGEN COMPOUNDS",
    "variableLevel2": "HYDROGEN PEROXIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "OXYGEN COMPOUNDS",
    "variableLevel2": "MOLECULAR OXYGEN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "PHOTOCHEMISTRY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "PHOTOCHEMISTRY",
    "variableLevel2": "PHOTOLYSIS RATES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "SULFUR COMPOUNDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "SULFUR COMPOUNDS",
    "variableLevel2": "CARBONYL SULFIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "SULFUR COMPOUNDS",
    "variableLevel2": "DIMETHYL SULFIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "SULFUR COMPOUNDS",
    "variableLevel2": "SULFATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "SULFUR COMPOUNDS",
    "variableLevel2": "SULFUR DIOXIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "SULFUR COMPOUNDS",
    "variableLevel2": "SULFUR DIOXIDE",
    "variableLevel3": "SULFUR DIOXIDE PROFILES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "SULFUR COMPOUNDS",
    "variableLevel2": "SULFUR DIOXIDE",
    "variableLevel3": "SULFUR DIOXIDE SURFACE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "SULFUR COMPOUNDS",
    "variableLevel2": "SULFUR HEXAFLUORIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "SULFUR COMPOUNDS",
    "variableLevel2": "SULFUR HEXAFLUORIDE",
    "variableLevel3": "SULFUR HEXAFLUORIDE PROFILES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "SULFUR COMPOUNDS",
    "variableLevel2": "SULFUR HEXAFLUORIDE",
    "variableLevel3": "SULFUR HEXAFLUORIDE SURFACE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "SULFUR COMPOUNDS",
    "variableLevel2": "SULFUR OXIDES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "TRACE ELEMENTS/TRACE METALS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC CHEMISTRY",
    "variableLevel1": "TRACE GASES/TRACE SPECIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC ELECTRICITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC ELECTRICITY",
    "variableLevel1": "ATMOSPHERIC CONDUCTIVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC ELECTRICITY",
    "variableLevel1": "ELECTRIC FIELD"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC ELECTRICITY",
    "variableLevel1": "LIGHTNING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC ELECTRICITY",
    "variableLevel1": "TOTAL ELECTRON CONTENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC PRESSURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC PRESSURE",
    "variableLevel1": "AIR MASS/DENSITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC PRESSURE",
    "variableLevel1": "ANTICYCLONES/CYCLONES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC PRESSURE",
    "variableLevel1": "ATMOSPHERIC PRESSURE MEASUREMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC PRESSURE",
    "variableLevel1": "DIFFERENTIAL PRESSURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC PRESSURE",
    "variableLevel1": "GRAVITY WAVE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC PRESSURE",
    "variableLevel1": "HYDROSTATIC PRESSURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC PRESSURE",
    "variableLevel1": "OSCILLATIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC PRESSURE",
    "variableLevel1": "PLANETARY BOUNDARY LAYER HEIGHT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC PRESSURE",
    "variableLevel1": "PLANETARY/ROSSBY WAVES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC PRESSURE",
    "variableLevel1": "PRESSURE ANOMALIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC PRESSURE",
    "variableLevel1": "PRESSURE TENDENCY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC PRESSURE",
    "variableLevel1": "PRESSURE THICKNESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC PRESSURE",
    "variableLevel1": "SEA LEVEL PRESSURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC PRESSURE",
    "variableLevel1": "STATIC PRESSURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC PRESSURE",
    "variableLevel1": "SURFACE PRESSURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC PRESSURE",
    "variableLevel1": "TOPOGRAPHIC WAVES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION",
    "variableLevel1": "ABSORPTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION",
    "variableLevel1": "ACTINIC FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION",
    "variableLevel1": "AIRGLOW"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION",
    "variableLevel1": "ALBEDO"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION",
    "variableLevel1": "ANISOTROPY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION",
    "variableLevel1": "ATMOSPHERIC EMITTED RADIATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION",
    "variableLevel1": "ATMOSPHERIC HEATING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION",
    "variableLevel1": "EARTH RADIATION BUDGET"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION",
    "variableLevel1": "EMISSIVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION",
    "variableLevel1": "HEAT FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION",
    "variableLevel1": "INCOMING SOLAR RADIATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION",
    "variableLevel1": "LONGWAVE RADIATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION",
    "variableLevel1": "LONGWAVE RADIATION",
    "variableLevel2": "DOWNWELLING LONGWAVE RADIATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION",
    "variableLevel1": "LONGWAVE RADIATION",
    "variableLevel2": "UPWELLING LONGWAVE RADIATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION",
    "variableLevel1": "NET RADIATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION",
    "variableLevel1": "OPTICAL DEPTH/THICKNESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION",
    "variableLevel1": "OUTGOING LONGWAVE RADIATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION",
    "variableLevel1": "POLARIZED REFLECTANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION",
    "variableLevel1": "RADIATIVE FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION",
    "variableLevel1": "RADIATIVE FORCING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION",
    "variableLevel1": "REFLECTANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION",
    "variableLevel1": "REFLECTANCE",
    "variableLevel2": "TOP OF ATMOSPHERE (TOA) REFLECTANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION",
    "variableLevel1": "SCATTERING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION",
    "variableLevel1": "SHORTWAVE RADIATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION",
    "variableLevel1": "SHORTWAVE RADIATION",
    "variableLevel2": "DOWNWELLING SHORTWAVE RADIATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION",
    "variableLevel1": "SOLAR IRRADIANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION",
    "variableLevel1": "SOLAR IRRADIANCE",
    "variableLevel2": "SHORTWAVE DOWNWARD IRRADIANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION",
    "variableLevel1": "SOLAR RADIATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION",
    "variableLevel1": "SPECTRAL IRRADIANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION",
    "variableLevel1": "SUNSHINE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION",
    "variableLevel1": "TRANSMITTANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION",
    "variableLevel1": "ULTRAVIOLET RADIATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC RADIATION",
    "variableLevel1": "ULTRAVIOLET RADIATION",
    "variableLevel2": "UV SPECTRAL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "ATMOSPHERIC STABILITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "ATMOSPHERIC TEMPERATURE INDICES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "ATMOSPHERIC TEMPERATURE INDICES",
    "variableLevel2": "COMMON SENSE CLIMATE INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "ATMOSPHERIC TEMPERATURE INDICES",
    "variableLevel2": "COOLING DEGREE DAYS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "ATMOSPHERIC TEMPERATURE INDICES",
    "variableLevel2": "FREEZING INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "ATMOSPHERIC TEMPERATURE INDICES",
    "variableLevel2": "GROWING DEGREE DAYS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "ATMOSPHERIC TEMPERATURE INDICES",
    "variableLevel2": "HEAT INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "ATMOSPHERIC TEMPERATURE INDICES",
    "variableLevel2": "HEATING DEGREE DAYS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "ATMOSPHERIC TEMPERATURE INDICES",
    "variableLevel2": "RESIDENTIAL ENERGY DEMAND TEMPERATURE INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "ATMOSPHERIC TEMPERATURE INDICES",
    "variableLevel2": "TEMPERATURE CONCENTRATION INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "ATMOSPHERIC TEMPERATURE INDICES",
    "variableLevel2": "THAWING INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "ATMOSPHERIC TEMPERATURE INDICES",
    "variableLevel2": "WIND CHILL INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "SURFACE TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "SURFACE TEMPERATURE",
    "variableLevel2": "AIR TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "SURFACE TEMPERATURE",
    "variableLevel2": "BOUNDARY LAYER TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "SURFACE TEMPERATURE",
    "variableLevel2": "DEICED TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "SURFACE TEMPERATURE",
    "variableLevel2": "DEW POINT TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "SURFACE TEMPERATURE",
    "variableLevel2": "DEW POINT TEMPERATURE",
    "variableLevel3": "DEWPOINT DEPRESSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "SURFACE TEMPERATURE",
    "variableLevel2": "MAXIMUM/MINIMUM TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "SURFACE TEMPERATURE",
    "variableLevel2": "MAXIMUM/MINIMUM TEMPERATURE",
    "variableLevel3": "24 HOUR MAXIMUM TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "SURFACE TEMPERATURE",
    "variableLevel2": "MAXIMUM/MINIMUM TEMPERATURE",
    "variableLevel3": "24 HOUR MINIMUM TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "SURFACE TEMPERATURE",
    "variableLevel2": "MAXIMUM/MINIMUM TEMPERATURE",
    "variableLevel3": "6 HOUR MAXIMUM TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "SURFACE TEMPERATURE",
    "variableLevel2": "MAXIMUM/MINIMUM TEMPERATURE",
    "variableLevel3": "6 HOUR MINIMUM TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "SURFACE TEMPERATURE",
    "variableLevel2": "POTENTIAL TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "SURFACE TEMPERATURE",
    "variableLevel2": "SKIN TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "SURFACE TEMPERATURE",
    "variableLevel2": "STATIC TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "SURFACE TEMPERATURE",
    "variableLevel2": "TEMPERATURE ANOMALIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "SURFACE TEMPERATURE",
    "variableLevel2": "TEMPERATURE TENDENCY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "SURFACE TEMPERATURE",
    "variableLevel2": "VIRTUAL TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "UPPER AIR TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "UPPER AIR TEMPERATURE",
    "variableLevel2": "BOUNDARY LAYER TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "UPPER AIR TEMPERATURE",
    "variableLevel2": "DEICED TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "UPPER AIR TEMPERATURE",
    "variableLevel2": "DEW POINT TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "UPPER AIR TEMPERATURE",
    "variableLevel2": "DEW POINT TEMPERATURE",
    "variableLevel3": "DEW POINT DEPRESSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "UPPER AIR TEMPERATURE",
    "variableLevel2": "TEMPERATURE ANOMALIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "UPPER AIR TEMPERATURE",
    "variableLevel2": "VERTICAL PROFILES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "UPPER AIR TEMPERATURE",
    "variableLevel2": "VERTICAL PROFILES",
    "variableLevel3": "DRY ADIABATIC LAPSE RATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "UPPER AIR TEMPERATURE",
    "variableLevel2": "VERTICAL PROFILES",
    "variableLevel3": "ENVIRONMENTAL LAPSE RATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "UPPER AIR TEMPERATURE",
    "variableLevel2": "VERTICAL PROFILES",
    "variableLevel3": "INVERSION HEIGHT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "UPPER AIR TEMPERATURE",
    "variableLevel2": "VERTICAL PROFILES",
    "variableLevel3": "SATURATED ADIABATIC LAPSE RATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC TEMPERATURE",
    "variableLevel1": "UPPER AIR TEMPERATURE",
    "variableLevel2": "VIRTUAL TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR",
    "variableLevel1": "WATER VAPOR INDICATORS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR",
    "variableLevel1": "WATER VAPOR INDICATORS",
    "variableLevel2": "DEW POINT TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR",
    "variableLevel1": "WATER VAPOR INDICATORS",
    "variableLevel2": "HUMIDITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR",
    "variableLevel1": "WATER VAPOR INDICATORS",
    "variableLevel2": "HUMIDITY",
    "variableLevel3": "ABSOLUTE HUMIDITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR",
    "variableLevel1": "WATER VAPOR INDICATORS",
    "variableLevel2": "HUMIDITY",
    "variableLevel3": "HUMIDITY MIXING RATIO"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR",
    "variableLevel1": "WATER VAPOR INDICATORS",
    "variableLevel2": "HUMIDITY",
    "variableLevel3": "RELATIVE HUMIDITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR",
    "variableLevel1": "WATER VAPOR INDICATORS",
    "variableLevel2": "HUMIDITY",
    "variableLevel3": "SATURATION SPECIFIC HUMIDITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR",
    "variableLevel1": "WATER VAPOR INDICATORS",
    "variableLevel2": "HUMIDITY",
    "variableLevel3": "SPECIFIC HUMIDITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR",
    "variableLevel1": "WATER VAPOR INDICATORS",
    "variableLevel2": "LAYERED PRECIPITABLE WATER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR",
    "variableLevel1": "WATER VAPOR INDICATORS",
    "variableLevel2": "SATURATION VAPOR PRESSURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR",
    "variableLevel1": "WATER VAPOR INDICATORS",
    "variableLevel2": "STABLE ISOTOPES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR",
    "variableLevel1": "WATER VAPOR INDICATORS",
    "variableLevel2": "TOTAL PRECIPITABLE WATER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR",
    "variableLevel1": "WATER VAPOR INDICATORS",
    "variableLevel2": "VAPOR PRESSURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR",
    "variableLevel1": "WATER VAPOR INDICATORS",
    "variableLevel2": "WATER VAPOR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR",
    "variableLevel1": "WATER VAPOR INDICES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR",
    "variableLevel1": "WATER VAPOR INDICES",
    "variableLevel2": "HUMIDITY INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR",
    "variableLevel1": "WATER VAPOR INDICES",
    "variableLevel2": "WATER VAPOR TRANSPORT INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR",
    "variableLevel1": "WATER VAPOR PROCESSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR",
    "variableLevel1": "WATER VAPOR PROCESSES",
    "variableLevel2": "CONDENSATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR",
    "variableLevel1": "WATER VAPOR PROCESSES",
    "variableLevel2": "EVAPORATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR",
    "variableLevel1": "WATER VAPOR PROCESSES",
    "variableLevel2": "EVAPOTRANSPIRATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR",
    "variableLevel1": "WATER VAPOR PROCESSES",
    "variableLevel2": "EVAPOTRANSPIRATION",
    "variableLevel3": "EFFECTIVE EVAPOTRANSPIRATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR",
    "variableLevel1": "WATER VAPOR PROCESSES",
    "variableLevel2": "EVAPOTRANSPIRATION",
    "variableLevel3": "POTENTIAL EVAPOTRANSPIRATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR",
    "variableLevel1": "WATER VAPOR PROCESSES",
    "variableLevel2": "SUBLIMATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR",
    "variableLevel1": "WATER VAPOR PROCESSES",
    "variableLevel2": "SUPERSATURATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR",
    "variableLevel1": "WATER VAPOR PROCESSES",
    "variableLevel2": "WATER VAPOR CONVERGENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR",
    "variableLevel1": "WATER VAPOR PROCESSES",
    "variableLevel2": "WATER VAPOR DIVERGENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR",
    "variableLevel1": "WATER VAPOR PROCESSES",
    "variableLevel2": "WATER VAPOR FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR",
    "variableLevel1": "WATER VAPOR PROCESSES",
    "variableLevel2": "WATER VAPOR TENDENCY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR",
    "variableLevel1": "WATER VAPOR PROFILES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR",
    "variableLevel1": "WATER VAPOR PROFILES",
    "variableLevel2": "VERTICALLY RESOLVED BACKSCATTER LIGHT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR",
    "variableLevel1": "WATER VAPOR PROFILES",
    "variableLevel2": "WATER VAPOR CONCENTRATION PROFILES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WATER VAPOR",
    "variableLevel1": "WATER VAPOR PROFILES",
    "variableLevel2": "WATER VAPOR MIXING RATIO PROFILES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "LOCAL WINDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "LOCAL WINDS",
    "variableLevel2": "DUST DEVILS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "LOCAL WINDS",
    "variableLevel2": "LAND BREEZES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "LOCAL WINDS",
    "variableLevel2": "MICROBURSTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "LOCAL WINDS",
    "variableLevel2": "OROGRAPHIC WINDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "LOCAL WINDS",
    "variableLevel2": "OROGRAPHIC WINDS",
    "variableLevel3": "ANABATIC WINDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "LOCAL WINDS",
    "variableLevel2": "OROGRAPHIC WINDS",
    "variableLevel3": "BORA WINDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "LOCAL WINDS",
    "variableLevel2": "OROGRAPHIC WINDS",
    "variableLevel3": "FOEHN WINDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "LOCAL WINDS",
    "variableLevel2": "OROGRAPHIC WINDS",
    "variableLevel3": "KATABATIC WINDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "LOCAL WINDS",
    "variableLevel2": "OROGRAPHIC WINDS",
    "variableLevel3": "MOUNTAIN BREEZES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "LOCAL WINDS",
    "variableLevel2": "OROGRAPHIC WINDS",
    "variableLevel3": "VALLEY BREEZES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "LOCAL WINDS",
    "variableLevel2": "SEA BREEZES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "SURFACE WINDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "SURFACE WINDS",
    "variableLevel2": "STORM RELATIVE WINDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "SURFACE WINDS",
    "variableLevel2": "U/V WIND COMPONENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "SURFACE WINDS",
    "variableLevel2": "WIND DIRECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "SURFACE WINDS",
    "variableLevel2": "WIND DIRECTION TENDENCY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "SURFACE WINDS",
    "variableLevel2": "WIND SPEED"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "SURFACE WINDS",
    "variableLevel2": "WIND SPEED TENDENCY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "UPPER LEVEL WINDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "UPPER LEVEL WINDS",
    "variableLevel2": "BOUNDARY LAYER WINDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "UPPER LEVEL WINDS",
    "variableLevel2": "FLIGHT LEVEL WINDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "UPPER LEVEL WINDS",
    "variableLevel2": "STORM RELATIVE WINDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "UPPER LEVEL WINDS",
    "variableLevel2": "U/V WIND COMPONENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "UPPER LEVEL WINDS",
    "variableLevel2": "WIND DIRECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "UPPER LEVEL WINDS",
    "variableLevel2": "WIND DIRECTION TENDENCY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "UPPER LEVEL WINDS",
    "variableLevel2": "WIND SPEED"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "UPPER LEVEL WINDS",
    "variableLevel2": "WIND SPEED TENDENCY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "WIND DYNAMICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "WIND DYNAMICS",
    "variableLevel2": "ADVECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "WIND DYNAMICS",
    "variableLevel2": "CONVECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "WIND DYNAMICS",
    "variableLevel2": "CONVERGENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "WIND DYNAMICS",
    "variableLevel2": "DIVERGENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "WIND DYNAMICS",
    "variableLevel2": "HORIZONTAL WIND VELOCITY/SPEED"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "WIND DYNAMICS",
    "variableLevel2": "OROGRAPHIC LIFTING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "WIND DYNAMICS",
    "variableLevel2": "STREAMFUNCTIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "WIND DYNAMICS",
    "variableLevel2": "TURBULENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "WIND DYNAMICS",
    "variableLevel2": "VERTICAL WIND VELOCITY/SPEED"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "WIND DYNAMICS",
    "variableLevel2": "VORTICITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "WIND DYNAMICS",
    "variableLevel2": "VORTICITY",
    "variableLevel3": "POTENTIAL VORTICITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "WIND DYNAMICS",
    "variableLevel2": "VORTICITY",
    "variableLevel3": "VORTICITY ADVECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "WIND DYNAMICS",
    "variableLevel2": "WIND SHEAR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "WIND DYNAMICS",
    "variableLevel2": "WIND SHEAR",
    "variableLevel3": "HORIZONTAL WIND SHEAR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "WIND DYNAMICS",
    "variableLevel2": "WIND SHEAR",
    "variableLevel3": "VERTICAL WIND SHEAR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "WIND DYNAMICS",
    "variableLevel2": "WIND STRESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "WIND INDICES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "WIND INDICES",
    "variableLevel2": "GOES WIND INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "WIND INDICES",
    "variableLevel2": "QUASI-BIENNIAL OSCILLATION (QBO) ZONAL WIND INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "WIND PROFILES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "WIND PROFILES",
    "variableLevel2": "LINE OF SIGHT WINDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "WIND PROFILES",
    "variableLevel2": "VELOCITY AZIMUTH DISPLAY VERTICAL WIND PROFILES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "WIND PROFILES",
    "variableLevel2": "WIND DIRECTION PROFILES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "ATMOSPHERIC WINDS",
    "variableLevel1": "WIND PROFILES",
    "variableLevel2": "WIND VELOCITY/SPEED PROFILES"
  },
  { "category": "EARTH SCIENCE", "topic": "ATMOSPHERE", "term": "CLOUDS" },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD DROPLET DISTRIBUTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD DYNAMICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD DYNAMICS",
    "variableLevel2": "HEAT FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD DYNAMICS",
    "variableLevel2": "MOISTURE FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD DYNAMICS",
    "variableLevel2": "MOISTURE FLUX",
    "variableLevel3": "DOWNWARD MOISTURE FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD DYNAMICS",
    "variableLevel2": "MOISTURE FLUX",
    "variableLevel3": "UPWARD MOISTURE FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD DYNAMICS",
    "variableLevel2": "RADIATIONAL COOLING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD DYNAMICS",
    "variableLevel2": "RADIATIONAL DIVERGENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD DYNAMICS",
    "variableLevel2": "THETA-E ENTRAINMENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD DYNAMICS",
    "variableLevel2": "VORTEX STREET"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD DYNAMICS",
    "variableLevel2": "VORTEX STREET",
    "variableLevel3": "KARMAN VORTEX STREET"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD DYNAMICS",
    "variableLevel2": "WATER VAPOR TRANSPORT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD MICROPHYSICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD MICROPHYSICS",
    "variableLevel2": "CLOUD CONDENSATION NUCLEI"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD MICROPHYSICS",
    "variableLevel2": "CLOUD DROPLET CONCENTRATION/SIZE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD MICROPHYSICS",
    "variableLevel2": "CLOUD LIQUID WATER/ICE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD MICROPHYSICS",
    "variableLevel2": "CLOUD MASS FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD MICROPHYSICS",
    "variableLevel2": "CLOUD OPTICAL DEPTH/THICKNESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD MICROPHYSICS",
    "variableLevel2": "CLOUD PRECIPITABLE WATER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD MICROPHYSICS",
    "variableLevel2": "COLLISION RATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD MICROPHYSICS",
    "variableLevel2": "DROPLET GROWTH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD MICROPHYSICS",
    "variableLevel2": "DROPLET GROWTH",
    "variableLevel3": "ACCRETION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD MICROPHYSICS",
    "variableLevel2": "DROPLET GROWTH",
    "variableLevel3": "ACCRETION",
    "detailedVariable": "RIMING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD MICROPHYSICS",
    "variableLevel2": "DROPLET GROWTH",
    "variableLevel3": "AGGREGATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD MICROPHYSICS",
    "variableLevel2": "DROPLET GROWTH",
    "variableLevel3": "COALESCENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD MICROPHYSICS",
    "variableLevel2": "ICE NUCLEI"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD MICROPHYSICS",
    "variableLevel2": "PARTICLE SIZE DISTRIBUTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD MICROPHYSICS",
    "variableLevel2": "SEDIMENTATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD MICROPHYSICS",
    "variableLevel2": "SEDIMENTATION",
    "variableLevel3": "SEDIMENTATION RATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD PROPERTIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD PROPERTIES",
    "variableLevel2": "CLOUD AMOUNT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD PROPERTIES",
    "variableLevel2": "CLOUD ASYMMETRY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD PROPERTIES",
    "variableLevel2": "CLOUD BASE HEIGHT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD PROPERTIES",
    "variableLevel2": "CLOUD BASE PRESSURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD PROPERTIES",
    "variableLevel2": "CLOUD BASE TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD PROPERTIES",
    "variableLevel2": "CLOUD CEILING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD PROPERTIES",
    "variableLevel2": "CLOUD FRACTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD PROPERTIES",
    "variableLevel2": "CLOUD FREQUENCY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD PROPERTIES",
    "variableLevel2": "CLOUD HEIGHT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD PROPERTIES",
    "variableLevel2": "CLOUD MIDLAYER TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD PROPERTIES",
    "variableLevel2": "CLOUD TOP HEIGHT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD PROPERTIES",
    "variableLevel2": "CLOUD TOP PHASE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD PROPERTIES",
    "variableLevel2": "CLOUD TOP PRESSURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD PROPERTIES",
    "variableLevel2": "CLOUD TOP TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD PROPERTIES",
    "variableLevel2": "CLOUD VERTICAL DISTRIBUTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD RADIATIVE TRANSFER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD RADIATIVE TRANSFER",
    "variableLevel2": "ABSORPTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD RADIATIVE TRANSFER",
    "variableLevel2": "CLOUD EMISSIVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD RADIATIVE TRANSFER",
    "variableLevel2": "CLOUD RADIATIVE FORCING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD RADIATIVE TRANSFER",
    "variableLevel2": "CLOUD REFLECTANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD RADIATIVE TRANSFER",
    "variableLevel2": "DROPLET GROWTH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD RADIATIVE TRANSFER",
    "variableLevel2": "EMISSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD RADIATIVE TRANSFER",
    "variableLevel2": "SCATTERING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CLOUD TYPES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CONVECTIVE CLOUDS/SYSTEMS (OBSERVED/ANALYZED)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CONVECTIVE CLOUDS/SYSTEMS (OBSERVED/ANALYZED)",
    "variableLevel2": "CLOUD CLUSTERS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CONVECTIVE CLOUDS/SYSTEMS (OBSERVED/ANALYZED)",
    "variableLevel2": "CUMULONIMBUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CONVECTIVE CLOUDS/SYSTEMS (OBSERVED/ANALYZED)",
    "variableLevel2": "CUMULONIMBUS",
    "variableLevel3": "CUMULONIMBUS CALVUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CONVECTIVE CLOUDS/SYSTEMS (OBSERVED/ANALYZED)",
    "variableLevel2": "CUMULONIMBUS",
    "variableLevel3": "CUMULONIMBUS CAPILLATUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CONVECTIVE CLOUDS/SYSTEMS (OBSERVED/ANALYZED)",
    "variableLevel2": "CUMULONIMBUS",
    "variableLevel3": "CUMULONIMBUS CAPILLATUS",
    "detailedVariable": "CUMULONIMBUS INCUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CONVECTIVE CLOUDS/SYSTEMS (OBSERVED/ANALYZED)",
    "variableLevel2": "CUMULONIMBUS",
    "variableLevel3": "PYROCUMULONIMBUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CONVECTIVE CLOUDS/SYSTEMS (OBSERVED/ANALYZED)",
    "variableLevel2": "CUMULUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CONVECTIVE CLOUDS/SYSTEMS (OBSERVED/ANALYZED)",
    "variableLevel2": "CUMULUS",
    "variableLevel3": "CUMULUS CASTELLANUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CONVECTIVE CLOUDS/SYSTEMS (OBSERVED/ANALYZED)",
    "variableLevel2": "CUMULUS",
    "variableLevel3": "CUMULUS CONGESTUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CONVECTIVE CLOUDS/SYSTEMS (OBSERVED/ANALYZED)",
    "variableLevel2": "CUMULUS",
    "variableLevel3": "CUMULUS CONGESTUS",
    "detailedVariable": "TOWERING CUMULUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CONVECTIVE CLOUDS/SYSTEMS (OBSERVED/ANALYZED)",
    "variableLevel2": "CUMULUS",
    "variableLevel3": "CUMULUS HUMILIS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CONVECTIVE CLOUDS/SYSTEMS (OBSERVED/ANALYZED)",
    "variableLevel2": "CUMULUS",
    "variableLevel3": "CUMULUS HUMILIS",
    "detailedVariable": "FAIR WEATHER CUMULUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CONVECTIVE CLOUDS/SYSTEMS (OBSERVED/ANALYZED)",
    "variableLevel2": "CUMULUS",
    "variableLevel3": "CUMULUS MEDIOCRIS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CONVECTIVE CLOUDS/SYSTEMS (OBSERVED/ANALYZED)",
    "variableLevel2": "CUMULUS",
    "variableLevel3": "PYROCUMULUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CONVECTIVE CLOUDS/SYSTEMS (OBSERVED/ANALYZED)",
    "variableLevel2": "DEEP CONVECTIVE CLOUD SYSTEMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CONVECTIVE CLOUDS/SYSTEMS (OBSERVED/ANALYZED)",
    "variableLevel2": "MESOSCALE CONVECTIVE COMPLEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CONVECTIVE CLOUDS/SYSTEMS (OBSERVED/ANALYZED)",
    "variableLevel2": "PERCENT CONVECTIVE CLOUDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CONVECTIVE CLOUDS/SYSTEMS (OBSERVED/ANALYZED)",
    "variableLevel2": "PRECIPITATING CONVECTIVE CLOUD SYSTEMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CONVECTIVE CLOUDS/SYSTEMS (OBSERVED/ANALYZED)",
    "variableLevel2": "SQUALL LINE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "CONVECTIVE CLOUDS/SYSTEMS (OBSERVED/ANALYZED)",
    "variableLevel2": "TROPICAL OCEANIC CLOUD SYSTEMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "MESOSPHERIC CLOUDS (OBSERVED/ANALYZED)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "MESOSPHERIC CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "NOCTILUCENT CLOUDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "MESOSPHERIC CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "POLAR MESOSPHERIC CLOUDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "STRATOSPHERIC CLOUDS (OBSERVED/ANALYZED)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "STRATOSPHERIC CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "POLAR STRATOSPHERIC CLOUDS/NACREOUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/HIGH-LEVEL CLOUDS (OBSERVED/ANALYZED)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/HIGH-LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "CIRROCUMULUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/HIGH-LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "CIRROSTRATUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/HIGH-LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "CIRRUS/SYSTEMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/HIGH-LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "CIRRUS/SYSTEMS",
    "variableLevel3": "CIRRUS CLOUD SYSTEMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/HIGH-LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "CIRRUS/SYSTEMS",
    "variableLevel3": "CIRRUS KELVIN-HELMHOLTZ COLOMBIAH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/HIGH-LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "CONTRAILS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/HIGH-LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "PILEUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/LOW LEVEL CLOUDS (OBSERVED/ANALYZED)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/LOW LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "FOG"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/LOW LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "FOG",
    "variableLevel3": "ADVECTION FOG"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/LOW LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "FOG",
    "variableLevel3": "FRONTAL FOG"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/LOW LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "FOG",
    "variableLevel3": "ICE FOG"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/LOW LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "FOG",
    "variableLevel3": "RADIATION FOG"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/LOW LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "FOG",
    "variableLevel3": "STEAM FOG"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/LOW LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "FOG",
    "variableLevel3": "UPSLOPE FOG"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/LOW LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "NIMBOSTRATUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/LOW LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "STRATOCUMULUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/LOW LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "STRATOCUMULUS",
    "variableLevel3": "MARINE STRATOCUMULUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/LOW LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "STRATOCUMULUS",
    "variableLevel3": "STRATOCUMULUS CUMILIFORMIS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/LOW LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "STRATOCUMULUS",
    "variableLevel3": "STRATOCUMULUS CUMILIFORMIS",
    "detailedVariable": "STRATOCUMULUS CASTELLANUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/LOW LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "STRATOCUMULUS",
    "variableLevel3": "STRATOCUMULUS CUMILIFORMIS",
    "detailedVariable": "STRATOCUMULUS DIURNALIS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/LOW LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "STRATOCUMULUS",
    "variableLevel3": "STRATOCUMULUS CUMILIFORMIS",
    "detailedVariable": "STRATOCUMULUS MAMMATUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/LOW LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "STRATOCUMULUS",
    "variableLevel3": "STRATOCUMULUS CUMILIFORMIS",
    "detailedVariable": "STRATOCUMULUS VESPERALIS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/LOW LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "STRATOCUMULUS",
    "variableLevel3": "STRATOCUMULUS UNDULATAS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/LOW LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "STRATOCUMULUS",
    "variableLevel3": "STRATOCUMULUS UNDULATAS",
    "detailedVariable": "STRATOCUMULUS LENTICULARIS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/LOW LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "STRATOCUMULUS",
    "variableLevel3": "STRATOCUMULUS UNDULATAS",
    "detailedVariable": "STRATOCUMULUS OPACUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/LOW LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "STRATOCUMULUS",
    "variableLevel3": "STRATOCUMULUS UNDULATAS",
    "detailedVariable": "STRATOCUMULUS PERLUCIDUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/LOW LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "STRATOCUMULUS",
    "variableLevel3": "STRATOCUMULUS UNDULATAS",
    "detailedVariable": "STRATOCUMULUS TRANSLUCIDUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/LOW LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "STRATUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/MID-LEVEL CLOUDS (OBSERVED/ANALYZED)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/MID-LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "ALTOCUMULUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/MID-LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "ALTOCUMULUS",
    "variableLevel3": "ALTOCUMULUS CASTELLANUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/MID-LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "ALTOCUMULUS",
    "variableLevel3": "ALTOCUMULUS LENTICULARIS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/MID-LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "ALTOCUMULUS",
    "variableLevel3": "ALTOCUMULUS LENTICULARIS",
    "detailedVariable": "LENTICULAR CLOUDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/MID-LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "ALTOCUMULUS",
    "variableLevel3": "ALTOCUMULUS UNDULATUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/MID-LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "ALTOSTRATUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "CLOUDS",
    "variableLevel1": "TROPOSPHERIC/MID-LEVEL CLOUDS (OBSERVED/ANALYZED)",
    "variableLevel2": "ALTOSTRATUS",
    "variableLevel3": "ALTOSTRATUS UNDULATUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "ACCUMULATIVE CONVECTIVE PRECIPITATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "ATMOSPHERIC PRECIPITATION INDICES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "ATMOSPHERIC PRECIPITATION INDICES",
    "variableLevel2": "CENTRAL INDIAN PRECIPITATION INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "ATMOSPHERIC PRECIPITATION INDICES",
    "variableLevel2": "ENSO PRECIPITATION INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "ATMOSPHERIC PRECIPITATION INDICES",
    "variableLevel2": "STANDARDIZED PRECIPITATION INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "ATMOSPHERIC PRECIPITATION INDICES",
    "variableLevel2": "WEIGHTED ANOMALY STANDARDIZED PRECIPITATION INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "DROPLET SIZE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "HYDROMETEORS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "LIQUID PRECIPITATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "LIQUID PRECIPITATION",
    "variableLevel2": "DRIZZLE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "LIQUID PRECIPITATION",
    "variableLevel2": "DRIZZLE",
    "variableLevel3": "FREEZING DRIZZLE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "LIQUID PRECIPITATION",
    "variableLevel2": "LIQUID SURFACE PRECIPITATION RATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "LIQUID PRECIPITATION",
    "variableLevel2": "RAIN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "LIQUID PRECIPITATION",
    "variableLevel2": "RAIN",
    "variableLevel3": "ACID RAIN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "LIQUID PRECIPITATION",
    "variableLevel2": "RAIN",
    "variableLevel3": "FREEZING RAIN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "LIQUID WATER EQUIVALENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "PRECIPITATION AMOUNT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "PRECIPITATION AMOUNT",
    "variableLevel2": "12 HOUR PRECIPITATION AMOUNT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "PRECIPITATION AMOUNT",
    "variableLevel2": "24 HOUR PRECIPITATION AMOUNT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "PRECIPITATION AMOUNT",
    "variableLevel2": "3 AND 6 HOUR PRECIPITATION AMOUNT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "PRECIPITATION AMOUNT",
    "variableLevel2": "HOURLY PRECIPITATION AMOUNT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "PRECIPITATION ANOMALIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "PRECIPITATION PROFILES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "PRECIPITATION PROFILES",
    "variableLevel2": "CLOUD WATER PATH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "PRECIPITATION PROFILES",
    "variableLevel2": "ICE WATER PATH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "PRECIPITATION PROFILES",
    "variableLevel2": "LATENT HEAT FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "PRECIPITATION PROFILES",
    "variableLevel2": "MELTING LAYER HEIGHT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "PRECIPITATION PROFILES",
    "variableLevel2": "RAIN TYPE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "PRECIPITATION PROFILES",
    "variableLevel2": "RAIN WATER PATH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "PRECIPITATION RATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "PRECIPITATION RATE",
    "variableLevel2": "CONVECTIVE PRECIPITATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "PRECIPITATION RATE",
    "variableLevel2": "FROZEN PRECIPITATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "PRECIPITATION RATE",
    "variableLevel2": "SURFACE PRECIPITATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "SNOW WATER EQUIVALENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "SOLID PRECIPITATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "SOLID PRECIPITATION",
    "variableLevel2": "CONVECTIVE SURFACE PRECIPITATION RATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "SOLID PRECIPITATION",
    "variableLevel2": "HAIL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "SOLID PRECIPITATION",
    "variableLevel2": "ICE PELLETS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "SOLID PRECIPITATION",
    "variableLevel2": "ICE PELLETS",
    "variableLevel3": "SLEET"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "SOLID PRECIPITATION",
    "variableLevel2": "ICE PELLETS",
    "variableLevel3": "SMALL HAIL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "SOLID PRECIPITATION",
    "variableLevel2": "SNOW"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "SOLID PRECIPITATION",
    "variableLevel2": "SNOW",
    "variableLevel3": "SNOW GRAINS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "SOLID PRECIPITATION",
    "variableLevel2": "SNOW",
    "variableLevel3": "SNOW PELLETS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "TOTAL SURFACE PRECIPITATION RATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "PRECIPITATION",
    "variableLevel1": "VIRGA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "COLD WAVE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "DROUGHTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "DROUGHTS",
    "variableLevel2": "DROUGHT DURATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "DROUGHTS",
    "variableLevel2": "DROUGHT FREQUENCY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "DROUGHTS",
    "variableLevel2": "DROUGHT SEVERITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "EXTRATROPICAL CYCLONES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "EXTRATROPICAL CYCLONES",
    "variableLevel2": "EXTRATROPICAL CYCLONE FREQUENCY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "EXTRATROPICAL CYCLONES",
    "variableLevel2": "EXTRATROPICAL CYCLONE MOTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "EXTRATROPICAL CYCLONES",
    "variableLevel2": "EXTRATROPICAL CYCLONE TRACK"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "FOG"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "FREEZE/FROST"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "FREEZE/FROST",
    "variableLevel2": "FIRST FREEZE/FROST DATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "FREEZE/FROST",
    "variableLevel2": "FIRST FREEZE/FROST PROBABILITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "FREEZE/FROST",
    "variableLevel2": "FIRST MODERATE FREEZE/FROST DATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "FREEZE/FROST",
    "variableLevel2": "FREEZE FREE PERIOD LENGTH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "FREEZE/FROST",
    "variableLevel2": "LAST FREEZE/FROST DATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "FREEZE/FROST",
    "variableLevel2": "LAST FREEZE/FROST PROBABILITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "FREEZE/FROST",
    "variableLevel2": "LAST MODERATE FREEZE/FROST DATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "HAIL STORMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "HEAT WAVE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "ICE STORMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "ICE STORMS",
    "variableLevel2": "TOTAL FREEZING RAIN ACCUMULATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "LIGHTNING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "MONSOONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "RAIN STORMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "SNOW STORMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "SNOW STORMS",
    "variableLevel2": "BLIZZARDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "SNOW STORMS",
    "variableLevel2": "BLOWING SNOW"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "SNOW STORMS",
    "variableLevel2": "LAKE EFFECT SNOW"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "SUBTROPICAL CYCLONES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "SUBTROPICAL CYCLONES",
    "variableLevel2": "SUBTROPICAL DEPRESSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "SUBTROPICAL CYCLONES",
    "variableLevel2": "SUBTROPICAL DEPRESSION",
    "variableLevel3": "SUBTROPICAL DEPRESSION TRACK"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "SUBTROPICAL CYCLONES",
    "variableLevel2": "SUBTROPICAL STORM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "SUBTROPICAL CYCLONES",
    "variableLevel2": "SUBTROPICAL STORM",
    "variableLevel3": "SUBTROPICAL STORM MOTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "SUBTROPICAL CYCLONES",
    "variableLevel2": "SUBTROPICAL STORM",
    "variableLevel3": "SUBTROPICAL STORM TRACK"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "Stability/Severe Weather Indices"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "Stability/Severe Weather Indices",
    "variableLevel2": "CONVECTIVE AVAILABLE POTENTIAL ENERGY (CAPE)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "Stability/Severe Weather Indices",
    "variableLevel2": "K-index (KI)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "Stability/Severe Weather Indices",
    "variableLevel2": "LIFTED INDEX (LI)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "Stability/Severe Weather Indices",
    "variableLevel2": "SHOWALTER STABILITY INDEX (SI)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "Stability/Severe Weather Indices",
    "variableLevel2": "TOTAL TOTALS INDEX (TT)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TORNADOES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TORNADOES",
    "variableLevel2": "DESTRUCTION POTENTIAL INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TORNADOES",
    "variableLevel2": "ENHANCED FUJITA SCALE RATING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TORNADOES",
    "variableLevel2": "STORM SYSTEM MOTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TORNADOES",
    "variableLevel2": "TORNADO CLIMATOLOGY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TORNADOES",
    "variableLevel2": "TORNADO DENSITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TORNADOES",
    "variableLevel2": "TORNADO FREQUENCY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TORNADOES",
    "variableLevel2": "TORNADO PATH LENGTH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TORNADOES",
    "variableLevel2": "TORNADO PATH WIDTH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TORNADOES",
    "variableLevel2": "TORNADO VORTEX SIGNATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TORNADOES",
    "variableLevel2": "WATER SPOUT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "ACCUMULATED CYCLONE ENERGY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "ACCUMULATED CYCLONE ENERGY",
    "variableLevel3": "CYCLONES (SW INDIAN)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "ACCUMULATED CYCLONE ENERGY",
    "variableLevel3": "HURRICANES  (N. ATLANTIC/E. PACIFIC)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "ACCUMULATED CYCLONE ENERGY",
    "variableLevel3": "SEVERE CYCLONIC STORMS (N. INDIAN)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "ACCUMULATED CYCLONE ENERGY",
    "variableLevel3": "SEVERE TROPICAL CYCLONES (SW PACIFIC/SE INDIAN)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "ACCUMULATED CYCLONE ENERGY",
    "variableLevel3": "TYPHOONS (WESTERN N. PACIFIC)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "LANDFALL INTENSITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "LANDFALL INTENSITY",
    "variableLevel3": "CYCLONES (SW INDIAN)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "LANDFALL INTENSITY",
    "variableLevel3": "HURRICANES  (N. ATLANTIC/E. PACIFIC)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "LANDFALL INTENSITY",
    "variableLevel3": "SEVERE CYCLONIC STORMS (N. INDIAN)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "LANDFALL INTENSITY",
    "variableLevel3": "SEVERE TROPICAL CYCLONES (SW PACIFIC/SE INDIAN)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "LANDFALL INTENSITY",
    "variableLevel3": "TYPHOONS (WESTERN N. PACIFIC)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "MAXIMUM 1-MINUTE SUSTAINED WIND"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "MAXIMUM 1-MINUTE SUSTAINED WIND",
    "variableLevel3": "CYCLONES (SW INDIAN)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "MAXIMUM 1-MINUTE SUSTAINED WIND",
    "variableLevel3": "HURRICANES  (N. ATLANTIC/E. PACIFIC)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "MAXIMUM 1-MINUTE SUSTAINED WIND",
    "variableLevel3": "SEVERE CYCLONIC STORMS (N. INDIAN)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "MAXIMUM 1-MINUTE SUSTAINED WIND",
    "variableLevel3": "SEVERE TROPICAL CYCLONES (SW PACIFIC/SE INDIAN)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "MAXIMUM 1-MINUTE SUSTAINED WIND",
    "variableLevel3": "TYPHOONS (WESTERN N. PACIFIC)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "MAXIMUM SURFACE WIND"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "MAXIMUM SURFACE WIND",
    "variableLevel3": "CYCLONES (SW INDIAN)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "MAXIMUM SURFACE WIND",
    "variableLevel3": "HURRICANES  (N. ATLANTIC/E. PACIFIC)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "MAXIMUM SURFACE WIND",
    "variableLevel3": "SEVERE CYCLONIC STORMS (N. INDIAN)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "MAXIMUM SURFACE WIND",
    "variableLevel3": "SEVERE TROPICAL CYCLONES (SW PACIFIC/SE INDIAN)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "MAXIMUM SURFACE WIND",
    "variableLevel3": "TYPHOONS (WESTERN N. PACIFIC)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "MAXIMUM WIND GUST"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "MAXIMUM WIND GUST",
    "variableLevel3": "CYCLONES (SW INDIAN)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "MAXIMUM WIND GUST",
    "variableLevel3": "HURRICANES  (N. ATLANTIC/E. PACIFIC)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "MAXIMUM WIND GUST",
    "variableLevel3": "SEVERE CYCLONIC STORMS (N. INDIAN)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "MAXIMUM WIND GUST",
    "variableLevel3": "SEVERE TROPICAL CYCLONES (SW PACIFIC/SE INDIAN)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "MAXIMUM WIND GUST",
    "variableLevel3": "TYPHOONS (WESTERN N. PACIFIC)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "MINIMUM CENTRAL PRESSURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "MINIMUM CENTRAL PRESSURE",
    "variableLevel3": "CYCLONES (SW INDIAN)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "MINIMUM CENTRAL PRESSURE",
    "variableLevel3": "HURRICANES  (N. ATLANTIC/E. PACIFIC)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "MINIMUM CENTRAL PRESSURE",
    "variableLevel3": "SEVERE CYCLONIC STORMS (N. INDIAN)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "MINIMUM CENTRAL PRESSURE",
    "variableLevel3": "SEVERE TROPICAL CYCLONES (SW PACIFIC/SE INDIAN)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "MINIMUM CENTRAL PRESSURE",
    "variableLevel3": "TYPHOONS (WESTERN N. PACIFIC)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "PEAK INTENSITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "PEAK INTENSITY",
    "variableLevel3": "CYCLONES (SW INDIAN)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "PEAK INTENSITY",
    "variableLevel3": "HURRICANES  (N. ATLANTIC/E. PACIFIC)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "PEAK INTENSITY",
    "variableLevel3": "SEVERE CYCLONIC STORMS (N. INDIAN)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "PEAK INTENSITY",
    "variableLevel3": "SEVERE TROPICAL CYCLONES (SW PACIFIC/SE INDIAN)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "PEAK INTENSITY",
    "variableLevel3": "TYPHOONS (WESTERN N. PACIFIC)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "SAFFIR-SIMPSON SCALE AT LANDFALL (CATEGORY 1)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "SAFFIR-SIMPSON SCALE AT LANDFALL (CATEGORY 1)",
    "variableLevel3": "HURRICANES  (N. ATLANTIC/E. PACIFIC)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "SAFFIR-SIMPSON SCALE AT LANDFALL (CATEGORY 2)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "SAFFIR-SIMPSON SCALE AT LANDFALL (CATEGORY 2)",
    "variableLevel3": "HURRICANES  (N. ATLANTIC/E. PACIFIC)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "SAFFIR-SIMPSON SCALE AT LANDFALL (CATEGORY 3)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "SAFFIR-SIMPSON SCALE AT LANDFALL (CATEGORY 3)",
    "variableLevel3": "HURRICANES  (N. ATLANTIC/E. PACIFIC)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "SAFFIR-SIMPSON SCALE AT LANDFALL (CATEGORY 4)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "SAFFIR-SIMPSON SCALE AT LANDFALL (CATEGORY 4)",
    "variableLevel3": "HURRICANES  (N. ATLANTIC/E. PACIFIC)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "SAFFIR-SIMPSON SCALE AT LANDFALL (CATEGORY 5)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "SAFFIR-SIMPSON SCALE AT LANDFALL (CATEGORY 5)",
    "variableLevel3": "HURRICANES  (N. ATLANTIC/E. PACIFIC)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "TROPICAL CYCLONE FORCE WIND EXTENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "TROPICAL CYCLONE FORCE WIND EXTENT",
    "variableLevel3": "CYCLONES (SW INDIAN)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "TROPICAL CYCLONE FORCE WIND EXTENT",
    "variableLevel3": "HURRICANES  (N. ATLANTIC/E. PACIFIC)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "TROPICAL CYCLONE FORCE WIND EXTENT",
    "variableLevel3": "SEVERE CYCLONIC STORMS (N. INDIAN)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "TROPICAL CYCLONE FORCE WIND EXTENT",
    "variableLevel3": "SEVERE TROPICAL CYCLONES (SW PACIFIC/SE INDIAN)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "TROPICAL CYCLONE FORCE WIND EXTENT",
    "variableLevel3": "TYPHOONS (WESTERN N. PACIFIC)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "TROPICAL CYCLONE MOTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "TROPICAL CYCLONE MOTION",
    "variableLevel3": "CYCLONES (SW INDIAN)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "TROPICAL CYCLONE MOTION",
    "variableLevel3": "HURRICANES  (N. ATLANTIC/E. PACIFIC)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "TROPICAL CYCLONE MOTION",
    "variableLevel3": "SEVERE CYCLONIC STORMS (N. INDIAN)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "TROPICAL CYCLONE MOTION",
    "variableLevel3": "SEVERE TROPICAL CYCLONES (SW PACIFIC/SE INDIAN)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "TROPICAL CYCLONE MOTION",
    "variableLevel3": "TYPHOONS (WESTERN N. PACIFIC)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "TROPICAL CYCLONE RADIUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "TROPICAL CYCLONE RADIUS",
    "variableLevel3": "CYCLONES (SW INDIAN)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "TROPICAL CYCLONE RADIUS",
    "variableLevel3": "HURRICANES  (N. ATLANTIC/E. PACIFIC)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "TROPICAL CYCLONE RADIUS",
    "variableLevel3": "SEVERE CYCLONIC STORMS (N. INDIAN)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "TROPICAL CYCLONE RADIUS",
    "variableLevel3": "SEVERE TROPICAL CYCLONES (SW PACIFIC/SE INDIAN)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "TROPICAL CYCLONE RADIUS",
    "variableLevel3": "TYPHOONS (WESTERN N. PACIFIC)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "TROPICAL CYCLONE TRACK"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "TROPICAL CYCLONE TRACK",
    "variableLevel3": "CYCLONES (SW INDIAN)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "TROPICAL CYCLONE TRACK",
    "variableLevel3": "HURRICANES  (N. ATLANTIC/E. PACIFIC)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "TROPICAL CYCLONE TRACK",
    "variableLevel3": "SEVERE CYCLONIC STORMS (N. INDIAN)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "TROPICAL CYCLONE TRACK",
    "variableLevel3": "SEVERE TROPICAL CYCLONES (SW PACIFIC/SE INDIAN)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "TROPICAL CYCLONE TRACK",
    "variableLevel3": "TYPHOONS (WESTERN N. PACIFIC)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "TROPICAL DEPRESSION FREQUENCY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "TROPICAL DEPRESSION MOTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "TROPICAL DEPRESSION TRACK"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "TROPICAL STORM FORCE WIND EXTENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "TROPICAL STORM FREQUENCY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "TROPICAL STORM MOTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "TROPICAL STORM TRACK"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "WEATHER/CLIMATE ADVISORIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "WEATHER/CLIMATE ADVISORIES",
    "variableLevel2": "Weather Forecast"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "WIND STORMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "WIND STORMS",
    "variableLevel2": "DERECHO"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "WIND STORMS",
    "variableLevel2": "GALE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "WIND STORMS",
    "variableLevel2": "MICROBURST"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "ATMOSPHERE",
    "term": "WEATHER EVENTS",
    "variableLevel1": "WIND STORMS",
    "variableLevel2": "SQUALL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "ACORN WORMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "ARROW WORMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "ARTHROPODS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "ARTHROPODS",
    "variableLevel2": "CHELICERATES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "ARTHROPODS",
    "variableLevel2": "CHELICERATES",
    "variableLevel3": "ARACHNIDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "ARTHROPODS",
    "variableLevel2": "CRUSTACEANS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "ARTHROPODS",
    "variableLevel2": "CRUSTACEANS",
    "variableLevel3": "AMPHIPODS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "ARTHROPODS",
    "variableLevel2": "CRUSTACEANS",
    "variableLevel3": "BARNACLES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "ARTHROPODS",
    "variableLevel2": "CRUSTACEANS",
    "variableLevel3": "COPEPODS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "ARTHROPODS",
    "variableLevel2": "CRUSTACEANS",
    "variableLevel3": "DECAPODS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "ARTHROPODS",
    "variableLevel2": "CRUSTACEANS",
    "variableLevel3": "EUPHAUSIIDS (KRILL)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "ARTHROPODS",
    "variableLevel2": "CRUSTACEANS",
    "variableLevel3": "ISOPODS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "ARTHROPODS",
    "variableLevel2": "CRUSTACEANS",
    "variableLevel3": "MYSIDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "ARTHROPODS",
    "variableLevel2": "CRUSTACEANS",
    "variableLevel3": "OSTRACODS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "ARTHROPODS",
    "variableLevel2": "HEXAPODS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "ARTHROPODS",
    "variableLevel2": "HEXAPODS",
    "variableLevel3": "ENTOGNATHA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "ARTHROPODS",
    "variableLevel2": "HEXAPODS",
    "variableLevel3": "INSECTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "ARTHROPODS",
    "variableLevel2": "MYRIAPODS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "ARTHROPODS",
    "variableLevel2": "MYRIAPODS",
    "variableLevel3": "CENTIPEDES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "ARTHROPODS",
    "variableLevel2": "MYRIAPODS",
    "variableLevel3": "MILLIPEDES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "BRYOZOANS/MOSS ANIMALS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "BURROWS/SPOON WORMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "CNIDARIANS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "CNIDARIANS",
    "variableLevel2": "ANTHOZOANS/HEXACORALS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "CNIDARIANS",
    "variableLevel2": "ANTHOZOANS/HEXACORALS",
    "variableLevel3": "HARD OR STONY CORALS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "CNIDARIANS",
    "variableLevel2": "ANTHOZOANS/HEXACORALS",
    "variableLevel3": "SEA ANEMONES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "CNIDARIANS",
    "variableLevel2": "ANTHOZOANS/OCTOCORALS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "CNIDARIANS",
    "variableLevel2": "ANTHOZOANS/OCTOCORALS",
    "variableLevel3": "SEA FANS/SEA WHIPS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "CNIDARIANS",
    "variableLevel2": "ANTHOZOANS/OCTOCORALS",
    "variableLevel3": "SEA PENS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "CNIDARIANS",
    "variableLevel2": "ANTHOZOANS/OCTOCORALS",
    "variableLevel3": "SOFT CORALS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "CNIDARIANS",
    "variableLevel2": "HYDROZOANS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "CNIDARIANS",
    "variableLevel2": "JELLYFISHES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "COMB JELLIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "ECHINODERMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "ECHINODERMS",
    "variableLevel2": "BRITTLE/BASKET STARS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "ECHINODERMS",
    "variableLevel2": "SEA STARS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "ECHINODERMS",
    "variableLevel2": "SEA URCHINS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "ENTOPROCTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "FLATWORMS/FLUKES/TAPEWORMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "GNATHOSTOMULIDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "HORSEHAIR WORMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "LAMP SHELLS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "LORICIFERANS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "MOLLUSKS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "MOLLUSKS",
    "variableLevel2": "APLACOPHORANS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "MOLLUSKS",
    "variableLevel2": "BIVALVES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "MOLLUSKS",
    "variableLevel2": "BIVALVES",
    "variableLevel3": "CLAMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "MOLLUSKS",
    "variableLevel2": "BIVALVES",
    "variableLevel3": "MUSSELS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "MOLLUSKS",
    "variableLevel2": "BIVALVES",
    "variableLevel3": "OYSTERS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "MOLLUSKS",
    "variableLevel2": "CEPHALOPODS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "MOLLUSKS",
    "variableLevel2": "CEPHALOPODS",
    "variableLevel3": "SQUIDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "MOLLUSKS",
    "variableLevel2": "CHITONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "MOLLUSKS",
    "variableLevel2": "GASTROPODS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "PEANUT WORMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "PHORONIDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "PRIAPULANS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "RIBBON WORMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "ROTIFERS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "ROUNDWORMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "SEGMENTED WORMS (ANNELIDS)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "SEGMENTED WORMS (ANNELIDS)",
    "variableLevel2": "BRISTLE WORMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "SEGMENTED WORMS (ANNELIDS)",
    "variableLevel2": "EARTHWORMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "SEGMENTED WORMS (ANNELIDS)",
    "variableLevel2": "LEECHES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "SPINY-HEADED WORMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "SPONGES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "TUNICATES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "TUNICATES",
    "variableLevel2": "LARVACEANS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "TUNICATES",
    "variableLevel2": "SALPS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "TUNICATES",
    "variableLevel2": "SEA SQUIRTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "WATER BEARS (TARDIGRADES)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/INVERTEBRATES",
    "variableLevel1": "WHEEL WEAVERS (CYCLIOPHORANS)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "AMPHIBIANS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "AMPHIBIANS",
    "variableLevel2": "FROGS/TOADS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "AMPHIBIANS",
    "variableLevel2": "SALAMANDERS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "BIRDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "BIRDS",
    "variableLevel2": "ALBATROSSES/PETRELS AND ALLIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "BIRDS",
    "variableLevel2": "CRANES AND ALLIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "BIRDS",
    "variableLevel2": "DUCKS/GEESE/SWANS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "BIRDS",
    "variableLevel2": "EAGLES/FALCONS/HAWKS AND ALLIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "BIRDS",
    "variableLevel2": "GREBES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "BIRDS",
    "variableLevel2": "HERONS/EGRETS AND ALLIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "BIRDS",
    "variableLevel2": "IBISES/SPOONBILLS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "BIRDS",
    "variableLevel2": "LOONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "BIRDS",
    "variableLevel2": "PELICANS AND ALLIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "BIRDS",
    "variableLevel2": "PENGUINS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "BIRDS",
    "variableLevel2": "PERCHING BIRDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "BIRDS",
    "variableLevel2": "SANDPIPERS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "BIRDS",
    "variableLevel2": "WADERS/GULLS/AUKS AND ALLIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "FISH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "FISH",
    "variableLevel2": "LAMPREYS/HAGFISHES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "FISH",
    "variableLevel2": "RAY-FINNED FISHES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "FISH",
    "variableLevel2": "RAY-FINNED FISHES",
    "variableLevel3": "ANCHOVIES/HERRINGS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "FISH",
    "variableLevel2": "RAY-FINNED FISHES",
    "variableLevel3": "CATFISHES/MINNOWS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "FISH",
    "variableLevel2": "RAY-FINNED FISHES",
    "variableLevel3": "CODS/HADDOCKS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "FISH",
    "variableLevel2": "RAY-FINNED FISHES",
    "variableLevel3": "FLOUNDERS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "FISH",
    "variableLevel2": "RAY-FINNED FISHES",
    "variableLevel3": "NEEDLEFISHES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "FISH",
    "variableLevel2": "RAY-FINNED FISHES",
    "variableLevel3": "PERCH-LIKE FISHES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "FISH",
    "variableLevel2": "RAY-FINNED FISHES",
    "variableLevel3": "PERCH-LIKE FISHES",
    "detailedVariable": "TUNAS AND ALLIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "FISH",
    "variableLevel2": "RAY-FINNED FISHES",
    "variableLevel3": "PUPFISHES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "FISH",
    "variableLevel2": "RAY-FINNED FISHES",
    "variableLevel3": "SALMONS/TROUTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "FISH",
    "variableLevel2": "RAY-FINNED FISHES",
    "variableLevel3": "STICKLEBACKS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "FISH",
    "variableLevel2": "RAY-FINNED FISHES",
    "variableLevel3": "STURGEONS/PADDLEFISHES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "FISH",
    "variableLevel2": "SHARKS/RAYS/CHIMAERAS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "MAMMALS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "MAMMALS",
    "variableLevel2": "BATS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "MAMMALS",
    "variableLevel2": "CARNIVORES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "MAMMALS",
    "variableLevel2": "CARNIVORES",
    "variableLevel3": "BEARS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "MAMMALS",
    "variableLevel2": "CARNIVORES",
    "variableLevel3": "DOGS/FOXES/WOLVES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "MAMMALS",
    "variableLevel2": "CARNIVORES",
    "variableLevel3": "MARTENS/WEASELS/WOLVERINES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "MAMMALS",
    "variableLevel2": "CARNIVORES",
    "variableLevel3": "OTTERS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "MAMMALS",
    "variableLevel2": "CARNIVORES",
    "variableLevel3": "SEALS/SEA LIONS/WALRUSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "MAMMALS",
    "variableLevel2": "CETACEANS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "MAMMALS",
    "variableLevel2": "CETACEANS",
    "variableLevel3": "BALEEN WHALES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "MAMMALS",
    "variableLevel2": "CETACEANS",
    "variableLevel3": "TOOTHED WHALES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "MAMMALS",
    "variableLevel2": "DUGONGS/MANATEES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "MAMMALS",
    "variableLevel2": "ELEPHANTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "MAMMALS",
    "variableLevel2": "EVEN-TOED UNGULATES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "MAMMALS",
    "variableLevel2": "EVEN-TOED UNGULATES",
    "variableLevel3": "CATTLE/SHEEP"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "MAMMALS",
    "variableLevel2": "EVEN-TOED UNGULATES",
    "variableLevel3": "DEER/MOOSE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "MAMMALS",
    "variableLevel2": "EVEN-TOED UNGULATES",
    "variableLevel3": "HOGS/PIGS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "MAMMALS",
    "variableLevel2": "RODENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "MARINE MAMMALS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "MARINE MAMMALS",
    "variableLevel2": "DOLPHINS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "MARINE MAMMALS",
    "variableLevel2": "PINNIPED"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "REPTILES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "REPTILES",
    "variableLevel2": "ALLIGATORS/CROCODILES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "REPTILES",
    "variableLevel2": "LIZARDS/SNAKES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "REPTILES",
    "variableLevel2": "TURTLES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "ANIMALS/VERTEBRATES",
    "variableLevel1": "REPTILES",
    "variableLevel2": "TURTLES",
    "variableLevel3": "SEA TURTLES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "BACTERIA/ARCHAEA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "BACTERIA/ARCHAEA",
    "variableLevel1": "CYANOBACTERIA (BLUE-GREEN ALGAE)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "FUNGI"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "FUNGI",
    "variableLevel1": "LICHENS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "FUNGI",
    "variableLevel1": "MUSHROOMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "FUNGI",
    "variableLevel1": "SLIME MOLDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "FUNGI",
    "variableLevel1": "YEASTS/TRUFFLES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PLANTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PLANTS",
    "variableLevel1": "ANGIOSPERMS (FLOWERING PLANTS)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PLANTS",
    "variableLevel1": "ANGIOSPERMS (FLOWERING PLANTS)",
    "variableLevel2": "DICOTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PLANTS",
    "variableLevel1": "ANGIOSPERMS (FLOWERING PLANTS)",
    "variableLevel2": "MONOCOTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PLANTS",
    "variableLevel1": "ANGIOSPERMS (FLOWERING PLANTS)",
    "variableLevel2": "MONOCOTS",
    "variableLevel3": "SEAGRASS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PLANTS",
    "variableLevel1": "FERNS AND ALLIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PLANTS",
    "variableLevel1": "FERNS AND ALLIES",
    "variableLevel2": "CLUB MOSSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PLANTS",
    "variableLevel1": "FERNS AND ALLIES",
    "variableLevel2": "FERNS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PLANTS",
    "variableLevel1": "FERNS AND ALLIES",
    "variableLevel2": "HORSETAILS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PLANTS",
    "variableLevel1": "FERNS AND ALLIES",
    "variableLevel2": "WHISK FERNS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PLANTS",
    "variableLevel1": "GYMNOSPERMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PLANTS",
    "variableLevel1": "GYMNOSPERMS",
    "variableLevel2": "CONIFERS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PLANTS",
    "variableLevel1": "GYMNOSPERMS",
    "variableLevel2": "CYCADS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PLANTS",
    "variableLevel1": "GYMNOSPERMS",
    "variableLevel2": "GINKGO"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PLANTS",
    "variableLevel1": "GYMNOSPERMS",
    "variableLevel2": "GNETOPS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PLANTS",
    "variableLevel1": "MACROALGAE (SEAWEEDS)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PLANTS",
    "variableLevel1": "MACROALGAE (SEAWEEDS)",
    "variableLevel2": "BROWN ALGAE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PLANTS",
    "variableLevel1": "MACROALGAE (SEAWEEDS)",
    "variableLevel2": "GREEN ALGAE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PLANTS",
    "variableLevel1": "MACROALGAE (SEAWEEDS)",
    "variableLevel2": "RED ALGAE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PLANTS",
    "variableLevel1": "MICROALGAE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PLANTS",
    "variableLevel1": "MICROALGAE",
    "variableLevel2": "CRYPTOMONADS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PLANTS",
    "variableLevel1": "MICROALGAE",
    "variableLevel2": "DIATOMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PLANTS",
    "variableLevel1": "MICROALGAE",
    "variableLevel2": "DINOFLAGELLATES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PLANTS",
    "variableLevel1": "MICROALGAE",
    "variableLevel2": "HAPTOPHYTES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PLANTS",
    "variableLevel1": "MICROALGAE",
    "variableLevel2": "HAPTOPHYTES",
    "variableLevel3": "COCCOLITHOPHORES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PLANTS",
    "variableLevel1": "MOSSES/HORNWORTS/LIVERWORTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PROTISTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PROTISTS",
    "variableLevel1": "AMOEBOIDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PROTISTS",
    "variableLevel1": "AMOEBOIDS",
    "variableLevel2": "AMOEBAS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PROTISTS",
    "variableLevel1": "AMOEBOIDS",
    "variableLevel2": "FORAMINIFERS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PROTISTS",
    "variableLevel1": "AMOEBOIDS",
    "variableLevel2": "RADIOLARIANS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PROTISTS",
    "variableLevel1": "CILIATES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PROTISTS",
    "variableLevel1": "DIATOMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PROTISTS",
    "variableLevel1": "FLAGELLATES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PROTISTS",
    "variableLevel1": "FLAGELLATES",
    "variableLevel2": "CRYPTOMONADS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PROTISTS",
    "variableLevel1": "FLAGELLATES",
    "variableLevel2": "DINOFLAGELLATES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PROTISTS",
    "variableLevel1": "FLAGELLATES",
    "variableLevel2": "HAPTOPHYTES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PROTISTS",
    "variableLevel1": "FLAGELLATES",
    "variableLevel2": "HAPTOPHYTES",
    "variableLevel3": "COCCOLITHOPHORES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PROTISTS",
    "variableLevel1": "MACROALGAE (SEAWEEDS)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PROTISTS",
    "variableLevel1": "MACROALGAE (SEAWEEDS)",
    "variableLevel2": "BROWN ALGAE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PROTISTS",
    "variableLevel1": "MACROALGAE (SEAWEEDS)",
    "variableLevel2": "GREEN ALGAE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PROTISTS",
    "variableLevel1": "MACROALGAE (SEAWEEDS)",
    "variableLevel2": "RED ALGAE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PROTISTS",
    "variableLevel1": "PLANKTON"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PROTISTS",
    "variableLevel1": "PLANKTON",
    "variableLevel2": "PHYTOPLANKTON"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PROTISTS",
    "variableLevel1": "SLIME MOLDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "PROTISTS",
    "variableLevel1": "SPOROZOANS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOLOGICAL CLASSIFICATION",
    "term": "VIRUSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "COMMUNITY DYNAMICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "COMMUNITY DYNAMICS",
    "variableLevel2": "BIODIVERSITY FUNCTIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "COMMUNITY DYNAMICS",
    "variableLevel2": "COMMUNITY STRUCTURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "COMMUNITY DYNAMICS",
    "variableLevel2": "GRAZING DYNAMICS / PLANT ECOLOGY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "COMMUNITY DYNAMICS",
    "variableLevel2": "INDICATOR SPECIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "COMMUNITY DYNAMICS",
    "variableLevel2": "INVASIVE SPECIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "COMMUNITY DYNAMICS",
    "variableLevel2": "PLANT SUCCESSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "COMMUNITY DYNAMICS",
    "variableLevel2": "SPECIES DOMINANCE INDICES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "COMMUNITY DYNAMICS",
    "variableLevel2": "SPECIES RECRUITMENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "ECOSYSTEM FUNCTIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "ECOSYSTEM FUNCTIONS",
    "variableLevel2": "BIOGEOCHEMICAL CYCLES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "ECOSYSTEM FUNCTIONS",
    "variableLevel2": "BIOMASS DYNAMICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "ECOSYSTEM FUNCTIONS",
    "variableLevel2": "CARBON SEQUESTRATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "ECOSYSTEM FUNCTIONS",
    "variableLevel2": "CHEMOSYNTHESIS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "ECOSYSTEM FUNCTIONS",
    "variableLevel2": "CONSUMPTION RATES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "ECOSYSTEM FUNCTIONS",
    "variableLevel2": "DECOMPOSITION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "ECOSYSTEM FUNCTIONS",
    "variableLevel2": "EXCRETION RATES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "ECOSYSTEM FUNCTIONS",
    "variableLevel2": "FATTY ACID DESATURASE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "ECOSYSTEM FUNCTIONS",
    "variableLevel2": "FOOD-WEB DYNAMICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "ECOSYSTEM FUNCTIONS",
    "variableLevel2": "NUTRIENT CYCLING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "ECOSYSTEM FUNCTIONS",
    "variableLevel2": "NUTRITIONAL CONSTRAINTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "ECOSYSTEM FUNCTIONS",
    "variableLevel2": "OXYGEN DEMAND"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "ECOSYSTEM FUNCTIONS",
    "variableLevel2": "PHOTOSYNTHESIS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "ECOSYSTEM FUNCTIONS",
    "variableLevel2": "POLYUNSATURATED FATTY ACID"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "ECOSYSTEM FUNCTIONS",
    "variableLevel2": "PRIMARY PRODUCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "ECOSYSTEM FUNCTIONS",
    "variableLevel2": "RESPIRATION RATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "ECOSYSTEM FUNCTIONS",
    "variableLevel2": "SECONDARY PRODUCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "ECOSYSTEM FUNCTIONS",
    "variableLevel2": "TROPHIC DYNAMICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "ECOTOXICOLOGY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "ECOTOXICOLOGY",
    "variableLevel2": "BIOAVAILABILITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "ECOTOXICOLOGY",
    "variableLevel2": "SPECIES BIOACCUMULATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "ECOTOXICOLOGY",
    "variableLevel2": "TOXICITY LEVELS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "FIRE ECOLOGY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "FIRE ECOLOGY",
    "variableLevel2": "FIRE DISTURBANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "FIRE ECOLOGY",
    "variableLevel2": "FIRE DYNAMICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "FIRE ECOLOGY",
    "variableLevel2": "FIRE MODELS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "FIRE ECOLOGY",
    "variableLevel2": "FIRE OCCURRENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "SPECIES/POPULATION INTERACTIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "SPECIES/POPULATION INTERACTIONS",
    "variableLevel2": "BIOLUMINESCENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "SPECIES/POPULATION INTERACTIONS",
    "variableLevel2": "DIURNAL MOVEMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "SPECIES/POPULATION INTERACTIONS",
    "variableLevel2": "ENDANGERED SPECIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "SPECIES/POPULATION INTERACTIONS",
    "variableLevel2": "EVOLUTIONARY ADAPTATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "SPECIES/POPULATION INTERACTIONS",
    "variableLevel2": "EXOTIC SPECIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "SPECIES/POPULATION INTERACTIONS",
    "variableLevel2": "EXTINCTION RATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "SPECIES/POPULATION INTERACTIONS",
    "variableLevel2": "GRAZING DYNAMICS/PLANT HERBIVORY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "SPECIES/POPULATION INTERACTIONS",
    "variableLevel2": "HIBERNATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "SPECIES/POPULATION INTERACTIONS",
    "variableLevel2": "INDIGENOUS/NATIVE SPECIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "SPECIES/POPULATION INTERACTIONS",
    "variableLevel2": "MICROBIAL CHANGES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "SPECIES/POPULATION INTERACTIONS",
    "variableLevel2": "MIGRATORY RATES/ROUTES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "SPECIES/POPULATION INTERACTIONS",
    "variableLevel2": "MORPHOLOGICAL ADAPTATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "SPECIES/POPULATION INTERACTIONS",
    "variableLevel2": "MUTATION RATES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "SPECIES/POPULATION INTERACTIONS",
    "variableLevel2": "MUTUALISM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "SPECIES/POPULATION INTERACTIONS",
    "variableLevel2": "NATURAL SELECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "SPECIES/POPULATION INTERACTIONS",
    "variableLevel2": "PARASITISM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "SPECIES/POPULATION INTERACTIONS",
    "variableLevel2": "POLLINATOR SPECIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "SPECIES/POPULATION INTERACTIONS",
    "variableLevel2": "POPULATION DYNAMICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "SPECIES/POPULATION INTERACTIONS",
    "variableLevel2": "POST-BREEDING PERIODS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "SPECIES/POPULATION INTERACTIONS",
    "variableLevel2": "RANGE CHANGES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "SPECIES/POPULATION INTERACTIONS",
    "variableLevel2": "SCAVENGING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "SPECIES/POPULATION INTERACTIONS",
    "variableLevel2": "SPECIES COMPETITION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "SPECIES/POPULATION INTERACTIONS",
    "variableLevel2": "SPECIES LIFE HISTORY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "SPECIES/POPULATION INTERACTIONS",
    "variableLevel2": "SPECIES PREDATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "SPECIES/POPULATION INTERACTIONS",
    "variableLevel2": "SURVIVAL RATES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "SPECIES/POPULATION INTERACTIONS",
    "variableLevel2": "SYMBIOSIS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOLOGICAL DYNAMICS",
    "variableLevel1": "SPECIES/POPULATION INTERACTIONS",
    "variableLevel2": "USE/FEEDING HABITATS"
  },
  { "category": "EARTH SCIENCE", "topic": "BIOSPHERE", "term": "ECOSYSTEMS" },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "ANTHROPOGENIC/HUMAN INFLUENCED ECOSYSTEMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "ANTHROPOGENIC/HUMAN INFLUENCED ECOSYSTEMS",
    "variableLevel2": "AGRICULTURAL LANDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "ANTHROPOGENIC/HUMAN INFLUENCED ECOSYSTEMS",
    "variableLevel2": "AGRICULTURAL LANDS",
    "variableLevel3": "CROPLAND"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "ANTHROPOGENIC/HUMAN INFLUENCED ECOSYSTEMS",
    "variableLevel2": "AGRICULTURAL LANDS",
    "variableLevel3": "FOREST PLANTATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "ANTHROPOGENIC/HUMAN INFLUENCED ECOSYSTEMS",
    "variableLevel2": "AGRICULTURAL LANDS",
    "variableLevel3": "PASTURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "ANTHROPOGENIC/HUMAN INFLUENCED ECOSYSTEMS",
    "variableLevel2": "AGRICULTURAL LANDS",
    "variableLevel3": "RANGELAND"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "ANTHROPOGENIC/HUMAN INFLUENCED ECOSYSTEMS",
    "variableLevel2": "RESOURCE DEVELOPMENT SITE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "ANTHROPOGENIC/HUMAN INFLUENCED ECOSYSTEMS",
    "variableLevel2": "RESOURCE DEVELOPMENT SITE",
    "variableLevel3": "MINNING/DRILLING SITE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "ANTHROPOGENIC/HUMAN INFLUENCED ECOSYSTEMS",
    "variableLevel2": "RESOURCE DEVELOPMENT SITE",
    "variableLevel3": "SOLAR FARM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "ANTHROPOGENIC/HUMAN INFLUENCED ECOSYSTEMS",
    "variableLevel2": "RESOURCE DEVELOPMENT SITE",
    "variableLevel3": "WATER IMPOUNDMENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "ANTHROPOGENIC/HUMAN INFLUENCED ECOSYSTEMS",
    "variableLevel2": "RESOURCE DEVELOPMENT SITE",
    "variableLevel3": "WIND FARM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "ANTHROPOGENIC/HUMAN INFLUENCED ECOSYSTEMS",
    "variableLevel2": "URBAN LANDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "ANTHROPOGENIC/HUMAN INFLUENCED ECOSYSTEMS",
    "variableLevel2": "URBAN LANDS",
    "variableLevel3": "CANAL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "ANTHROPOGENIC/HUMAN INFLUENCED ECOSYSTEMS",
    "variableLevel2": "URBAN LANDS",
    "variableLevel3": "GARDEN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "ANTHROPOGENIC/HUMAN INFLUENCED ECOSYSTEMS",
    "variableLevel2": "URBAN LANDS",
    "variableLevel3": "PARK"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "ANTHROPOGENIC/HUMAN INFLUENCED ECOSYSTEMS",
    "variableLevel2": "URBAN LANDS",
    "variableLevel3": "ROADSIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "AQUATIC ECOSYSTEMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "AQUATIC ECOSYSTEMS",
    "variableLevel2": "PLANKTON"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "AQUATIC ECOSYSTEMS",
    "variableLevel2": "PLANKTON",
    "variableLevel3": "PHYTOPLANKTON"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "AQUATIC ECOSYSTEMS",
    "variableLevel2": "PLANKTON",
    "variableLevel3": "ZOOPLANKTON"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "AQUATIC ECOSYSTEMS",
    "variableLevel2": "WETLANDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "AQUATIC ECOSYSTEMS",
    "variableLevel2": "WETLANDS",
    "variableLevel3": "ESTUARINE WETLANDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "AQUATIC ECOSYSTEMS",
    "variableLevel2": "WETLANDS",
    "variableLevel3": "LACUSTRINE WETLANDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "AQUATIC ECOSYSTEMS",
    "variableLevel2": "WETLANDS",
    "variableLevel3": "MARINE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "AQUATIC ECOSYSTEMS",
    "variableLevel2": "WETLANDS",
    "variableLevel3": "MARSHES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "AQUATIC ECOSYSTEMS",
    "variableLevel2": "WETLANDS",
    "variableLevel3": "PALUSTRINE WETLANDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "AQUATIC ECOSYSTEMS",
    "variableLevel2": "WETLANDS",
    "variableLevel3": "PEATLANDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "AQUATIC ECOSYSTEMS",
    "variableLevel2": "WETLANDS",
    "variableLevel3": "RIPARIAN WETLANDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "AQUATIC ECOSYSTEMS",
    "variableLevel2": "WETLANDS",
    "variableLevel3": "SWAMPS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "AQUATIC ECOSYSTEMS",
    "variableLevel2": "WETLANDS",
    "variableLevel3": "VERNAL POOL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "FRESHWATER ECOSYSTEMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "FRESHWATER ECOSYSTEMS",
    "variableLevel2": "LAKE/POND"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "FRESHWATER ECOSYSTEMS",
    "variableLevel2": "LAKE/POND",
    "variableLevel3": "MONTANE LAKE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "FRESHWATER ECOSYSTEMS",
    "variableLevel2": "LAKE/POND",
    "variableLevel3": "SALINE LAKES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "FRESHWATER ECOSYSTEMS",
    "variableLevel2": "RIVERS/STREAM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "FRESHWATER ECOSYSTEMS",
    "variableLevel2": "RIVERS/STREAM",
    "variableLevel3": "EPHEMERAL STREAM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "FRESHWATER ECOSYSTEMS",
    "variableLevel2": "RIVERS/STREAM",
    "variableLevel3": "HEADWATER STREAM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "FRESHWATER ECOSYSTEMS",
    "variableLevel2": "RIVERS/STREAM",
    "variableLevel3": "INTERMITTENT STREAM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "FRESHWATER ECOSYSTEMS",
    "variableLevel2": "RIVERS/STREAM",
    "variableLevel3": "PERENNIAL STREAM/RIVER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "FRESHWATER ECOSYSTEMS",
    "variableLevel2": "RIVERS/STREAM",
    "variableLevel3": "RIVER DELTA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "MARINE ECOSYSTEMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "MARINE ECOSYSTEMS",
    "variableLevel2": "ABYSSAL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "MARINE ECOSYSTEMS",
    "variableLevel2": "ABYSSAL",
    "variableLevel3": "COLD SEEP"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "MARINE ECOSYSTEMS",
    "variableLevel2": "ABYSSAL",
    "variableLevel3": "HYDROTHERMAL VENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "MARINE ECOSYSTEMS",
    "variableLevel2": "BENTHIC"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "MARINE ECOSYSTEMS",
    "variableLevel2": "COASTAL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "MARINE ECOSYSTEMS",
    "variableLevel2": "COASTAL",
    "variableLevel3": "BEACHES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "MARINE ECOSYSTEMS",
    "variableLevel2": "COASTAL",
    "variableLevel3": "DUNES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "MARINE ECOSYSTEMS",
    "variableLevel2": "COASTAL",
    "variableLevel3": "KELP FOREST"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "MARINE ECOSYSTEMS",
    "variableLevel2": "COASTAL",
    "variableLevel3": "LAGOON"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "MARINE ECOSYSTEMS",
    "variableLevel2": "COASTAL",
    "variableLevel3": "MANGROVE SWAMP"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "MARINE ECOSYSTEMS",
    "variableLevel2": "COASTAL",
    "variableLevel3": "MUDFLAT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "MARINE ECOSYSTEMS",
    "variableLevel2": "COASTAL",
    "variableLevel3": "ROCKY INTERTIDAL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "MARINE ECOSYSTEMS",
    "variableLevel2": "COASTAL",
    "variableLevel3": "SALT MARSH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "MARINE ECOSYSTEMS",
    "variableLevel2": "COASTAL",
    "variableLevel3": "SAV/SEA GRASS BED"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "MARINE ECOSYSTEMS",
    "variableLevel2": "DEMERSAL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "MARINE ECOSYSTEMS",
    "variableLevel2": "ESTUARY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "MARINE ECOSYSTEMS",
    "variableLevel2": "ESTUARY",
    "variableLevel3": "BRACKISH MARSH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "MARINE ECOSYSTEMS",
    "variableLevel2": "ESTUARY",
    "variableLevel3": "MANGROVE SWAMP"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "MARINE ECOSYSTEMS",
    "variableLevel2": "ESTUARY",
    "variableLevel3": "MUDFLAT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "MARINE ECOSYSTEMS",
    "variableLevel2": "ESTUARY",
    "variableLevel3": "SAV/SEA GRASS BED"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "MARINE ECOSYSTEMS",
    "variableLevel2": "PELAGIC"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "MARINE ECOSYSTEMS",
    "variableLevel2": "PELAGIC",
    "variableLevel3": "NERITIC ZONE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "MARINE ECOSYSTEMS",
    "variableLevel2": "PELAGIC",
    "variableLevel3": "OCEANIC ZONE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "MARINE ECOSYSTEMS",
    "variableLevel2": "REEF"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "MARINE ECOSYSTEMS",
    "variableLevel2": "REEF",
    "variableLevel3": "CORAL REEF"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "MARINE ECOSYSTEMS",
    "variableLevel2": "REEF",
    "variableLevel3": "OYSTER REEF"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS",
    "variableLevel2": "ALPINE/TUNDRA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS",
    "variableLevel2": "ALPINE/TUNDRA",
    "variableLevel3": "ALPINE TUNDRA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS",
    "variableLevel2": "ALPINE/TUNDRA",
    "variableLevel3": "ARCTIC TUNDRA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS",
    "variableLevel2": "ALPINE/TUNDRA",
    "variableLevel3": "SUBALPINE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS",
    "variableLevel2": "CAVE/SUBTERRANEAN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS",
    "variableLevel2": "DESERTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS",
    "variableLevel2": "DESERTS",
    "variableLevel3": "DESERT SCRUB"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS",
    "variableLevel2": "FORESTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS",
    "variableLevel2": "FORESTS",
    "variableLevel3": "BOREAL FOREST/TIAGA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS",
    "variableLevel2": "FORESTS",
    "variableLevel3": "TEMPERATE CONIFEROUS FOREST"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS",
    "variableLevel2": "FORESTS",
    "variableLevel3": "TEMPERATE DECIDUOUS FOREST"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS",
    "variableLevel2": "FORESTS",
    "variableLevel3": "TEMPERATE MIXED FOREST"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS",
    "variableLevel2": "FORESTS",
    "variableLevel3": "TEMPERATE RAINFOREST"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS",
    "variableLevel2": "FORESTS",
    "variableLevel3": "TROPICAL RAINFOREST"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS",
    "variableLevel2": "GRASSLANDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS",
    "variableLevel2": "GRASSLANDS",
    "variableLevel3": "MONTANE GRASSLAND"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS",
    "variableLevel2": "GRASSLANDS",
    "variableLevel3": "SAVANNA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS",
    "variableLevel2": "ISLANDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS",
    "variableLevel2": "KARST LANDSCAPE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS",
    "variableLevel2": "MONTANE HABITATS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS",
    "variableLevel2": "SAVANNAS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS",
    "variableLevel2": "SHRUBLAND/SCRUB"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS",
    "variableLevel2": "SHRUBLAND/SCRUB",
    "variableLevel3": "CHAPARRAL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS",
    "variableLevel2": "SHRUBLAND/SCRUB",
    "variableLevel3": "MONTANE SHRUBLAND"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS",
    "variableLevel2": "WETLANDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS",
    "variableLevel2": "WETLANDS",
    "variableLevel3": "ESTUARINE WETLANDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS",
    "variableLevel2": "WETLANDS",
    "variableLevel3": "LACUSTRINE WETLANDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS",
    "variableLevel2": "WETLANDS",
    "variableLevel3": "MARINE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS",
    "variableLevel2": "WETLANDS",
    "variableLevel3": "MARSHES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS",
    "variableLevel2": "WETLANDS",
    "variableLevel3": "PALUSTRINE WETLANDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS",
    "variableLevel2": "WETLANDS",
    "variableLevel3": "PEATLANDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS",
    "variableLevel2": "WETLANDS",
    "variableLevel3": "RIPARIAN WETLANDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "ECOSYSTEMS",
    "variableLevel1": "TERRESTRIAL ECOSYSTEMS",
    "variableLevel2": "WETLANDS",
    "variableLevel3": "SWAMPS"
  },
  { "category": "EARTH SCIENCE", "topic": "BIOSPHERE", "term": "VEGETATION" },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "AFFORESTATION/REFORESTATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "BIOMASS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "CANOPY CHARACTERISTICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "CANOPY CHARACTERISTICS",
    "variableLevel2": "VEGETATION HEIGHT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "CANOPY TRANSMITTANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "CARBON"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "CHLOROPHYLL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "CROWN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "DECIDUOUS VEGETATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "DOMINANT SPECIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "EVERGREEN VEGETATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "EXOTIC VEGETATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "FOREST COMPOSITION/VEGETATION STRUCTURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "HERBIVORY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "IMPORTANCE VALUE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "INDIGENOUS VEGETATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "LEAF CHARACTERISTICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "LEAF CHARACTERISTICS",
    "variableLevel2": "LEAF AREA INDEX (LAI)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "LITTER CHARACTERISTICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "MACROPHYTES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "NITROGEN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "NUTRIENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "PHOSPHORUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "PHOTOSYNTHETICALLY ACTIVE RADIATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "PHOTOSYNTHETICALLY ACTIVE RADIATION",
    "variableLevel2": "FRACTION OF ABSORBED PHOTOSYNTHETICALLY ACTIVE RADIATION (FAPAR)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "PIGMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "PLANT CHARACTERISTICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "PLANT CHARACTERISTICS",
    "variableLevel2": "CROP DENSITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "PLANT CHARACTERISTICS",
    "variableLevel2": "CROP HEIGHT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "PLANT CHARACTERISTICS",
    "variableLevel2": "VEGETATION WATER CONTENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "PLANT CHARACTERISTICS",
    "variableLevel2": "XYLEM DIELECTRIC PERMITTIVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "PLANT PHENOLOGY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "PLANT PHENOLOGY",
    "variableLevel2": "GROWTH STAGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "POLLEN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "RECLAMATION/REVEGETATION/RESTORATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "REFORESTATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "SOLAR INDUCED FLUORESCENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "TREE RINGS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "VEGETATION COVER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "VEGETATION INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "VEGETATION INDEX",
    "variableLevel2": "LEAF AREA INDEX (LAI)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "VEGETATION INDEX",
    "variableLevel2": "NORMALIZED DIFFERENCE VEGETATION INDEX (NDVI)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "VEGETATION OPTICAL DEPTH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "VEGETATION SPECIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "BIOSPHERE",
    "term": "VEGETATION",
    "variableLevel1": "VEGETATION WATER POTENTIAL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "CLOUD INDICATORS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "CLOUD INDICATORS",
    "variableLevel2": "INCREASED/DECREASED CLOUD FRACTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "COMPOUND EXTREME EVENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "EXTREME WEATHER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "EXTREME WEATHER",
    "variableLevel2": "EXTREME DROUGHT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "EXTREME WEATHER",
    "variableLevel2": "EXTREME PRECIPITATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "EXTREME WEATHER",
    "variableLevel2": "HEAT/COLD WAVE FREQUENCY/INTENSITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "EXTREME WEATHER",
    "variableLevel2": "MONSOON ONSET/INTENSITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "EXTREME WEATHER",
    "variableLevel2": "TROPICAL OR EXTRATROPICAL CYCLONE FREQUENCY/INTENSITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "FRESH WATER RIVER DISCHARGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "HUMIDITY INDICES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "HUMIDITY INDICES",
    "variableLevel2": "HUMIDITY INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "HUMIDITY INDICES",
    "variableLevel2": "TEMPERATURE-HUMIDITY INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "HUMIDITY INDICES",
    "variableLevel2": "WATER VAPOR TRANSPORT INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "OCEAN ACIDIFICATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "OCEAN OVERTURNING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "OCEAN UPWELLING INDICES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "OCEAN UPWELLING INDICES",
    "variableLevel2": "OCEAN COASTAL UPWELLING INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "OCEAN UPWELLING/DOWNWELLING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "PRECIPITATION INDICATORS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "PRECIPITATION INDICATORS",
    "variableLevel2": "PRECIPITATION TRENDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "PRECIPITATION INDICATORS",
    "variableLevel2": "PRECIPITATION VARIABILITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "PRECIPITATION INDICATORS",
    "variableLevel2": "SAHEL STANDARDIZED RAINFALL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "PRECIPITATION INDICES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "PRECIPITATION INDICES",
    "variableLevel2": "ENSO PRECIPITATION INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "PRECIPITATION INDICES",
    "variableLevel2": "NORTHEAST BRAZIL RAINFALL ANOMALY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "PRECIPITATION INDICES",
    "variableLevel2": "STANDARDIZED PRECIPITATION INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "PRECIPITATION INDICES",
    "variableLevel2": "WEIGHTED ANOMALY STANDARDIZED PRECIPITATION INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "SEA LEVEL RISE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "SEA LEVEL RISE",
    "variableLevel2": "EROSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "SEA LEVEL RISE",
    "variableLevel2": "INUNDATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "SEA SURFACE TEMPERATURE INDICES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "SEA SURFACE TEMPERATURE INDICES",
    "variableLevel2": "ATLANTIC TRIPOLE SST"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "SEA SURFACE TEMPERATURE INDICES",
    "variableLevel2": "CARIBBEAN INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "SEA SURFACE TEMPERATURE INDICES",
    "variableLevel2": "CENTRAL TROPICAL PACIFIC SST"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "SEA SURFACE TEMPERATURE INDICES",
    "variableLevel2": "CENTRAL TROPICAL PACIFIC SST",
    "variableLevel3": "NINO 4 INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "SEA SURFACE TEMPERATURE INDICES",
    "variableLevel2": "EAST CENTRAL TROPICAL PACIFIC SST"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "SEA SURFACE TEMPERATURE INDICES",
    "variableLevel2": "EAST CENTRAL TROPICAL PACIFIC SST",
    "variableLevel3": "NINO 3.4 INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "SEA SURFACE TEMPERATURE INDICES",
    "variableLevel2": "EXTREME EASTERN TROPICAL PACIFIC SST"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "SEA SURFACE TEMPERATURE INDICES",
    "variableLevel2": "EXTREME EASTERN TROPICAL PACIFIC SST",
    "variableLevel3": "NINO 1+2 INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "SEA SURFACE TEMPERATURE INDICES",
    "variableLevel2": "KAPLAN SST INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "SEA SURFACE TEMPERATURE INDICES",
    "variableLevel2": "NINO 3 INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "SEA SURFACE TEMPERATURE INDICES",
    "variableLevel2": "NORTH TROPICAL ATLANTIC INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "SEA SURFACE TEMPERATURE INDICES",
    "variableLevel2": "OCEANIC NINO INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "SEA SURFACE TEMPERATURE INDICES",
    "variableLevel2": "PACIFIC WARM POOL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "SEA SURFACE TEMPERATURE INDICES",
    "variableLevel2": "TRANS-NINO INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "SEA SURFACE TEMPERATURE INDICES",
    "variableLevel2": "TROPICAL NORTH ATLANTIC INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "SEA SURFACE TEMPERATURE INDICES",
    "variableLevel2": "TROPICAL PACIFIC SST EOF"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "SEA SURFACE TEMPERATURE INDICES",
    "variableLevel2": "TROPICAL SOUTH ATLANTIC INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "SEA SURFACE TEMPERATURE INDICES",
    "variableLevel2": "WESTERN HEMISPHERE WARM POOL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "SURFACE SALINITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TELECONNECTIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TELECONNECTIONS",
    "variableLevel2": "ANTARCTIC OSCILLATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TELECONNECTIONS",
    "variableLevel2": "ARCTIC OSCILLATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TELECONNECTIONS",
    "variableLevel2": "ATLANTIC MERIDIONAL MODE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TELECONNECTIONS",
    "variableLevel2": "ATLANTIC MULTIDECADAL OSCILLATION LONG VERSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TELECONNECTIONS",
    "variableLevel2": "BIVARIATE ENSO TIMESERIES INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TELECONNECTIONS",
    "variableLevel2": "BLOCKING INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TELECONNECTIONS",
    "variableLevel2": "EAST ATLANTIC JET PATTERN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TELECONNECTIONS",
    "variableLevel2": "EAST ATLANTIC PATTERN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TELECONNECTIONS",
    "variableLevel2": "EASTERN ATLANTIC WESTERN RUSSIA PATTERN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TELECONNECTIONS",
    "variableLevel2": "EASTERN PACIFIC OSCILLATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TELECONNECTIONS",
    "variableLevel2": "EL NINO SOUTHERN OSCILLATION (ENSO)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TELECONNECTIONS",
    "variableLevel2": "EQUATORIAL PACIFIC MERIDIONAL WIND ANOMALY INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TELECONNECTIONS",
    "variableLevel2": "EQUATORIAL PACIFIC ZONAL WIND ANOMALY INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TELECONNECTIONS",
    "variableLevel2": "GLOBALLY INTEGRATED ANGULAR MOMENTUM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TELECONNECTIONS",
    "variableLevel2": "MADDEN-JULIAN OSCILLATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TELECONNECTIONS",
    "variableLevel2": "MULTIVARIATE ENSO INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TELECONNECTIONS",
    "variableLevel2": "NORTH ATLANTIC OSCILLATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TELECONNECTIONS",
    "variableLevel2": "NORTH PACIFIC OSCILLATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TELECONNECTIONS",
    "variableLevel2": "NORTH PACIFIC PATTERN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TELECONNECTIONS",
    "variableLevel2": "NORTHERN OSCILLATION INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TELECONNECTIONS",
    "variableLevel2": "PACIFIC DECADAL OSCILLATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TELECONNECTIONS",
    "variableLevel2": "PACIFIC/NORTH AMERICAN (PNA) PATTERN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TELECONNECTIONS",
    "variableLevel2": "Pacific Transition Index"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TELECONNECTIONS",
    "variableLevel2": "QUASI-BIENNIAL OSCILLATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TELECONNECTIONS",
    "variableLevel2": "SOUTHERN OSCILLATION INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TELECONNECTIONS",
    "variableLevel2": "TROPICAL/NORTHERN HEMISPHERE PATTERN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TELECONNECTIONS",
    "variableLevel2": "WEST PACIFIC INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TEMPERATURE INDICATORS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TEMPERATURE INDICATORS",
    "variableLevel2": "HIGHER MAXIMUM DAYTIME TEMPERATURES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TEMPERATURE INDICATORS",
    "variableLevel2": "HIGHER MINIMUM NIGHTIME TEMPERATURES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TEMPERATURE INDICATORS",
    "variableLevel2": "STRATOSPHERIC TEMPERATURE ANOMALIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TEMPERATURE INDICATORS",
    "variableLevel2": "TEMPERATURE TRENDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TEMPERATURE INDICATORS",
    "variableLevel2": "TEMPERATURE VARIABILITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TEMPERATURE INDICATORS",
    "variableLevel2": "TROPOSPHERIC TEMPERATURE ANOMALIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TEMPERATURE INDICES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TEMPERATURE INDICES",
    "variableLevel2": "COMMON SENSE CLIMATE INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TEMPERATURE INDICES",
    "variableLevel2": "COOLING DEGREE DAYS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TEMPERATURE INDICES",
    "variableLevel2": "FREEZING INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TEMPERATURE INDICES",
    "variableLevel2": "GROWING DEGREE DAYS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TEMPERATURE INDICES",
    "variableLevel2": "HEATING DEGREE DAYS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TEMPERATURE INDICES",
    "variableLevel2": "RESIDENTIAL ENERGY DEMAND TEMPERATURE INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TEMPERATURE INDICES",
    "variableLevel2": "TEMPERATURE CONCENTRATION INDEX (TCI)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ATMOSPHERIC/OCEAN INDICATORS",
    "variableLevel1": "TEMPERATURE INDICES",
    "variableLevel2": "THAWING INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "BIOSPHERIC INDICATORS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "BIOSPHERIC INDICATORS",
    "variableLevel1": "BIRTH RATE DECLINE/INCREASE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "BIOSPHERIC INDICATORS",
    "variableLevel1": "BREEDING PRODUCTIVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "BIOSPHERIC INDICATORS",
    "variableLevel1": "CANOPY TEMPERATURE VARIABILITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "BIOSPHERIC INDICATORS",
    "variableLevel1": "HYPOXIC CONDITIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "BIOSPHERIC INDICATORS",
    "variableLevel1": "INDICATOR SPECIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "BIOSPHERIC INDICATORS",
    "variableLevel1": "INVASIVE SPECIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "BIOSPHERIC INDICATORS",
    "variableLevel1": "PHENOLOGICAL CHANGES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "BIOSPHERIC INDICATORS",
    "variableLevel1": "PHENOLOGICAL CHANGES",
    "variableLevel2": "ANIMAL PHENOLOGICAL CHANGES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "BIOSPHERIC INDICATORS",
    "variableLevel1": "PHENOLOGICAL CHANGES",
    "variableLevel2": "PLANT PHENOLOGICAL CHANGES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "BIOSPHERIC INDICATORS",
    "variableLevel1": "RANGE CHANGES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "BIOSPHERIC INDICATORS",
    "variableLevel1": "SPECIES MIGRATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "BIOSPHERIC INDICATORS",
    "variableLevel1": "VECTOR SPECIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "CARBON FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "CLIMATE FEEDBACKS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "CLIMATE FEEDBACKS",
    "variableLevel1": "ATMOSPHERIC FEEDBACKS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "CLIMATE FEEDBACKS",
    "variableLevel1": "COUPLED SYSTEM FEEDBACKS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "CLIMATE FEEDBACKS",
    "variableLevel1": "CRYOSPHERIC FEEDBACKS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "CLIMATE FEEDBACKS",
    "variableLevel1": "LAND SURFACE FEEDBACKS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "CLIMATE FEEDBACKS",
    "variableLevel1": "OCEANIC FEEDBACKS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "CRYOSPHERIC INDICATORS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "CRYOSPHERIC INDICATORS",
    "variableLevel1": "AVALANCHE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "CRYOSPHERIC INDICATORS",
    "variableLevel1": "CLIMATE WARMING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "CRYOSPHERIC INDICATORS",
    "variableLevel1": "DEPTH HOAR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "CRYOSPHERIC INDICATORS",
    "variableLevel1": "FIRN LIMIT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "CRYOSPHERIC INDICATORS",
    "variableLevel1": "GLACIAL MEASUREMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "CRYOSPHERIC INDICATORS",
    "variableLevel1": "GLACIAL MEASUREMENTS",
    "variableLevel2": "GLACIER ELEVATION/ICE SHEET ELEVATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "CRYOSPHERIC INDICATORS",
    "variableLevel1": "GLACIAL MEASUREMENTS",
    "variableLevel2": "GLACIER ELEVATION/ICE SHEET ELEVATION",
    "variableLevel3": "Hypsometry"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "CRYOSPHERIC INDICATORS",
    "variableLevel1": "GLACIAL MEASUREMENTS",
    "variableLevel2": "GLACIER FACIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "CRYOSPHERIC INDICATORS",
    "variableLevel1": "GLACIAL MEASUREMENTS",
    "variableLevel2": "GLACIER MASS BALANCE/ICE SHEET MASS BALANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "CRYOSPHERIC INDICATORS",
    "variableLevel1": "GLACIAL MEASUREMENTS",
    "variableLevel2": "GLACIER MOTION/ICE SHEET MOTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "CRYOSPHERIC INDICATORS",
    "variableLevel1": "GLACIAL MEASUREMENTS",
    "variableLevel2": "GLACIER/ICE SHEET THICKNESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "CRYOSPHERIC INDICATORS",
    "variableLevel1": "GLACIAL MEASUREMENTS",
    "variableLevel2": "GLACIER/ICE SHEET TOPOGRAPHY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "CRYOSPHERIC INDICATORS",
    "variableLevel1": "ICE DEPTH/THICKNESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "CRYOSPHERIC INDICATORS",
    "variableLevel1": "ICE EDGES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "CRYOSPHERIC INDICATORS",
    "variableLevel1": "ICE EXTENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "CRYOSPHERIC INDICATORS",
    "variableLevel1": "ICE FLOES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "CRYOSPHERIC INDICATORS",
    "variableLevel1": "ICE GROWTH/MELT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "CRYOSPHERIC INDICATORS",
    "variableLevel1": "RIVER ICE DEPTH/EXTENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "CRYOSPHERIC INDICATORS",
    "variableLevel1": "SALINITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "CRYOSPHERIC INDICATORS",
    "variableLevel1": "SEA ICE CONCENTRATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "CRYOSPHERIC INDICATORS",
    "variableLevel1": "SEA ICE ELEVATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "CRYOSPHERIC INDICATORS",
    "variableLevel1": "SNOW COVER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "CRYOSPHERIC INDICATORS",
    "variableLevel1": "SNOW DEPTH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "CRYOSPHERIC INDICATORS",
    "variableLevel1": "SNOW ENERGY BALANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "CRYOSPHERIC INDICATORS",
    "variableLevel1": "SNOW MELT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ENVIRONMENTAL VULNERABILITY INDEX (EVI)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ENVIRONMENTAL VULNERABILITY INDEX (EVI)",
    "variableLevel1": "FIJI INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ENVIRONMENTAL VULNERABILITY INDEX (EVI)",
    "variableLevel1": "SAMOA INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "ENVIRONMENTAL VULNERABILITY INDEX (EVI)",
    "variableLevel1": "TUVALU INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "LAND SURFACE/AGRICULTURE INDICATORS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "LAND SURFACE/AGRICULTURE INDICATORS",
    "variableLevel1": "DROUGHT DURATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "LAND SURFACE/AGRICULTURE INDICATORS",
    "variableLevel1": "DROUGHT FREQUENCY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "LAND SURFACE/AGRICULTURE INDICATORS",
    "variableLevel1": "DROUGHT INDICES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "LAND SURFACE/AGRICULTURE INDICATORS",
    "variableLevel1": "DROUGHT INDICES",
    "variableLevel2": "PALMER DROUGHT CROP MOISTURE INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "LAND SURFACE/AGRICULTURE INDICATORS",
    "variableLevel1": "DROUGHT INDICES",
    "variableLevel2": "PALMER DROUGHT SEVERITY INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "LAND SURFACE/AGRICULTURE INDICATORS",
    "variableLevel1": "DROUGHT INDICES",
    "variableLevel2": "PALMER HYDROLOGICAL DROUGHT INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "LAND SURFACE/AGRICULTURE INDICATORS",
    "variableLevel1": "DROUGHT INDICES",
    "variableLevel2": "PALMER Z INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "LAND SURFACE/AGRICULTURE INDICATORS",
    "variableLevel1": "DROUGHT SEVERITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "LAND SURFACE/AGRICULTURE INDICATORS",
    "variableLevel1": "EROSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "LAND SURFACE/AGRICULTURE INDICATORS",
    "variableLevel1": "FIRE WEATHER INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "LAND SURFACE/AGRICULTURE INDICATORS",
    "variableLevel1": "FOREST FIRE DANGER INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "LAND SURFACE/AGRICULTURE INDICATORS",
    "variableLevel1": "LANDSLIDES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "LAND SURFACE/AGRICULTURE INDICATORS",
    "variableLevel1": "LENGTH OF GROWING SEASON"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "LAND SURFACE/AGRICULTURE INDICATORS",
    "variableLevel1": "LENGTH OF GROWING SEASON",
    "variableLevel2": "CROP HARVEST DATES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "LAND SURFACE/AGRICULTURE INDICATORS",
    "variableLevel1": "LENGTH OF GROWING SEASON",
    "variableLevel2": "FIRST BLOOM DATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "LAND SURFACE/AGRICULTURE INDICATORS",
    "variableLevel1": "LENGTH OF GROWING SEASON",
    "variableLevel2": "FIRST LEAF DATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "LAND SURFACE/AGRICULTURE INDICATORS",
    "variableLevel1": "LENGTH OF GROWING SEASON",
    "variableLevel2": "FREEZE/FROST DATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "LAND SURFACE/AGRICULTURE INDICATORS",
    "variableLevel1": "LENGTH OF GROWING SEASON",
    "variableLevel2": "FREEZE/FROST PROBABILITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "LAND SURFACE/AGRICULTURE INDICATORS",
    "variableLevel1": "LENGTH OF GROWING SEASON",
    "variableLevel2": "LENGTH OF FREEZE FREE PERIOD"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "LAND SURFACE/AGRICULTURE INDICATORS",
    "variableLevel1": "SATELLITE SOIL MOISTURE INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "LAND SURFACE/AGRICULTURE INDICATORS",
    "variableLevel1": "SOIL EROSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "LAND SURFACE/AGRICULTURE INDICATORS",
    "variableLevel1": "SOIL MOISTURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "LAND SURFACE/AGRICULTURE INDICATORS",
    "variableLevel1": "SOIL TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "LAND SURFACE/AGRICULTURE INDICATORS",
    "variableLevel1": "SURFACE MOISTURE INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "LAND SURFACE/AGRICULTURE INDICATORS",
    "variableLevel1": "TREE LINE SHIFT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "LAND SURFACE/AGRICULTURE INDICATORS",
    "variableLevel1": "VEGETATION COVER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "ALUMINUM-26 ANALYSIS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "BERYLLIUM-10 ANALYSIS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "BIOLOGICAL RECORDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "BIOLOGICAL RECORDS",
    "variableLevel2": "BIOMARKER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "BIOLOGICAL RECORDS",
    "variableLevel2": "CORAL DEPOSITS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "BIOLOGICAL RECORDS",
    "variableLevel2": "FAUNA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "BIOLOGICAL RECORDS",
    "variableLevel2": "MACROFOSSILS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "BIOLOGICAL RECORDS",
    "variableLevel2": "MICROFOSSILS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "BIOLOGICAL RECORDS",
    "variableLevel2": "PALEOVEGETATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "BIOLOGICAL RECORDS",
    "variableLevel2": "POLLEN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "BIOLOGICAL RECORDS",
    "variableLevel2": "POPULATION ABUNDANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "BIOLOGICAL RECORDS",
    "variableLevel2": "TREE RINGS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "BIOLOGICAL RECORDS",
    "variableLevel2": "TREE RINGS",
    "variableLevel3": "ISOTOPIC ANALYSIS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "BIOLOGICAL RECORDS",
    "variableLevel2": "TREE RINGS",
    "variableLevel3": "ISOTOPIC ANALYSIS",
    "detailedVariable": "CARBON ISOTOPE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "BIOLOGICAL RECORDS",
    "variableLevel2": "TREE RINGS",
    "variableLevel3": "ISOTOPIC ANALYSIS",
    "detailedVariable": "HYDROGEN ISOTOPE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "BIOLOGICAL RECORDS",
    "variableLevel2": "TREE RINGS",
    "variableLevel3": "ISOTOPIC ANALYSIS",
    "detailedVariable": "OXYGEN ISOTOPE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "ICE CORE RECORDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "ICE CORE RECORDS",
    "variableLevel2": "CARBON DIOXIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "ICE CORE RECORDS",
    "variableLevel2": "ELECTRICAL PROPERTIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "ICE CORE RECORDS",
    "variableLevel2": "ICE CORE AIR BUBBLES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "ICE CORE RECORDS",
    "variableLevel2": "IONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "ICE CORE RECORDS",
    "variableLevel2": "ISOTOPES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "ICE CORE RECORDS",
    "variableLevel2": "ISOTOPES",
    "variableLevel3": "ARGON ISOTOPES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "ICE CORE RECORDS",
    "variableLevel2": "ISOTOPES",
    "variableLevel3": "NITROGEN ISOTOPES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "ICE CORE RECORDS",
    "variableLevel2": "ISOTOPES",
    "variableLevel3": "OXYGEN ISOTOPES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "ICE CORE RECORDS",
    "variableLevel2": "METHANE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "ICE CORE RECORDS",
    "variableLevel2": "NITROUS OXIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "ICE CORE RECORDS",
    "variableLevel2": "PARTICULATE MATTER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "ICE CORE RECORDS",
    "variableLevel2": "PARTICULATE MATTER",
    "variableLevel3": "MICROPARTICLE CONCENTRATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "ICE CORE RECORDS",
    "variableLevel2": "VOLCANIC DEPOSITS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "LAND RECORDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "LAND RECORDS",
    "variableLevel2": "BOREHOLES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "LAND RECORDS",
    "variableLevel2": "CAVE DEPOSITS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "LAND RECORDS",
    "variableLevel2": "FIRE HISTORY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "LAND RECORDS",
    "variableLevel2": "FIRE HISTORY",
    "variableLevel3": "CHARCOAL SEDIMENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "LAND RECORDS",
    "variableLevel2": "FIRE HISTORY",
    "variableLevel3": "FIRE SCAR DATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "LAND RECORDS",
    "variableLevel2": "GLACIAL RETREAT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "LAND RECORDS",
    "variableLevel2": "GLACIATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "LAND RECORDS",
    "variableLevel2": "ISOTOPES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "LAND RECORDS",
    "variableLevel2": "LOESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "LAND RECORDS",
    "variableLevel2": "PALEOMAGNETIC DATA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "LAND RECORDS",
    "variableLevel2": "PALEOSOLS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "LAND RECORDS",
    "variableLevel2": "RADIOCARBON"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "LAND RECORDS",
    "variableLevel2": "SEDIMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "LAND RECORDS",
    "variableLevel2": "SEDIMENTS",
    "variableLevel3": "SEDIMENT THICKNESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "LAND RECORDS",
    "variableLevel2": "STRATIGRAPHIC SEQUENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "LAND RECORDS",
    "variableLevel2": "VOLCANIC DEPOSITS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "MASS EXTINCTIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "OCEAN/LAKE RECORDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "OCEAN/LAKE RECORDS",
    "variableLevel2": "BOREHOLES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "OCEAN/LAKE RECORDS",
    "variableLevel2": "CORAL DEPOSITS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "OCEAN/LAKE RECORDS",
    "variableLevel2": "ISOTOPES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "OCEAN/LAKE RECORDS",
    "variableLevel2": "LAKE LEVELS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "OCEAN/LAKE RECORDS",
    "variableLevel2": "MACROFOSSILS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "OCEAN/LAKE RECORDS",
    "variableLevel2": "MICROFOSSILS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "OCEAN/LAKE RECORDS",
    "variableLevel2": "OXYGEN ISOTOPES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "OCEAN/LAKE RECORDS",
    "variableLevel2": "PALEOMAGNETIC DATA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "OCEAN/LAKE RECORDS",
    "variableLevel2": "POLLEN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "OCEAN/LAKE RECORDS",
    "variableLevel2": "RADIOCARBON"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "OCEAN/LAKE RECORDS",
    "variableLevel2": "SEDIMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "OCEAN/LAKE RECORDS",
    "variableLevel2": "SEDIMENTS",
    "variableLevel3": "SEDIMENT THICKNESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "OCEAN/LAKE RECORDS",
    "variableLevel2": "STRATIGRAPHIC SEQUENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "OCEAN/LAKE RECORDS",
    "variableLevel2": "VARVE DEPOSITS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "OXYGEN ISOTOPE ANALYSIS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "PALEOCLIMATE FORCING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "PALEOCLIMATE FORCING",
    "variableLevel2": "CARBON DIOXIDE FORCING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "PALEOCLIMATE FORCING",
    "variableLevel2": "ORBITAL CHANGE FORCING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "PALEOCLIMATE FORCING",
    "variableLevel2": "SOLAR FORCING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "PALEOCLIMATE FORCING",
    "variableLevel2": "VOLCANIC FORCING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "PALEOCLIMATE RECONSTRUCTIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "PALEOCLIMATE RECONSTRUCTIONS",
    "variableLevel2": "AIR TEMPERATURE RECONSTRUCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "PALEOCLIMATE RECONSTRUCTIONS",
    "variableLevel2": "ATMOSPHERIC CIRCULATION RECONSTRUCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "PALEOCLIMATE RECONSTRUCTIONS",
    "variableLevel2": "DROUGHT/PRECIPITATION RECONSTRUCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "PALEOCLIMATE RECONSTRUCTIONS",
    "variableLevel2": "GROUND WATER RECONSTRUCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "PALEOCLIMATE RECONSTRUCTIONS",
    "variableLevel2": "LAKE LEVEL RECONSTRUCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "PALEOCLIMATE RECONSTRUCTIONS",
    "variableLevel2": "OCEAN SALINITY RECONSTRUCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "PALEOCLIMATE RECONSTRUCTIONS",
    "variableLevel2": "SEA LEVEL RECONSTRUCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "PALEOCLIMATE RECONSTRUCTIONS",
    "variableLevel2": "SEA SURFACE TEMPERATURE RECONSTRUCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "PALEOCLIMATE RECONSTRUCTIONS",
    "variableLevel2": "SEDIMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "PALEOCLIMATE RECONSTRUCTIONS",
    "variableLevel2": "SEDIMENTS",
    "variableLevel3": "SEDIMENT THICKNESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "PALEOCLIMATE RECONSTRUCTIONS",
    "variableLevel2": "SOLAR FORCING/INSOLATION RECONSTRUCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "PALEOCLIMATE RECONSTRUCTIONS",
    "variableLevel2": "STREAMFLOW RECONSTRUCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "PALEOCLIMATE RECONSTRUCTIONS",
    "variableLevel2": "VEGETATION RECONSTRUCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "PERMAFROST/METHANE RELEASE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "PLATE TECTONICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "SPELEOTHEMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "PALEOCLIMATE INDICATORS",
    "variableLevel1": "VOLCANIC ACTIVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "REGIONAL CLIMATE LEVELS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "REGIONAL CLIMATE LEVELS",
    "variableLevel1": "MACROCLIMATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "REGIONAL CLIMATE LEVELS",
    "variableLevel1": "MESOCLIMATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "REGIONAL CLIMATE LEVELS",
    "variableLevel1": "MICROCLIMATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "SUN-EARTH INTERACTIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "SUN-EARTH INTERACTIONS",
    "variableLevel1": "SUNSPOT ACTIVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "SUN-EARTH INTERACTIONS",
    "variableLevel1": "SUNSPOT ACTIVITY",
    "variableLevel2": "LENGTH OF THE SOLAR CYCLE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "SUN-EARTH INTERACTIONS",
    "variableLevel1": "SUNSPOT ACTIVITY",
    "variableLevel2": "SOLAR FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "TERRESTRIAL HYDROSPHERE INDICATORS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "TERRESTRIAL HYDROSPHERE INDICATORS",
    "variableLevel1": "FRESHWATER RUNOFF"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "TERRESTRIAL HYDROSPHERE INDICATORS",
    "variableLevel1": "MOUNTAIN SNOW LINE SHIFT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "TERRESTRIAL HYDROSPHERE INDICATORS",
    "variableLevel1": "PERMAFROST MELT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "TERRESTRIAL HYDROSPHERE INDICATORS",
    "variableLevel1": "RIVER/LAKE ICE BREAKUP"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "TERRESTRIAL HYDROSPHERE INDICATORS",
    "variableLevel1": "RIVER/LAKE ICE FREEZE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CLIMATE INDICATORS",
    "term": "TERRESTRIAL HYDROSPHERE INDICATORS",
    "variableLevel1": "SNOW COVER DEGRADATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "FROZEN GROUND"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "FROZEN GROUND",
    "variableLevel1": "ACTIVE LAYER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "FROZEN GROUND",
    "variableLevel1": "CRYOSOLS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "FROZEN GROUND",
    "variableLevel1": "GROUND ICE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "FROZEN GROUND",
    "variableLevel1": "PERIGLACIAL PROCESSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "FROZEN GROUND",
    "variableLevel1": "PERMAFROST"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "FROZEN GROUND",
    "variableLevel1": "PERMAFROST",
    "variableLevel2": "PERMAFROST TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "FROZEN GROUND",
    "variableLevel1": "ROCK GLACIERS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "FROZEN GROUND",
    "variableLevel1": "SEASONALLY FROZEN GROUND"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "FROZEN GROUND",
    "variableLevel1": "SOIL TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "FROZEN GROUND",
    "variableLevel1": "TALIK"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "GLACIERS/ICE SHEETS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "ABLATION ZONES/ACCUMULATION ZONES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "AGE AT ICE-THICKNESS-NORMALIZED DEPTHS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "AGE OF INTERNAL REFLECTIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "BASAL SHEAR STRESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "BASINS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "COASTLINE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "DEPTHS AT SPECIFIC AGES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "FIRN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "FIRN",
    "variableLevel2": "FIRN AIR CONTENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "FIRN",
    "variableLevel2": "SNOW GRAIN SIZE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "GEOMETRY OF INTERNAL REFLECTIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "GLACIER ELEVATION/ICE SHEET ELEVATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "GLACIER FACIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "GLACIER MASS BALANCE/ICE SHEET MASS BALANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "GLACIER MOTION/ICE SHEET MOTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "GLACIER THICKNESS/ICE SHEET THICKNESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "GLACIER TOPOGRAPHY/ICE SHEET TOPOGRAPHY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "GLACIERS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "GLACIERS",
    "variableLevel2": "DEBRIS THICKNESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "GLACIERS",
    "variableLevel2": "GLACIAL LAKE EXTENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "GLACIERS",
    "variableLevel2": "GLACIER EXTENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "GLACIERS",
    "variableLevel2": "GLACIER RUNOFF"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "GLACIERS",
    "variableLevel2": "GLACIER TERMINUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "GLACIERS",
    "variableLevel2": "GROUNDING LINE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "GLACIERS",
    "variableLevel2": "ICE STUPA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "GLACIERS",
    "variableLevel2": "SUB-DEBRIS MELT ENHANCEMENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "ICE SHEETS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "ICE SHEETS",
    "variableLevel2": "ICE SHEET MEASUREMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "ICE SHEETS",
    "variableLevel2": "ICE SHEET MEASUREMENTS",
    "variableLevel3": "RIFTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "ICE SHEETS",
    "variableLevel2": "SURFACE MORPHOLOGY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "ICE SHELVES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "ICEBERGS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "LAND ICE/OCEAN CLASSIFICATION"
  },
  { "category": "EARTH SCIENCE", "topic": "CRYOSPHERE", "term": "SEA ICE" },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "FREEBOARD"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "HEAT FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "HEAT FLUX",
    "variableLevel2": "LATENT HEAT FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "HEAT FLUX",
    "variableLevel2": "LONGWAVE HEAT FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "HEAT FLUX",
    "variableLevel2": "SENSIBLE HEAT FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "HEAT FLUX",
    "variableLevel2": "SHORTWAVE HEAT FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "ICE DEFORMATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "ICE DEPTH/THICKNESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "ICE DRAFT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "ICE EDGES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "ICE EXTENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "ICE FLOES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "ICE GROWTH/MELT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "ICE ROUGHNESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "ICE TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "ICE TYPES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "ICEBERGS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "ISOTOPES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "LEADS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "PACK ICE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "POLYNYAS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "REFLECTANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "SALINITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "SEA ICE AGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "SEA ICE CONCENTRATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "SEA ICE CONCENTRATION",
    "variableLevel2": "ICE FRACTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "SEA ICE ELEVATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "SEA ICE MOTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "SEA ICE STRAIN RATES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "SEA ICE STRENGTH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "SEA ICE STRESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "SEA ICE VOLUME BUDGET"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "SEA ICE VOLUME BUDGET",
    "variableLevel2": "ADVECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "SEA ICE VOLUME BUDGET",
    "variableLevel2": "DIFFUSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "SEA ICE VOLUME BUDGET",
    "variableLevel2": "FLOODING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "SEA ICE VOLUME BUDGET",
    "variableLevel2": "ICE GROWTH/MELT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "SEA ICE VOLUME BUDGET",
    "variableLevel2": "SUBLIMATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "SEA ICE/OCEAN CLASSIFICATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "SNOW DEPTH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SEA ICE",
    "variableLevel1": "SNOW MELT"
  },
  { "category": "EARTH SCIENCE", "topic": "CRYOSPHERE", "term": "SNOW/ICE" },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "ALBEDO"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "AVALANCHE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "BLOWING SNOW"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "DEPTH HOAR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "FREEZE/THAW"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "FREEZE/THAW",
    "variableLevel2": "TRANSITION DIRECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "FROST"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "ICE DEPTH/THICKNESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "ICE EXTENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "ICE GROWTH/MELT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "ICE MELANGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "ICE MELANGE",
    "variableLevel2": "ICE MELANGE VELOCITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "ICE MOTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "ICE SURFACE TEMPERAT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "ICE VELOCITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "LAKE ICE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "PERMAFROST"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "REFLECTANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "REFLECTANCE",
    "variableLevel2": "BIDIRECTIONAL REFLECTANCE DISTRIBUTION FUNCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "REFLECTANCE",
    "variableLevel2": "BIDIRECTIONAL REFLECTANCE FACTOR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "RIVER ICE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "SNOW CLASSIFICATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "SNOW COVER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "SNOW DENSITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "SNOW DEPTH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "SNOW ENERGY BALANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "SNOW FACIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "SNOW MELT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "SNOW MICROSTRUCTURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "SNOW MICROSTRUCTURE",
    "variableLevel2": "SPECIFIC SURFACE AREA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "SNOW STRATIGRAPHY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "SNOW VOLUME BUDGET"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "SNOW VOLUME BUDGET",
    "variableLevel2": "ADVECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "SNOW VOLUME BUDGET",
    "variableLevel2": "DIFFUSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "SNOW VOLUME BUDGET",
    "variableLevel2": "PRECIPITATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "SNOW VOLUME BUDGET",
    "variableLevel2": "SNOW MELT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "SNOW VOLUME BUDGET",
    "variableLevel2": "SUBLIMATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "SNOW WATER EQUIVALENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "SNOW/ICE CHEMISTRY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "SNOW/ICE TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "SURFACE MELT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "CRYOSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "WHITEOUT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "BOUNDARIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "BOUNDARIES",
    "variableLevel1": "ADMINISTRATIVE DIVISIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "BOUNDARIES",
    "variableLevel1": "BOUNDARY SURVEYS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "BOUNDARIES",
    "variableLevel1": "POLITICAL DIVISIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "BOUNDARIES",
    "variableLevel1": "POLITICAL DIVISIONS",
    "variableLevel2": "COUNTRY BOUNDARIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "BOUNDARIES",
    "variableLevel1": "POLITICAL DIVISIONS",
    "variableLevel2": "STATE BOUNDARIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ECONOMIC RESOURCES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ECONOMIC RESOURCES",
    "variableLevel1": "AGRICULTURE PRODUCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ECONOMIC RESOURCES",
    "variableLevel1": "AGRICULTURE PRODUCTION",
    "variableLevel2": "MONOCULTURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ECONOMIC RESOURCES",
    "variableLevel1": "AQUACULTURE PRODUCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ECONOMIC RESOURCES",
    "variableLevel1": "ENERGY PRODUCTION/USE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ECONOMIC RESOURCES",
    "variableLevel1": "ENERGY PRODUCTION/USE",
    "variableLevel2": "BIOMASS ENERGY PRODUCTION/USE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ECONOMIC RESOURCES",
    "variableLevel1": "ENERGY PRODUCTION/USE",
    "variableLevel2": "COAL PRODUCTION/USE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ECONOMIC RESOURCES",
    "variableLevel1": "ENERGY PRODUCTION/USE",
    "variableLevel2": "GEOTHERMAL ENERGY PRODUCTION/USE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ECONOMIC RESOURCES",
    "variableLevel1": "ENERGY PRODUCTION/USE",
    "variableLevel2": "HYDROELECTRIC ENERGY PRODUCTION/USE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ECONOMIC RESOURCES",
    "variableLevel1": "ENERGY PRODUCTION/USE",
    "variableLevel2": "HYDROGEN PRODUCTION/USE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ECONOMIC RESOURCES",
    "variableLevel1": "ENERGY PRODUCTION/USE",
    "variableLevel2": "METHANE PRODUCTION/USE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ECONOMIC RESOURCES",
    "variableLevel1": "ENERGY PRODUCTION/USE",
    "variableLevel2": "NATURAL GAS PRODUCTION/USE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ECONOMIC RESOURCES",
    "variableLevel1": "ENERGY PRODUCTION/USE",
    "variableLevel2": "NUCLEAR ENERGY PRODUCTION/USE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ECONOMIC RESOURCES",
    "variableLevel1": "ENERGY PRODUCTION/USE",
    "variableLevel2": "OIL PRODUCTION/USE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ECONOMIC RESOURCES",
    "variableLevel1": "ENERGY PRODUCTION/USE",
    "variableLevel2": "PETROLEUM PRODUCTION/USE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ECONOMIC RESOURCES",
    "variableLevel1": "ENERGY PRODUCTION/USE",
    "variableLevel2": "SOLAR ENERGY PRODUCTION/USE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ECONOMIC RESOURCES",
    "variableLevel1": "ENERGY PRODUCTION/USE",
    "variableLevel2": "TIDAL ENERGY PRODUCTION/USE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ECONOMIC RESOURCES",
    "variableLevel1": "ENERGY PRODUCTION/USE",
    "variableLevel2": "WAVE ENERGY PRODUCTION/USE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ECONOMIC RESOURCES",
    "variableLevel1": "ENERGY PRODUCTION/USE",
    "variableLevel2": "WIND ENERGY PRODUCTION/USE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ECONOMIC RESOURCES",
    "variableLevel1": "MARICULTURE PRODUCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ECONOMIC RESOURCES",
    "variableLevel1": "TOURISM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ECONOMIC RESOURCES",
    "variableLevel1": "TOURISM",
    "variableLevel2": "ECOTOURISM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL GOVERNANCE/MANAGEMENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL GOVERNANCE/MANAGEMENT",
    "variableLevel1": "CARBON CAPTURE AND STORAGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL GOVERNANCE/MANAGEMENT",
    "variableLevel1": "CARBON FOOTPRINT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL GOVERNANCE/MANAGEMENT",
    "variableLevel1": "ENVIRONMENTAL ASSESSMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL GOVERNANCE/MANAGEMENT",
    "variableLevel1": "ENVIRONMENTAL REGULATIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL GOVERNANCE/MANAGEMENT",
    "variableLevel1": "FIRE MANAGEMENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL GOVERNANCE/MANAGEMENT",
    "variableLevel1": "GEOENGINEERING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL GOVERNANCE/MANAGEMENT",
    "variableLevel1": "GEOENGINEERING",
    "variableLevel2": "CARBON DIOXIDE REMOVAL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL GOVERNANCE/MANAGEMENT",
    "variableLevel1": "GEOENGINEERING",
    "variableLevel2": "SOLAR RADIATION MANAGEMENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL GOVERNANCE/MANAGEMENT",
    "variableLevel1": "LAND MANAGEMENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL GOVERNANCE/MANAGEMENT",
    "variableLevel1": "LAND MANAGEMENT",
    "variableLevel2": "LAND TENURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL GOVERNANCE/MANAGEMENT",
    "variableLevel1": "LAND MANAGEMENT",
    "variableLevel2": "LAND USE CLASSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL GOVERNANCE/MANAGEMENT",
    "variableLevel1": "LAND MANAGEMENT",
    "variableLevel2": "LAND USE/LAND COVER CLASSIFICATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL GOVERNANCE/MANAGEMENT",
    "variableLevel1": "TREATY AGREEMENTS/RESULTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL GOVERNANCE/MANAGEMENT",
    "variableLevel1": "WATER MANAGEMENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL GOVERNANCE/MANAGEMENT",
    "variableLevel1": "WATER MANAGEMENT",
    "variableLevel2": "GROUNDWATER MANAGEMENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL GOVERNANCE/MANAGEMENT",
    "variableLevel1": "WATER MANAGEMENT",
    "variableLevel2": "STORMWATER MANAGEMENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL GOVERNANCE/MANAGEMENT",
    "variableLevel1": "WATER MANAGEMENT",
    "variableLevel2": "WASTEWATER MANAGEMENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL GOVERNANCE/MANAGEMENT",
    "variableLevel1": "WATER MANAGEMENT",
    "variableLevel2": "WATER STORAGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL GOVERNANCE/MANAGEMENT",
    "variableLevel1": "WATER MANAGEMENT",
    "variableLevel2": "WATER STORAGE",
    "variableLevel3": "ICE STUPA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL IMPACTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL IMPACTS",
    "variableLevel1": "ACID DEPOSITION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL IMPACTS",
    "variableLevel1": "AGRICULTURAL EXPANSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL IMPACTS",
    "variableLevel1": "BIOCHEMICAL RELEASE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL IMPACTS",
    "variableLevel1": "BIOMASS BURNING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL IMPACTS",
    "variableLevel1": "CHEMICAL SPILLS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL IMPACTS",
    "variableLevel1": "CIVIL DISTURBANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL IMPACTS",
    "variableLevel1": "CONSERVATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL IMPACTS",
    "variableLevel1": "CONTAMINANT LEVELS/SPILLS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL IMPACTS",
    "variableLevel1": "ELECTRIC/MAGNETIC FIELD EXPOSURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL IMPACTS",
    "variableLevel1": "FOSSIL FUEL BURNING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL IMPACTS",
    "variableLevel1": "GAS EXPLOSIONS/LEAKS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL IMPACTS",
    "variableLevel1": "GAS FLARING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL IMPACTS",
    "variableLevel1": "HEAVY METALS CONCENTRATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL IMPACTS",
    "variableLevel1": "INDUSTRIAL EMISSIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL IMPACTS",
    "variableLevel1": "MARINE DEBRIS REMOVAL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL IMPACTS",
    "variableLevel1": "MINE DRAINAGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL IMPACTS",
    "variableLevel1": "NUCLEAR RADIATION EXPOSURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL IMPACTS",
    "variableLevel1": "OIL SPILLS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL IMPACTS",
    "variableLevel1": "PRESCRIBED BURNS/FIRES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL IMPACTS",
    "variableLevel1": "SEWAGE DISPOSAL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "ENVIRONMENTAL IMPACTS",
    "variableLevel1": "WATER RESOURCES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "GLOBAL CHANGE RESPONSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "GLOBAL CHANGE RESPONSES",
    "variableLevel1": "CLIMATE ADAPTATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "GLOBAL CHANGE RESPONSES",
    "variableLevel1": "CLIMATE MITIGATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "GLOBAL CHANGE RESPONSES",
    "variableLevel1": "SCENARIO PLANNING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "HABITAT CONVERSION/FRAGMENTATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "HABITAT CONVERSION/FRAGMENTATION",
    "variableLevel1": "DEFORESTATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "HABITAT CONVERSION/FRAGMENTATION",
    "variableLevel1": "DESERTIFICATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "HABITAT CONVERSION/FRAGMENTATION",
    "variableLevel1": "ECOLOGICAL CORRIDORS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "HABITAT CONVERSION/FRAGMENTATION",
    "variableLevel1": "EUTROPHICATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "HABITAT CONVERSION/FRAGMENTATION",
    "variableLevel1": "IRRIGATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "HABITAT CONVERSION/FRAGMENTATION",
    "variableLevel1": "RECLAMATION/REVEGETATION/RESTORATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "HABITAT CONVERSION/FRAGMENTATION",
    "variableLevel1": "REFORESTATION/REVEGETATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "HABITAT CONVERSION/FRAGMENTATION",
    "variableLevel1": "URBANIZATION/URBAN SPRAWL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "HUMAN SETTLEMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "HUMAN SETTLEMENTS",
    "variableLevel1": "ARCHAEOLOGICAL AREAS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "HUMAN SETTLEMENTS",
    "variableLevel1": "COASTAL AREAS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "HUMAN SETTLEMENTS",
    "variableLevel1": "RURAL AREAS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "HUMAN SETTLEMENTS",
    "variableLevel1": "TRIBAL LANDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "HUMAN SETTLEMENTS",
    "variableLevel1": "URBAN AREAS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "INFRASTRUCTURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "INFRASTRUCTURE",
    "variableLevel1": "BUILDINGS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "INFRASTRUCTURE",
    "variableLevel1": "COMMUNICATIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "INFRASTRUCTURE",
    "variableLevel1": "CULTURAL FEATURES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "INFRASTRUCTURE",
    "variableLevel1": "ELECTRICITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "INFRASTRUCTURE",
    "variableLevel1": "GREEN INFRASTRUCTURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "INFRASTRUCTURE",
    "variableLevel1": "PIPELINES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "INFRASTRUCTURE",
    "variableLevel1": "TRANSPORTATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "INFRASTRUCTURE",
    "variableLevel1": "WATER STORAGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "NATURAL HAZARDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "NATURAL HAZARDS",
    "variableLevel1": "BIOLOGICAL HAZARDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "NATURAL HAZARDS",
    "variableLevel1": "DROUGHTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "NATURAL HAZARDS",
    "variableLevel1": "DROUGHTS",
    "variableLevel2": "DROUGHT DURATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "NATURAL HAZARDS",
    "variableLevel1": "DROUGHTS",
    "variableLevel2": "DROUGHT FREQUENCY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "NATURAL HAZARDS",
    "variableLevel1": "DROUGHTS",
    "variableLevel2": "DROUGHT SEVERITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "NATURAL HAZARDS",
    "variableLevel1": "EARTHQUAKES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "NATURAL HAZARDS",
    "variableLevel1": "FAMINE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "NATURAL HAZARDS",
    "variableLevel1": "FLOODS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "NATURAL HAZARDS",
    "variableLevel1": "HEAT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "NATURAL HAZARDS",
    "variableLevel1": "LAND SUBSIDENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "NATURAL HAZARDS",
    "variableLevel1": "LANDSLIDES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "NATURAL HAZARDS",
    "variableLevel1": "SEVERE STORMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "NATURAL HAZARDS",
    "variableLevel1": "TORNADOES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "NATURAL HAZARDS",
    "variableLevel1": "TROPICAL CYCLONES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "NATURAL HAZARDS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "CYCLONES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "NATURAL HAZARDS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "HURRICANES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "NATURAL HAZARDS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "SEVERE CYCLONIC STORMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "NATURAL HAZARDS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "SEVERE TROPICAL CYCLONES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "NATURAL HAZARDS",
    "variableLevel1": "TROPICAL CYCLONES",
    "variableLevel2": "TYPHOONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "NATURAL HAZARDS",
    "variableLevel1": "TSUNAMIS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "NATURAL HAZARDS",
    "variableLevel1": "VOLCANIC ERUPTIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "NATURAL HAZARDS",
    "variableLevel1": "WILDFIRES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "NATURAL HAZARDS",
    "variableLevel1": "WILDFIRES",
    "variableLevel2": "BURNED AREA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "NATURAL HAZARDS",
    "variableLevel1": "WILDFIRES",
    "variableLevel2": "WILDFIRE SUPPRESSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "POPULATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "POPULATION",
    "variableLevel1": "MORTALITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "POPULATION",
    "variableLevel1": "MORTALITY",
    "variableLevel2": "INFANT MORTALITY RATES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "POPULATION",
    "variableLevel1": "MORTALITY",
    "variableLevel2": "MORTALITY RATES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "POPULATION",
    "variableLevel1": "NATALITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "POPULATION",
    "variableLevel1": "NATALITY",
    "variableLevel2": "NATALITY RATES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "POPULATION",
    "variableLevel1": "POPULATION DENSITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "POPULATION",
    "variableLevel1": "POPULATION DISTRIBUTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "POPULATION",
    "variableLevel1": "POPULATION ESTIMATES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "POPULATION",
    "variableLevel1": "POPULATION SIZE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "POPULATION",
    "variableLevel1": "VULNERABLE POPULATIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "PUBLIC HEALTH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "PUBLIC HEALTH",
    "variableLevel1": "DISEASES/EPIDEMICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "PUBLIC HEALTH",
    "variableLevel1": "DISEASES/EPIDEMICS",
    "variableLevel2": "EPIDEMIOLOGY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "PUBLIC HEALTH",
    "variableLevel1": "DISEASES/EPIDEMICS",
    "variableLevel2": "EPIDEMIOLOGY",
    "variableLevel3": "TELE-EPIDEMIOLOGY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "PUBLIC HEALTH",
    "variableLevel1": "DISEASES/EPIDEMICS",
    "variableLevel2": "FOODBORNE DISEASES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "PUBLIC HEALTH",
    "variableLevel1": "DISEASES/EPIDEMICS",
    "variableLevel2": "VECTOR-BORN DISEASES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "PUBLIC HEALTH",
    "variableLevel1": "DISEASES/EPIDEMICS",
    "variableLevel2": "WATERBORNE DISEASES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "PUBLIC HEALTH",
    "variableLevel1": "ENVIRONMENTAL HEALTH FACTORS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "PUBLIC HEALTH",
    "variableLevel1": "ENVIRONMENTAL HEALTH FACTORS",
    "variableLevel2": "AEROALLERGENS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "PUBLIC HEALTH",
    "variableLevel1": "ENVIRONMENTAL HEALTH FACTORS",
    "variableLevel2": "PARTICULATE MATTER CONCENTRATIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "PUBLIC HEALTH",
    "variableLevel1": "ENVIRONMENTAL HEALTH FACTORS",
    "variableLevel2": "Urban Heat Island"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "PUBLIC HEALTH",
    "variableLevel1": "FOOD SECURITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "PUBLIC HEALTH",
    "variableLevel1": "MALNUTRITION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "PUBLIC HEALTH",
    "variableLevel1": "MALNUTRITION",
    "variableLevel2": "MALNUTRITION RATES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "PUBLIC HEALTH",
    "variableLevel1": "MENTAL HEALTH IMPACTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "PUBLIC HEALTH",
    "variableLevel1": "MORBIDITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "PUBLIC HEALTH",
    "variableLevel1": "MORBIDITY",
    "variableLevel2": "MORBIDITY RATES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "PUBLIC HEALTH",
    "variableLevel1": "RADIATION EXPOSURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "PUBLIC HEALTH",
    "variableLevel1": "WATER TREATMENT DISRUPTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "SOCIAL BEHAVIOR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "SOCIAL BEHAVIOR",
    "variableLevel1": "CONSERVATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "SOCIAL BEHAVIOR",
    "variableLevel1": "CONSUMER BEHAVIOR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "SOCIAL BEHAVIOR",
    "variableLevel1": "DISASTER RESPONSE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "SOCIAL BEHAVIOR",
    "variableLevel1": "HAZARD MITIGATION/PLANNING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "SOCIAL BEHAVIOR",
    "variableLevel1": "PRESERVATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "SOCIAL BEHAVIOR",
    "variableLevel1": "RECREATIONAL ACTIVITIES/AREAS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "SOCIAL BEHAVIOR",
    "variableLevel1": "RECYCLING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "SOCIAL BEHAVIOR",
    "variableLevel1": "VULNERABILITY LEVELS/INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "SOCIOECONOMICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "SOCIOECONOMICS",
    "variableLevel1": "HOUSEHOLD INCOME"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "SOCIOECONOMICS",
    "variableLevel1": "INDUSTRIALIZATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "SOCIOECONOMICS",
    "variableLevel1": "POVERTY LEVELS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "SOCIOECONOMICS",
    "variableLevel1": "PURCHASING POWER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "SUSTAINABILITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "SUSTAINABILITY",
    "variableLevel1": "ENVIRONMENTAL SUSTAINABILITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "HUMAN DIMENSIONS",
    "term": "SUSTAINABILITY",
    "variableLevel1": "SUSTAINABLE DEVELOPMENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "EROSION/SEDIMENTATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "EROSION/SEDIMENTATION",
    "variableLevel1": "DEGRADATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "EROSION/SEDIMENTATION",
    "variableLevel1": "ENTRAINMENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "EROSION/SEDIMENTATION",
    "variableLevel1": "EROSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "EROSION/SEDIMENTATION",
    "variableLevel1": "LANDSLIDES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "EROSION/SEDIMENTATION",
    "variableLevel1": "SEDIMENT CHEMISTRY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "EROSION/SEDIMENTATION",
    "variableLevel1": "SEDIMENT COMPOSITION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "EROSION/SEDIMENTATION",
    "variableLevel1": "SEDIMENT TRANSPORT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "EROSION/SEDIMENTATION",
    "variableLevel1": "SEDIMENTATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "EROSION/SEDIMENTATION",
    "variableLevel1": "SEDIMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "EROSION/SEDIMENTATION",
    "variableLevel1": "STRATIGRAPHIC SEQUENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "EROSION/SEDIMENTATION",
    "variableLevel1": "SUSPENDED SOLIDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "EROSION/SEDIMENTATION",
    "variableLevel1": "WEATHERING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "FROZEN GROUND"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "FROZEN GROUND",
    "variableLevel1": "ACTIVE LAYER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "FROZEN GROUND",
    "variableLevel1": "CRYOSOLS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "FROZEN GROUND",
    "variableLevel1": "GROUND ICE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "FROZEN GROUND",
    "variableLevel1": "PERIGLACIAL PROCESSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "FROZEN GROUND",
    "variableLevel1": "PERMAFROST"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "FROZEN GROUND",
    "variableLevel1": "ROCK GLACIERS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "FROZEN GROUND",
    "variableLevel1": "SEASONALLY FROZEN GROUND"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "FROZEN GROUND",
    "variableLevel1": "SOIL TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "FROZEN GROUND",
    "variableLevel1": "TALIK"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN LANDFORMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN LANDFORMS",
    "variableLevel2": "DUNES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN LANDFORMS",
    "variableLevel2": "DUNES",
    "variableLevel3": "CRESCENTIC (BARCHAN/TRANSVERSE) DUNE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN LANDFORMS",
    "variableLevel2": "DUNES",
    "variableLevel3": "DOME DUNE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN LANDFORMS",
    "variableLevel2": "DUNES",
    "variableLevel3": "LONGITUDINAL/LINEAR DUNE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN LANDFORMS",
    "variableLevel2": "DUNES",
    "variableLevel3": "PARABOLIC DUNE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN LANDFORMS",
    "variableLevel2": "DUNES",
    "variableLevel3": "STAR DUNE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN LANDFORMS",
    "variableLevel2": "RIPPLES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN PROCESSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN PROCESSES",
    "variableLevel2": "ABRASION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN PROCESSES",
    "variableLevel2": "ABRASION",
    "variableLevel3": "VENTIFACTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN PROCESSES",
    "variableLevel2": "ABRASION",
    "variableLevel3": "YARDANGS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN PROCESSES",
    "variableLevel2": "DEFLATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN PROCESSES",
    "variableLevel2": "DEGRADATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN PROCESSES",
    "variableLevel2": "SALTATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN PROCESSES",
    "variableLevel2": "SEDIMENT TRANSPORT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN PROCESSES",
    "variableLevel2": "SEDIMENT TRANSPORT",
    "variableLevel3": "LOESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN PROCESSES",
    "variableLevel2": "SEDIMENT TRANSPORT",
    "variableLevel3": "MONADNOCK"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN PROCESSES",
    "variableLevel2": "SEDIMENTATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN PROCESSES",
    "variableLevel2": "SEDIMENTATION",
    "variableLevel3": "SEDIMENT CHEMISTRY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN PROCESSES",
    "variableLevel2": "SEDIMENTATION",
    "variableLevel3": "SEDIMENT COMPOSITION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN PROCESSES",
    "variableLevel2": "SEDIMENTATION",
    "variableLevel3": "STRATIGRAPHIC SEQUENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN PROCESSES",
    "variableLevel2": "WEATHERING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "BARRIER ISLANDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "BEACHES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "CORAL REEFS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "CORAL REEFS",
    "variableLevel3": "APRON REEF"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "CORAL REEFS",
    "variableLevel3": "ATOLL REEF"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "CORAL REEFS",
    "variableLevel3": "BANK REEF"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "CORAL REEFS",
    "variableLevel3": "BARRIER REEF"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "CORAL REEFS",
    "variableLevel3": "FRINGING REEF"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "CORAL REEFS",
    "variableLevel3": "PATCH REEF"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "CORAL REEFS",
    "variableLevel3": "RIBBON REEF"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "CORAL REEFS",
    "variableLevel3": "TABLE REEF"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "CUSPATE FORELANDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "DELTAS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "DUNES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "DUNES",
    "variableLevel3": "CRESCENTIC (BARCHAN/TRANSVERSE)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "DUNES",
    "variableLevel3": "DOME DUNE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "DUNES",
    "variableLevel3": "LONGITUDINAL/LINEAR DUNE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "DUNES",
    "variableLevel3": "PARABOLIC DUNE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "DUNES",
    "variableLevel3": "STAR DUNE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "ESTUARIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "FJORDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "HEADLANDS/BAYS/CAPE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "INLETS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "ISTHMUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "LAGOONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "RIA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "SALT MARSH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "SEA ARCHES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "SEA CAVES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "SEA CLIFFS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "SHOALS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "SHORELINES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "SOUNDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "SPITS AND BARS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "TOMBOLOS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "WAVE-CUT NOTCH/PLATFORMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "ABRASION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "ACCRETION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "ATTRITION/WEATHERING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "CHEMICAL SOLUTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "DEPOSITION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "FLOODING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "HYDRAULIC ACTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "SALTATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "SEA LEVEL CHANGES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "SEDIMENT TRANSPORT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "SEDIMENTATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "SEDIMENTATION",
    "variableLevel3": "SEDIMENT CHEMISTRY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "SEDIMENTATION",
    "variableLevel3": "SEDIMENT COMPOSITION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "SEDIMENTATION",
    "variableLevel3": "STRATIGRAPHIC SEQUENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "SUBMERGENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "SUBSIDENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "SUSPENSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "WAVE BREAKING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "WAVE DIFFRACTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "WAVE EROSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "WAVE REFRACTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "WAVE SHOALING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "AIT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "BAR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "BAYOU"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "CANYON"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "CONFLUENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "CUTBANK"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "DELTAS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "ENDORHEIC BASIN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "FLOOD PLAIN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "GULLY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "ISLAND"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "LACUSTRINE PLAIN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "MARSH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "MEANDER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "OX-BOW LAKE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "PINGO"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "POINT BAR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "POND"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "RIFFLE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "RIVER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "SPRING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "STREAM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "STREAM TERRACE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "SWAMP"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "VALLEY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "VALLEY",
    "variableLevel3": "V SHAPED VALLEY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "WATERFALL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "WATERSHED/DRAINAGE BASIN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL PROCESSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL PROCESSES",
    "variableLevel2": "ABRASION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL PROCESSES",
    "variableLevel2": "ATTRITION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL PROCESSES",
    "variableLevel2": "DEGRADATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL PROCESSES",
    "variableLevel2": "DOWNCUTTING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL PROCESSES",
    "variableLevel2": "ENTRAINMENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL PROCESSES",
    "variableLevel2": "HYDRAULIC ACTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL PROCESSES",
    "variableLevel2": "LANDSLIDES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL PROCESSES",
    "variableLevel2": "SALTATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL PROCESSES",
    "variableLevel2": "SEDIMENT TRANSPORT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL PROCESSES",
    "variableLevel2": "SEDIMENTATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL PROCESSES",
    "variableLevel2": "SEDIMENTATION",
    "variableLevel3": "SEDIMENT CHEMISTRY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL PROCESSES",
    "variableLevel2": "SEDIMENTATION",
    "variableLevel3": "SEDIMENT COMPOSITION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL PROCESSES",
    "variableLevel2": "SEDIMENTATION",
    "variableLevel3": "STRATIGRAPHIC SEQUENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL PROCESSES",
    "variableLevel2": "SUSPENSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL PROCESSES",
    "variableLevel2": "WEATHERING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "ARETES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "CIRQUES/COMBES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "CREVASSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "CREVASSES",
    "variableLevel3": "LONGITUDINAL CREVASSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "CREVASSES",
    "variableLevel3": "MARGINAL CREVASSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "CREVASSES",
    "variableLevel3": "TRANSVERSE CREVASSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "DRUMLINS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "ESKERS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "FJORDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "GLACIAL HORNS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "GLACIER STRIATIONS/GROOVES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "GLACIER/HANGING/U-SHAPED VALLEYS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "GLACIER/ICE CAVES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "ICE-DAMMED LAKES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "KAME DELTA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "KAMES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "KETTLE HOLES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "MORAINES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "MORAINES",
    "variableLevel3": "LATERAL MORAINE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "MORAINES",
    "variableLevel3": "MEDIAL MORAINE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "MORAINES",
    "variableLevel3": "RECESSIONAL MORAINE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "MORAINES",
    "variableLevel3": "RIBBED/ROGAN MORAINE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "MORAINES",
    "variableLevel3": "TERMINAL MORAINE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "NUNATAKS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "OUTWASH FANS/PLAINS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "ROCHE MOUTONNEES/SHEEPBACK"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "ROCK GLACIERS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "TILL PLAINS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "ABLATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "ABRASION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "CRUST REBOUND"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "DEGRADATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "DUMPING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "ENTRAINMENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "FIRN FORMATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "FREEZE/THAW"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "FREEZE/THAW",
    "variableLevel3": "BASAL ICE FREEZING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "GLACIAL DISPLACEMENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "GLACIAL DRIFT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "GLACIAL GROWTH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "GLACIAL STRIATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "GLACIER CRUST SUBSIDENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "PERIGLACIAL PROCESSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "PLUCKING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "SCOURING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "SEDIMENT TRANSPORT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "SEDIMENTATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "SEDIMENTATION",
    "variableLevel3": "SEDIMENT CHEMISTRY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "SEDIMENTATION",
    "variableLevel3": "STRATIGRAPHIC SEQUENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "WEATHERING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "KARST LANDFORMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "KARST LANDFORMS",
    "variableLevel2": "CAVES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "KARST LANDFORMS",
    "variableLevel2": "COCKPIT/TOWER KARST"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "KARST LANDFORMS",
    "variableLevel2": "KARST VALLEY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "KARST LANDFORMS",
    "variableLevel2": "SINKHOLES (DOLINES)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "KARST LANDFORMS",
    "variableLevel2": "UVALA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "KARST PROCESSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "KARST PROCESSES",
    "variableLevel2": "CAC03"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "KARST PROCESSES",
    "variableLevel2": "DISSOLVED CO2"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "KARST PROCESSES",
    "variableLevel2": "KARST HYDROLOGY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "KARST PROCESSES",
    "variableLevel2": "POROSITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "KARST PROCESSES",
    "variableLevel2": "WEATHERING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS",
    "variableLevel2": "CALDRA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS",
    "variableLevel2": "CINDER CONE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS",
    "variableLevel2": "FAULTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS",
    "variableLevel2": "FOLDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS",
    "variableLevel2": "GEYSER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS",
    "variableLevel2": "GRABEN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS",
    "variableLevel2": "HORST"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS",
    "variableLevel2": "LAVA DOME"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS",
    "variableLevel2": "LAVA PLAIN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS",
    "variableLevel2": "MAAR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS",
    "variableLevel2": "MOUNTAINS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS",
    "variableLevel2": "PLATEAU"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS",
    "variableLevel2": "RIDGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS",
    "variableLevel2": "RIFT VALLEY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS",
    "variableLevel2": "TUYA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS",
    "variableLevel2": "VOLCANO"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC PROCESSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC PROCESSES",
    "variableLevel2": "EPEIROGENIC MOVEMENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC PROCESSES",
    "variableLevel2": "ISOSTATIC UPLIFT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC PROCESSES",
    "variableLevel2": "OROGENIC MOVEMENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC PROCESSES",
    "variableLevel2": "RIFTING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC PROCESSES",
    "variableLevel2": "SUBDUCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC PROCESSES",
    "variableLevel2": "TECTONIC UPLIFT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "LAND USE/LAND COVER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "LAND USE/LAND COVER",
    "variableLevel1": "LAND PRODUCTIVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "LAND USE/LAND COVER",
    "variableLevel1": "LAND RESOURCES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "LAND USE/LAND COVER",
    "variableLevel1": "LAND USE CLASSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "LAND USE/LAND COVER",
    "variableLevel1": "LAND USE/LAND COVER CLASSIFICATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "LAND USE/LAND COVER",
    "variableLevel1": "LAND USE/LAND COVER CLASSIFICATION",
    "variableLevel2": "VEGETATION INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "LAND USE/LAND COVER",
    "variableLevel1": "LAND USE/LAND COVER CLASSIFICATION",
    "variableLevel2": "VEGETATION INDEX",
    "variableLevel3": "NORMALIZED DIFFERENCE VEGETATION INDEX (NDVI)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "LAND USE/LAND COVER",
    "variableLevel1": "LAND/OCEAN/ICE FRACTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "LAND USE/LAND COVER",
    "variableLevel1": "LAND/OCEAN/ICE MASK"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "LANDSCAPE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "LANDSCAPE",
    "variableLevel1": "LANDSCAPE ECOLOGY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "LANDSCAPE",
    "variableLevel1": "LANDSCAPE MANAGEMENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "LANDSCAPE",
    "variableLevel1": "LANDSCAPE PATTERNS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "LANDSCAPE",
    "variableLevel1": "LANDSCAPE PROCESSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "LANDSCAPE",
    "variableLevel1": "RECLAMATION/REVEGETATION/RESTORATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "LANDSCAPE",
    "variableLevel1": "REFORESTATION"
  },
  { "category": "EARTH SCIENCE", "topic": "LAND SURFACE", "term": "SOILS" },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "CALCIUM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "CARBON"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "CARBON",
    "variableLevel2": "NET ECOSYSTEM CO2 EXCHANGE (NEE)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "CARBON",
    "variableLevel2": "SOIL ORGANIC CARBON (SOC)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "CATION EXCHANGE CAPACITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "DENITRIFICATION RATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "ELECTRICAL CONDUCTIVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "HEAVY METALS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "HYDRAULIC CONDUCTIVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "IRON"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "MACROFAUNA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "MAGNESIUM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "MICROFAUNA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "MICROFLORA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "MICRONUTRIENTS/TRACE ELEMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "NITROGEN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "ORGANIC MATTER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "PERMAFROST"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "PHOSPHORUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "POTASSIUM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "RECLAMATION/REVEGETATION/RESTORATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL ABSORPTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL BIOGEOCHEMISTRY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL BULK DENSITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL CHEMISTRY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL CLASSIFICATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL COLOR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL COMPACTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL CONSISTENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL DEPTH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL EROSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL FERTILITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL GAS/AIR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL HEAT BUDGET"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL HORIZONS/PROFILE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL IMPEDANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL INFILTRATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL MECHANICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL MOISTURE/WATER CONTENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL MOISTURE/WATER CONTENT",
    "variableLevel2": "ROOT ZONE SOIL MOISTURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL MOISTURE/WATER CONTENT",
    "variableLevel2": "SURFACE SOIL MOISTURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL PH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL PLASTICITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL POROSITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL PRODUCTIVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL PRODUCTIVITY",
    "variableLevel2": "GROSS PRIMARY PRODUCTION (GPP)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL RESPIRATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL RESPIRATION",
    "variableLevel2": "AUTOTROPHIC RESPIRATION (Ra)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL RESPIRATION",
    "variableLevel2": "HETEROTROPHIC RESPIRATION (Rh)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL ROOTING DEPTH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL SALINITY/SOIL SODICITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL STRUCTURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL TEXTURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SOIL WATER HOLDING CAPACITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "SULFUR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SOILS",
    "variableLevel1": "THERMAL CONDUCTIVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SURFACE RADIATIVE PROPERTIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SURFACE RADIATIVE PROPERTIES",
    "variableLevel1": "ALBEDO"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SURFACE RADIATIVE PROPERTIES",
    "variableLevel1": "ANISOTROPY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SURFACE RADIATIVE PROPERTIES",
    "variableLevel1": "EMISSIVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SURFACE RADIATIVE PROPERTIES",
    "variableLevel1": "REFLECTANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SURFACE THERMAL PROPERTIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SURFACE THERMAL PROPERTIES",
    "variableLevel1": "HEAT FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SURFACE THERMAL PROPERTIES",
    "variableLevel1": "HEAT FLUX",
    "variableLevel2": "LATENT HEAT FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SURFACE THERMAL PROPERTIES",
    "variableLevel1": "HEAT FLUX",
    "variableLevel2": "LONGWAVE HEAT FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SURFACE THERMAL PROPERTIES",
    "variableLevel1": "HEAT FLUX",
    "variableLevel2": "SENSIBLE HEAT FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SURFACE THERMAL PROPERTIES",
    "variableLevel1": "HEAT FLUX",
    "variableLevel2": "SHORTWAVE HEAT FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SURFACE THERMAL PROPERTIES",
    "variableLevel1": "LAND HEAT CAPACITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SURFACE THERMAL PROPERTIES",
    "variableLevel1": "LAND SURFACE TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "SURFACE THERMAL PROPERTIES",
    "variableLevel1": "SKIN TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "TOPOGRAPHY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "TOPOGRAPHY",
    "variableLevel1": "SURFACE ROUGHNESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "TOPOGRAPHY",
    "variableLevel1": "TERRAIN ELEVATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "TOPOGRAPHY",
    "variableLevel1": "TERRAIN ELEVATION",
    "variableLevel2": "BED ELEVATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "TOPOGRAPHY",
    "variableLevel1": "TERRAIN ELEVATION",
    "variableLevel2": "CONTOUR MAPS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "TOPOGRAPHY",
    "variableLevel1": "TERRAIN ELEVATION",
    "variableLevel2": "DIGITAL ELEVATION/TERRAIN MODEL (DEM)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "TOPOGRAPHY",
    "variableLevel1": "TERRAIN ELEVATION",
    "variableLevel2": "TOPOGRAPHICAL RELIEF MAPS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "LAND SURFACE",
    "term": "TOPOGRAPHY",
    "variableLevel1": "TOPOGRAPHIC EFFECTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "AQUATIC SCIENCES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "AQUATIC SCIENCES",
    "variableLevel1": "AQUACULTURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "AQUATIC SCIENCES",
    "variableLevel1": "FISHERIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "BATHYMETRY/SEAFLOOR TOPOGRAPHY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "BATHYMETRY/SEAFLOOR TOPOGRAPHY",
    "variableLevel1": "ABYSSAL HILLS/PLAINS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "BATHYMETRY/SEAFLOOR TOPOGRAPHY",
    "variableLevel1": "BATHYMETRY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "BATHYMETRY/SEAFLOOR TOPOGRAPHY",
    "variableLevel1": "BATHYMETRY",
    "variableLevel2": "COASTAL BATHYMETRY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "BATHYMETRY/SEAFLOOR TOPOGRAPHY",
    "variableLevel1": "CONTINENTAL MARGINS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "BATHYMETRY/SEAFLOOR TOPOGRAPHY",
    "variableLevel1": "FRACTURE ZONES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "BATHYMETRY/SEAFLOOR TOPOGRAPHY",
    "variableLevel1": "OCEAN PLATEAUS/RIDGES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "BATHYMETRY/SEAFLOOR TOPOGRAPHY",
    "variableLevel1": "SEAFLOOR TOPOGRAPHY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "BATHYMETRY/SEAFLOOR TOPOGRAPHY",
    "variableLevel1": "SEAMOUNTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "BATHYMETRY/SEAFLOOR TOPOGRAPHY",
    "variableLevel1": "SUB-BOTTOM PROFILE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "BATHYMETRY/SEAFLOOR TOPOGRAPHY",
    "variableLevel1": "SUBMARINE CANYONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "BATHYMETRY/SEAFLOOR TOPOGRAPHY",
    "variableLevel1": "TRENCHES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "BATHYMETRY/SEAFLOOR TOPOGRAPHY",
    "variableLevel1": "WATER DEPTH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "COASTAL PROCESSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "COASTAL PROCESSES",
    "variableLevel1": "BARRIER ISLANDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "COASTAL PROCESSES",
    "variableLevel1": "BEACHES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "COASTAL PROCESSES",
    "variableLevel1": "BOTTOM COVER TYPE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "COASTAL PROCESSES",
    "variableLevel1": "COASTAL ELEVATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "COASTAL PROCESSES",
    "variableLevel1": "CORAL REEFS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "COASTAL PROCESSES",
    "variableLevel1": "CORAL REEFS",
    "variableLevel2": "CORAL BLEACHING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "COASTAL PROCESSES",
    "variableLevel1": "CORAL REEFS",
    "variableLevel2": "CORAL REEF ASSESSMENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "COASTAL PROCESSES",
    "variableLevel1": "CORAL REEFS",
    "variableLevel2": "CORAL REEF EXTENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "COASTAL PROCESSES",
    "variableLevel1": "DELTAS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "COASTAL PROCESSES",
    "variableLevel1": "DUNES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "COASTAL PROCESSES",
    "variableLevel1": "EROSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "COASTAL PROCESSES",
    "variableLevel1": "ESTUARIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "COASTAL PROCESSES",
    "variableLevel1": "FJORDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "COASTAL PROCESSES",
    "variableLevel1": "INLETS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "COASTAL PROCESSES",
    "variableLevel1": "INTERTIDAL ZONE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "COASTAL PROCESSES",
    "variableLevel1": "LAGOONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "COASTAL PROCESSES",
    "variableLevel1": "LOCAL SUBSIDENCE TRENDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "COASTAL PROCESSES",
    "variableLevel1": "LONGSHORE CURRENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "COASTAL PROCESSES",
    "variableLevel1": "MANGROVES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "COASTAL PROCESSES",
    "variableLevel1": "MARSHES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "COASTAL PROCESSES",
    "variableLevel1": "ROCKY COASTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "COASTAL PROCESSES",
    "variableLevel1": "SALTWATER INTRUSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "COASTAL PROCESSES",
    "variableLevel1": "SEA LEVEL RISE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "COASTAL PROCESSES",
    "variableLevel1": "SEA SURFACE HEIGHT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "COASTAL PROCESSES",
    "variableLevel1": "SEDIMENT TRANSPORT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "COASTAL PROCESSES",
    "variableLevel1": "SEDIMENTATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "COASTAL PROCESSES",
    "variableLevel1": "SHOALS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "COASTAL PROCESSES",
    "variableLevel1": "SHORELINE DISPLACEMENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "COASTAL PROCESSES",
    "variableLevel1": "SHORELINES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "COASTAL PROCESSES",
    "variableLevel1": "SHORELINES",
    "variableLevel2": "SHORELINE MAPPING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "COASTAL PROCESSES",
    "variableLevel1": "STORM SURGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "COASTAL PROCESSES",
    "variableLevel1": "TIDAL HEIGHT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "GEOLOGICAL FEATURES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "GEOLOGICAL FEATURES",
    "variableLevel1": "LAND/OCEAN/ICE FRACTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "GEOLOGICAL FEATURES",
    "variableLevel1": "LAND/OCEAN/ICE MASK"
  },
  { "category": "EARTH SCIENCE", "topic": "OCEANS", "term": "HYDROGRAPHY" },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE ENVIRONMENT MONITORING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE ENVIRONMENT MONITORING",
    "variableLevel1": "ENFORCEMENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE ENVIRONMENT MONITORING",
    "variableLevel1": "MARINE OBSTRUCTIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE ENVIRONMENT MONITORING",
    "variableLevel1": "MARINE SUBMERGED DEBRIS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE ENVIRONMENT MONITORING",
    "variableLevel1": "MARINE SURFACE ELEMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE ENVIRONMENT MONITORING",
    "variableLevel1": "MARINE SURFACE ELEMENTS",
    "variableLevel2": "MARINE SURFACE DEBRIS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE ENVIRONMENT MONITORING",
    "variableLevel1": "MARINE SURFACE ELEMENTS",
    "variableLevel2": "MARINE VESSELS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE GEOPHYSICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE GEOPHYSICS",
    "variableLevel1": "MAGNETIC ANOMALIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE GEOPHYSICS",
    "variableLevel1": "MARINE GRAVITY FIELD"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE GEOPHYSICS",
    "variableLevel1": "MARINE MAGNETICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE GEOPHYSICS",
    "variableLevel1": "PLATE TECTONICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE SEDIMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE SEDIMENTS",
    "variableLevel1": "BIOGENIC SEDIMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE SEDIMENTS",
    "variableLevel1": "BIOTURBATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE SEDIMENTS",
    "variableLevel1": "DIAGENESIS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE SEDIMENTS",
    "variableLevel1": "GEOTECHNICAL PROPERTIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE SEDIMENTS",
    "variableLevel1": "HYDROGENOUS SEDIMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE SEDIMENTS",
    "variableLevel1": "PARTICLE FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE SEDIMENTS",
    "variableLevel1": "SEDIMENT CHEMISTRY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE SEDIMENTS",
    "variableLevel1": "SEDIMENT COMPOSITION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE SEDIMENTS",
    "variableLevel1": "SEDIMENT TRANSPORT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE SEDIMENTS",
    "variableLevel1": "SEDIMENTARY STRUCTURES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE SEDIMENTS",
    "variableLevel1": "SEDIMENTARY TEXTURES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE SEDIMENTS",
    "variableLevel1": "SEDIMENTATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE SEDIMENTS",
    "variableLevel1": "STRATIGRAPHIC SEQUENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE SEDIMENTS",
    "variableLevel1": "SUSPENDED SOLIDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE SEDIMENTS",
    "variableLevel1": "TERRIGENOUS SEDIMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE SEDIMENTS",
    "variableLevel1": "TURBIDITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE VOLCANISM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE VOLCANISM",
    "variableLevel1": "BENTHIC HEAT FLOW"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE VOLCANISM",
    "variableLevel1": "HYDROTHERMAL VENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE VOLCANISM",
    "variableLevel1": "ISLAND ARCS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE VOLCANISM",
    "variableLevel1": "MID-OCEAN RIDGES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "MARINE VOLCANISM",
    "variableLevel1": "RIFT VALLEYS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN ACOUSTICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN ACOUSTICS",
    "variableLevel1": "ACOUSTIC ATTENUATION/TRANSMISSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN ACOUSTICS",
    "variableLevel1": "ACOUSTIC FREQUENCY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN ACOUSTICS",
    "variableLevel1": "ACOUSTIC REFLECTIVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN ACOUSTICS",
    "variableLevel1": "ACOUSTIC SCATTERING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN ACOUSTICS",
    "variableLevel1": "ACOUSTIC TOMOGRAPHY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN ACOUSTICS",
    "variableLevel1": "ACOUSTIC VELOCITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN ACOUSTICS",
    "variableLevel1": "AMBIENT NOISE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN ACOUSTICS",
    "variableLevel1": "AMBIENT NOISE",
    "variableLevel2": "BIOLOGICAL AMBIENT NOISE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN ACOUSTICS",
    "variableLevel1": "AMBIENT NOISE",
    "variableLevel2": "PHYSICAL AMBIENT NOISE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN ACOUSTICS",
    "variableLevel1": "AMBIENT NOISE",
    "variableLevel2": "TOTAL AMBIENT NOISE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "ALKALINITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "AMMONIA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "BIOGEOCHEMICAL CYCLES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "BIOMEDICAL CHEMICALS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "CARBON"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "CARBON DIOXIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "CARBON DIOXIDE",
    "variableLevel2": "CARBON DIOXIDE PARTIAL PRESSURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "CARBONATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "CHLOROPHYLL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "DISSOLVED GASES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "DISSOLVED SOLIDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "HYDROCARBONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "HYPOXIA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "INORGANIC CARBON"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "INORGANIC MATTER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "MARINE GEOCHEMISTRY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "NITRATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "NITRIC ACID"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "NITRITE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "NITROGEN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "NITROGEN DIOXIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "NITROUS OXIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "NUTRIENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "NUTRIENTS",
    "variableLevel2": "NUTRIENT PROFILES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "NUTRIENTS",
    "variableLevel2": "SURFACE NUTRIENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "OCEAN TRACERS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "ORGANIC CARBON"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "ORGANIC MATTER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "ORGANIC MATTER",
    "variableLevel2": "COLORED DISSOLVED ORGANIC MATTER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "OXYGEN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "PH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "PHOSPHATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "PIGMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "PIGMENTS",
    "variableLevel2": "CHLOROPHYLL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "RADIOCARBON"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "RADIONUCLIDES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "SILICATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "STABLE ISOTOPES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "SUSPENDED SOLIDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CHEMISTRY",
    "variableLevel1": "TRACE ELEMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CIRCULATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CIRCULATION",
    "variableLevel1": "ADVECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CIRCULATION",
    "variableLevel1": "BUOY POSITION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CIRCULATION",
    "variableLevel1": "CONVECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CIRCULATION",
    "variableLevel1": "DIFFUSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CIRCULATION",
    "variableLevel1": "EDDIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CIRCULATION",
    "variableLevel1": "EDDIES",
    "variableLevel2": "MESOSCALE EDDIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CIRCULATION",
    "variableLevel1": "EDDIES",
    "variableLevel2": "SUBMESOCALE EDDIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CIRCULATION",
    "variableLevel1": "FRESH WATER FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CIRCULATION",
    "variableLevel1": "FRONTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CIRCULATION",
    "variableLevel1": "GYRES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CIRCULATION",
    "variableLevel1": "OCEAN CURRENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CIRCULATION",
    "variableLevel1": "OCEAN CURRENTS",
    "variableLevel2": "CURRENT PROFILES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CIRCULATION",
    "variableLevel1": "OCEAN CURRENTS",
    "variableLevel2": "SPEED PROFILES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CIRCULATION",
    "variableLevel1": "OCEAN CURRENTS",
    "variableLevel2": "SUBSURFACE CURRENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CIRCULATION",
    "variableLevel1": "OCEAN CURRENTS",
    "variableLevel2": "SURFACE CURRENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CIRCULATION",
    "variableLevel1": "OCEAN CURRENTS",
    "variableLevel2": "SURFACE SPEED"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CIRCULATION",
    "variableLevel1": "OCEAN MASS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CIRCULATION",
    "variableLevel1": "OCEAN MIXED LAYER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CIRCULATION",
    "variableLevel1": "SUBDUCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CIRCULATION",
    "variableLevel1": "THERMOHALINE CIRCULATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CIRCULATION",
    "variableLevel1": "TURBULENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CIRCULATION",
    "variableLevel1": "UPWELLING/DOWNWELLING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CIRCULATION",
    "variableLevel1": "VORTICITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CIRCULATION",
    "variableLevel1": "VORTICITY",
    "variableLevel2": "POTENTIAL VORTICITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CIRCULATION",
    "variableLevel1": "VORTICITY",
    "variableLevel2": "RELATIVE VORTICITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CIRCULATION",
    "variableLevel1": "WATER MASSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN CIRCULATION",
    "variableLevel1": "WIND-DRIVEN CIRCULATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN HEAT BUDGET"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN HEAT BUDGET",
    "variableLevel1": "ADVECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN HEAT BUDGET",
    "variableLevel1": "BOWEN RATIO"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN HEAT BUDGET",
    "variableLevel1": "CONDENSATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN HEAT BUDGET",
    "variableLevel1": "CONDUCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN HEAT BUDGET",
    "variableLevel1": "CONVECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN HEAT BUDGET",
    "variableLevel1": "DIFFUSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN HEAT BUDGET",
    "variableLevel1": "EVAPORATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN HEAT BUDGET",
    "variableLevel1": "HEAT FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN HEAT BUDGET",
    "variableLevel1": "HEAT FLUX",
    "variableLevel2": "CONDUCTIVE HEAT FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN HEAT BUDGET",
    "variableLevel1": "HEAT FLUX",
    "variableLevel2": "GEOTHERMAL HEAT FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN HEAT BUDGET",
    "variableLevel1": "HEAT FLUX",
    "variableLevel2": "LATENT HEAT FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN HEAT BUDGET",
    "variableLevel1": "HEAT FLUX",
    "variableLevel2": "SENSIBLE HEAT FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN HEAT BUDGET",
    "variableLevel1": "HEAT FLUX",
    "variableLevel2": "TURBULENT HEAT FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN HEAT BUDGET",
    "variableLevel1": "HEATING RATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN HEAT BUDGET",
    "variableLevel1": "LONGWAVE RADIATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN HEAT BUDGET",
    "variableLevel1": "REFLECTANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN HEAT BUDGET",
    "variableLevel1": "SHORTWAVE RADIATION"
  },
  { "category": "EARTH SCIENCE", "topic": "OCEANS", "term": "OCEAN OPTICS" },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN OPTICS",
    "variableLevel1": "ABSORPTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN OPTICS",
    "variableLevel1": "APHOTIC/PHOTIC ZONE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN OPTICS",
    "variableLevel1": "ATTENUATION/TRANSMISSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN OPTICS",
    "variableLevel1": "BIOLUMINESCENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN OPTICS",
    "variableLevel1": "CHLOROPHYLL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN OPTICS",
    "variableLevel1": "CHLOROPHYLL",
    "variableLevel2": "CHLOROPHYLL CONCENTRATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN OPTICS",
    "variableLevel1": "EXTINCTION COEFFICIENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN OPTICS",
    "variableLevel1": "FLUORESCENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN OPTICS",
    "variableLevel1": "GELBSTOFF"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN OPTICS",
    "variableLevel1": "IRRADIANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN OPTICS",
    "variableLevel1": "OCEAN COLOR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN OPTICS",
    "variableLevel1": "OPTICAL DEPTH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN OPTICS",
    "variableLevel1": "PHOTOSYNTHETICALLY ACTIVE RADIATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN OPTICS",
    "variableLevel1": "RADIANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN OPTICS",
    "variableLevel1": "REFLECTANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN OPTICS",
    "variableLevel1": "SCATTERING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN OPTICS",
    "variableLevel1": "SECCHI DEPTH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN OPTICS",
    "variableLevel1": "TURBIDITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN OPTICS",
    "variableLevel1": "WATER-LEAVING RADIANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN PRESSURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN PRESSURE",
    "variableLevel1": "OCEAN BOTTOM PRESSURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN PRESSURE",
    "variableLevel1": "SEA LEVEL PRESSURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN PRESSURE",
    "variableLevel1": "WATER PRESSURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN TEMPERATURE",
    "variableLevel1": "OCEAN BARRIER LAYER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN TEMPERATURE",
    "variableLevel1": "OCEAN MIXED LAYER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN TEMPERATURE",
    "variableLevel1": "OCEAN TEMPERATURE PROFILES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN TEMPERATURE",
    "variableLevel1": "POTENTIAL TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN TEMPERATURE",
    "variableLevel1": "SEA SURFACE TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN TEMPERATURE",
    "variableLevel1": "SEA SURFACE TEMPERATURE",
    "variableLevel2": "SEA SURFACE  SUBSKIN TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN TEMPERATURE",
    "variableLevel1": "SEA SURFACE TEMPERATURE",
    "variableLevel2": "SEA SURFACE FOUNDATION TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN TEMPERATURE",
    "variableLevel1": "SEA SURFACE TEMPERATURE",
    "variableLevel2": "SEA SURFACE SKIN TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN TEMPERATURE",
    "variableLevel1": "SEA SURFACE TEMPERATURE",
    "variableLevel2": "SEA SURFACE TEMPERATURE ANOMALY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN TEMPERATURE",
    "variableLevel1": "THERMOCLINE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN TEMPERATURE",
    "variableLevel1": "WATER TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN VOLUME BUDGET"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN VOLUME BUDGET",
    "variableLevel1": "ADVECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN VOLUME BUDGET",
    "variableLevel1": "DIFFUSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN VOLUME BUDGET",
    "variableLevel1": "EVAPORATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN VOLUME BUDGET",
    "variableLevel1": "ICE GROWTH/MELT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN VOLUME BUDGET",
    "variableLevel1": "PRECIPITATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN VOLUME BUDGET",
    "variableLevel1": "RUNOFF"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN VOLUME BUDGET",
    "variableLevel1": "SNOW MELT"
  },
  { "category": "EARTH SCIENCE", "topic": "OCEANS", "term": "OCEAN WAVES" },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WAVES",
    "variableLevel1": "GRAVITY WAVES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WAVES",
    "variableLevel1": "ROSSBY/PLANETARY WAVES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WAVES",
    "variableLevel1": "SEA STATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WAVES",
    "variableLevel1": "SEICHES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WAVES",
    "variableLevel1": "SIGNIFICANT WAVE HEIGHT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WAVES",
    "variableLevel1": "STORM SURGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WAVES",
    "variableLevel1": "SURF BEAT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WAVES",
    "variableLevel1": "SWELLS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WAVES",
    "variableLevel1": "SWELLS",
    "variableLevel2": "SWELL DIRECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WAVES",
    "variableLevel1": "SWELLS",
    "variableLevel2": "SWELL HEIGHT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WAVES",
    "variableLevel1": "SWELLS",
    "variableLevel2": "SWELL PERIOD"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WAVES",
    "variableLevel1": "TOPOGRAPHIC WAVES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WAVES",
    "variableLevel1": "TSUNAMIS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WAVES",
    "variableLevel1": "WAVE DIRECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WAVES",
    "variableLevel1": "WAVE FETCH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WAVES",
    "variableLevel1": "WAVE FREQUENCY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WAVES",
    "variableLevel1": "WAVE HEIGHT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WAVES",
    "variableLevel1": "WAVE LENGTH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WAVES",
    "variableLevel1": "WAVE OVERTOPPING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WAVES",
    "variableLevel1": "WAVE PERIOD"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WAVES",
    "variableLevel1": "WAVE RUNUP"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WAVES",
    "variableLevel1": "WAVE SETUP"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WAVES",
    "variableLevel1": "WAVE SPECTRA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WAVES",
    "variableLevel1": "WAVE SPEED"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WAVES",
    "variableLevel1": "WAVE SPEED/DIRECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WAVES",
    "variableLevel1": "WAVE TYPES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WAVES",
    "variableLevel1": "WIND WAVES"
  },
  { "category": "EARTH SCIENCE", "topic": "OCEANS", "term": "OCEAN WINDS" },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WINDS",
    "variableLevel1": "CONVERGENCE/DIVERGENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WINDS",
    "variableLevel1": "SURFACE WINDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WINDS",
    "variableLevel1": "SURFACE WINDS",
    "variableLevel2": "WIND DIRECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WINDS",
    "variableLevel1": "SURFACE WINDS",
    "variableLevel2": "WIND SPEED"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WINDS",
    "variableLevel1": "TURBULENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WINDS",
    "variableLevel1": "VERTICAL WIND MOTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WINDS",
    "variableLevel1": "VORTICITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WINDS",
    "variableLevel1": "WIND CHILL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WINDS",
    "variableLevel1": "WIND SHEAR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WINDS",
    "variableLevel1": "WIND STRESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "OCEAN WINDS",
    "variableLevel1": "WIND VELOCITY/SPEED"
  },
  { "category": "EARTH SCIENCE", "topic": "OCEANS", "term": "PRECIPITATION" },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "PRECIPITATION",
    "variableLevel1": "LIQUID PRECIPITATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "PRECIPITATION",
    "variableLevel1": "LIQUID PRECIPITATION",
    "variableLevel2": "RAIN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "PRECIPITATION",
    "variableLevel1": "SOLID PRECIPITATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "PRECIPITATION",
    "variableLevel1": "SOLID PRECIPITATION",
    "variableLevel2": "SNOW"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SALINITY/DENSITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SALINITY/DENSITY",
    "variableLevel1": "CONDUCTIVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SALINITY/DENSITY",
    "variableLevel1": "CONDUCTIVITY",
    "variableLevel2": "CONDUCTIVITY PROFILES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SALINITY/DENSITY",
    "variableLevel1": "CONDUCTIVITY",
    "variableLevel2": "SURFACE CONDUCTIVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SALINITY/DENSITY",
    "variableLevel1": "DENSITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SALINITY/DENSITY",
    "variableLevel1": "DESALINIZATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SALINITY/DENSITY",
    "variableLevel1": "HALOCLINE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SALINITY/DENSITY",
    "variableLevel1": "OCEAN SALINITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SALINITY/DENSITY",
    "variableLevel1": "OCEAN SALINITY",
    "variableLevel2": "ABSOLUTE SALINITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SALINITY/DENSITY",
    "variableLevel1": "OCEAN SALINITY",
    "variableLevel2": "OCEAN SALINITY BUDGET"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SALINITY/DENSITY",
    "variableLevel1": "OCEAN SALINITY",
    "variableLevel2": "OCEAN SALINITY BUDGET",
    "variableLevel3": "ADVECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SALINITY/DENSITY",
    "variableLevel1": "OCEAN SALINITY",
    "variableLevel2": "OCEAN SALINITY BUDGET",
    "variableLevel3": "DIFFUSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SALINITY/DENSITY",
    "variableLevel1": "OCEAN SALINITY",
    "variableLevel2": "OCEAN SALINITY BUDGET",
    "variableLevel3": "EVAPORATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SALINITY/DENSITY",
    "variableLevel1": "OCEAN SALINITY",
    "variableLevel2": "OCEAN SALINITY BUDGET",
    "variableLevel3": "ICE GROWTH/MELT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SALINITY/DENSITY",
    "variableLevel1": "OCEAN SALINITY",
    "variableLevel2": "OCEAN SALINITY BUDGET",
    "variableLevel3": "PRECIPITATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SALINITY/DENSITY",
    "variableLevel1": "OCEAN SALINITY",
    "variableLevel2": "OCEAN SALINITY BUDGET",
    "variableLevel3": "RUNOFF"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SALINITY/DENSITY",
    "variableLevel1": "OCEAN SALINITY",
    "variableLevel2": "OCEAN SALINITY BUDGET",
    "variableLevel3": "SNOW MELT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SALINITY/DENSITY",
    "variableLevel1": "OCEAN SALINITY",
    "variableLevel2": "OCEAN SALINITY PROFILES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SALINITY/DENSITY",
    "variableLevel1": "OCEAN SALINITY",
    "variableLevel2": "OCEAN SURFACE SALINITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SALINITY/DENSITY",
    "variableLevel1": "OCEAN SALINITY",
    "variableLevel2": "PRACTICAL SALINITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SALINITY/DENSITY",
    "variableLevel1": "OCEAN SALINITY",
    "variableLevel2": "SALT FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SALINITY/DENSITY",
    "variableLevel1": "POTENTIAL DENSITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SALINITY/DENSITY",
    "variableLevel1": "PYCNOCLINE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SALINITY/DENSITY",
    "variableLevel1": "SALINITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SALINITY/DENSITY",
    "variableLevel1": "SALT TRANSPORT"
  },
  { "category": "EARTH SCIENCE", "topic": "OCEANS", "term": "SEA ICE" },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA ICE",
    "variableLevel1": "HEAT FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA ICE",
    "variableLevel1": "ICE DEFORMATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA ICE",
    "variableLevel1": "ICE DEPTH/THICKNESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA ICE",
    "variableLevel1": "ICE DRAFT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA ICE",
    "variableLevel1": "ICE EDGES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA ICE",
    "variableLevel1": "ICE EXTENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA ICE",
    "variableLevel1": "ICE FLOES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA ICE",
    "variableLevel1": "ICE GROWTH/MELT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA ICE",
    "variableLevel1": "ICE ROUGHNESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA ICE",
    "variableLevel1": "ICE TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA ICE",
    "variableLevel1": "ICE TYPES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA ICE",
    "variableLevel1": "ICEBERGS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA ICE",
    "variableLevel1": "ISOTOPES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA ICE",
    "variableLevel1": "LEADS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA ICE",
    "variableLevel1": "PACK ICE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA ICE",
    "variableLevel1": "POLYNYAS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA ICE",
    "variableLevel1": "REFLECTANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA ICE",
    "variableLevel1": "SALINITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA ICE",
    "variableLevel1": "SEA ICE AGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA ICE",
    "variableLevel1": "SEA ICE AREA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA ICE",
    "variableLevel1": "SEA ICE CONCENTRATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA ICE",
    "variableLevel1": "SEA ICE ELEVATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA ICE",
    "variableLevel1": "SEA ICE MOTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA ICE",
    "variableLevel1": "SEA ICE MOTION",
    "variableLevel2": "SEA ICE DIRECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA ICE",
    "variableLevel1": "SEA ICE MOTION",
    "variableLevel2": "SEA ICE SPEED"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA ICE",
    "variableLevel1": "SEA ICE ORIGIN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA ICE",
    "variableLevel1": "SEA ICE TOPOGRAPHY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA ICE",
    "variableLevel1": "SEA ICE VOLUME"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA ICE",
    "variableLevel1": "SEA ICE/OCEAN CLASSIFICATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA ICE",
    "variableLevel1": "SNOW DEPTH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA ICE",
    "variableLevel1": "SNOW MELT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA SURFACE TOPOGRAPHY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA SURFACE TOPOGRAPHY",
    "variableLevel1": "DYNAMIC TOPOGRAPHY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA SURFACE TOPOGRAPHY",
    "variableLevel1": "DYNAMIC TOPOGRAPHY",
    "variableLevel2": "ABSOLUTE DYNAMIC TOPOGRAPHY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA SURFACE TOPOGRAPHY",
    "variableLevel1": "DYNAMIC TOPOGRAPHY",
    "variableLevel2": "MEAN DYNAMIC TOPOGRAPHY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA SURFACE TOPOGRAPHY",
    "variableLevel1": "MEAN SEA SURFACE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA SURFACE TOPOGRAPHY",
    "variableLevel1": "SEA LEVEL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA SURFACE TOPOGRAPHY",
    "variableLevel1": "SEA LEVEL",
    "variableLevel2": "MEAN SEA LEVEL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA SURFACE TOPOGRAPHY",
    "variableLevel1": "SEA LEVEL",
    "variableLevel2": "SEA LEVEL ANOMALY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA SURFACE TOPOGRAPHY",
    "variableLevel1": "SEA SURFACE HEIGHT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA SURFACE TOPOGRAPHY",
    "variableLevel1": "SEA SURFACE HEIGHT",
    "variableLevel2": "SEA SURFACE HEIGHT ANOMALY (SSHA)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "SEA SURFACE TOPOGRAPHY",
    "variableLevel1": "SEA SURFACE SLOPE"
  },
  { "category": "EARTH SCIENCE", "topic": "OCEANS", "term": "TIDES" },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "TIDES",
    "variableLevel1": "STORM SURGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "TIDES",
    "variableLevel1": "TIDAL COMPONENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "TIDES",
    "variableLevel1": "TIDAL CURRENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "TIDES",
    "variableLevel1": "TIDAL HEIGHT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "TIDES",
    "variableLevel1": "TIDAL RANGE"
  },
  { "category": "EARTH SCIENCE", "topic": "OCEANS", "term": "WATER QUALITY" },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "WATER QUALITY",
    "variableLevel1": "HARMFUL ALGAL BLOOM (HABs)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "WATER QUALITY",
    "variableLevel1": "HARMFUL ALGAL BLOOM (HABs)",
    "variableLevel2": "CELL CONCENTRATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "WATER QUALITY",
    "variableLevel1": "HARMFUL ALGAL BLOOM (HABs)",
    "variableLevel2": "TOXIN CONCENTRATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "WATER QUALITY",
    "variableLevel1": "OCEAN CONTAMINANTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "WATER QUALITY",
    "variableLevel1": "SEA SURFACE CONTAMINANTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "OCEANS",
    "term": "WATER QUALITY",
    "variableLevel1": "SEA SURFACE CONTAMINANTS",
    "variableLevel2": "MICROPLASTIC CONCENTRATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "ICE CORE RECORDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "ICE CORE RECORDS",
    "variableLevel1": "CALCIUM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "ICE CORE RECORDS",
    "variableLevel1": "CARBON DIOXIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "ICE CORE RECORDS",
    "variableLevel1": "ELECTRICAL PROPERTIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "ICE CORE RECORDS",
    "variableLevel1": "ICE CORE AIR BUBBLES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "ICE CORE RECORDS",
    "variableLevel1": "IONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "ICE CORE RECORDS",
    "variableLevel1": "IRON"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "ICE CORE RECORDS",
    "variableLevel1": "ISOTOPES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "ICE CORE RECORDS",
    "variableLevel1": "METHANE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "ICE CORE RECORDS",
    "variableLevel1": "NITROUS OXIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "ICE CORE RECORDS",
    "variableLevel1": "PARTICULATE MATTER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "ICE CORE RECORDS",
    "variableLevel1": "POTASSIUM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "ICE CORE RECORDS",
    "variableLevel1": "SODIUM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "ICE CORE RECORDS",
    "variableLevel1": "VELOCITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "ICE CORE RECORDS",
    "variableLevel1": "VOLCANIC DEPOSITS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "LAND RECORDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "LAND RECORDS",
    "variableLevel1": "BOREHOLES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "LAND RECORDS",
    "variableLevel1": "CAVE DEPOSITS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "LAND RECORDS",
    "variableLevel1": "GLACIATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "LAND RECORDS",
    "variableLevel1": "ISOTOPES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "LAND RECORDS",
    "variableLevel1": "LOESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "LAND RECORDS",
    "variableLevel1": "MACROFOSSILS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "LAND RECORDS",
    "variableLevel1": "MICROFOSSILS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "LAND RECORDS",
    "variableLevel1": "PALEOMAGNETIC DATA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "LAND RECORDS",
    "variableLevel1": "PALEOSOLS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "LAND RECORDS",
    "variableLevel1": "PALEOVEGETATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "LAND RECORDS",
    "variableLevel1": "POLLEN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "LAND RECORDS",
    "variableLevel1": "RADIOCARBON"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "LAND RECORDS",
    "variableLevel1": "SEDIMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "LAND RECORDS",
    "variableLevel1": "STRATIGRAPHIC SEQUENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "LAND RECORDS",
    "variableLevel1": "TREE RINGS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "LAND RECORDS",
    "variableLevel1": "TREE RINGS",
    "variableLevel2": "SEA SALT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "LAND RECORDS",
    "variableLevel1": "VOLCANIC DEPOSITS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "OCEAN/LAKE RECORDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "OCEAN/LAKE RECORDS",
    "variableLevel1": "BOREHOLES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "OCEAN/LAKE RECORDS",
    "variableLevel1": "CORAL DEPOSITS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "OCEAN/LAKE RECORDS",
    "variableLevel1": "ISOTOPES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "OCEAN/LAKE RECORDS",
    "variableLevel1": "LAKE LEVELS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "OCEAN/LAKE RECORDS",
    "variableLevel1": "MACROFOSSILS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "OCEAN/LAKE RECORDS",
    "variableLevel1": "MICROFOSSILS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "OCEAN/LAKE RECORDS",
    "variableLevel1": "OXYGEN ISOTOPES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "OCEAN/LAKE RECORDS",
    "variableLevel1": "PALEOMAGNETIC DATA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "OCEAN/LAKE RECORDS",
    "variableLevel1": "POLLEN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "OCEAN/LAKE RECORDS",
    "variableLevel1": "RADIOCARBON"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "OCEAN/LAKE RECORDS",
    "variableLevel1": "SEDIMENT CORE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "OCEAN/LAKE RECORDS",
    "variableLevel1": "SEDIMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "OCEAN/LAKE RECORDS",
    "variableLevel1": "STRATIGRAPHIC SEQUENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "OCEAN/LAKE RECORDS",
    "variableLevel1": "VARVE DEPOSITS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "PALEOCLIMATE RECONSTRUCTIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "PALEOCLIMATE RECONSTRUCTIONS",
    "variableLevel1": "AIR TEMPERATURE RECONSTRUCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "PALEOCLIMATE RECONSTRUCTIONS",
    "variableLevel1": "ATMOSPHERIC CIRCULATION RECONSTRUCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "PALEOCLIMATE RECONSTRUCTIONS",
    "variableLevel1": "DROUGHT/PRECIPITATION RECONSTRUCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "PALEOCLIMATE RECONSTRUCTIONS",
    "variableLevel1": "GROUND WATER RECONSTRUCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "PALEOCLIMATE RECONSTRUCTIONS",
    "variableLevel1": "LAKE LEVEL RECONSTRUCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "PALEOCLIMATE RECONSTRUCTIONS",
    "variableLevel1": "OCEAN SALINITY RECONSTRUCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "PALEOCLIMATE RECONSTRUCTIONS",
    "variableLevel1": "SEA LEVEL RECONSTRUCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "PALEOCLIMATE RECONSTRUCTIONS",
    "variableLevel1": "SEA SURFACE TEMPERATURE RECONSTRUCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "PALEOCLIMATE RECONSTRUCTIONS",
    "variableLevel1": "SOLAR FORCING/INSOLATION RECONSTRUCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "PALEOCLIMATE RECONSTRUCTIONS",
    "variableLevel1": "STREAMFLOW RECONSTRUCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "PALEOCLIMATE",
    "term": "PALEOCLIMATE RECONSTRUCTIONS",
    "variableLevel1": "VEGETATION RECONSTRUCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "EARTH GASES/LIQUIDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "EARTH GASES/LIQUIDS",
    "variableLevel1": "HYDROGEN GAS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "EARTH GASES/LIQUIDS",
    "variableLevel1": "HYDROGEN GAS",
    "variableLevel2": "HYDROGEN GAS VERTICAL/GEOGRAPHIC DISTRIBUTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "EARTH GASES/LIQUIDS",
    "variableLevel1": "NATURAL GAS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "EARTH GASES/LIQUIDS",
    "variableLevel1": "NATURAL GAS",
    "variableLevel2": "NATURAL GAS VERTICAL/GEOGRAPHIC DISTRIBUTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "EARTH GASES/LIQUIDS",
    "variableLevel1": "PETROLEUM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "EARTH GASES/LIQUIDS",
    "variableLevel1": "PETROLEUM",
    "variableLevel2": "MICROFOSSIL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "EARTH GASES/LIQUIDS",
    "variableLevel1": "PETROLEUM",
    "variableLevel2": "PETROLEUM VERTICAL/GEOGRAPHIC DISTRIBUTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "EARTH GASES/LIQUIDS",
    "variableLevel1": "RECLAMATION/REVEGETATION/RESTORATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOCHEMISTRY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOCHEMISTRY",
    "variableLevel1": "BIOGEOCHEMICAL PROCESSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOCHEMISTRY",
    "variableLevel1": "BIOGEOCHEMICAL PROCESSES",
    "variableLevel2": "CHEMICAL DECOMPOSITION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOCHEMISTRY",
    "variableLevel1": "BIOGEOCHEMICAL PROCESSES",
    "variableLevel2": "HYDROLYSIS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOCHEMISTRY",
    "variableLevel1": "BIOGEOCHEMICAL PROCESSES",
    "variableLevel2": "NITRIFICATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOCHEMISTRY",
    "variableLevel1": "GEOCHEMICAL PROCESSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOCHEMISTRY",
    "variableLevel1": "GEOCHEMICAL PROCESSES",
    "variableLevel2": "BIODEGRATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOCHEMISTRY",
    "variableLevel1": "GEOCHEMICAL PROCESSES",
    "variableLevel2": "CARBONATE FORMATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOCHEMISTRY",
    "variableLevel1": "GEOCHEMICAL PROCESSES",
    "variableLevel2": "CHEMICAL FIXATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOCHEMISTRY",
    "variableLevel1": "GEOCHEMICAL PROCESSES",
    "variableLevel2": "CHEMICAL WEATHERING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOCHEMISTRY",
    "variableLevel1": "GEOCHEMICAL PROCESSES",
    "variableLevel2": "DECOMPOSITION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOCHEMISTRY",
    "variableLevel1": "GEOCHEMICAL PROCESSES",
    "variableLevel2": "HYDRATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOCHEMISTRY",
    "variableLevel1": "GEOCHEMICAL PROCESSES",
    "variableLevel2": "ION EXCHANGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOCHEMISTRY",
    "variableLevel1": "GEOCHEMICAL PROCESSES",
    "variableLevel2": "MINERAL DISSOLUTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOCHEMISTRY",
    "variableLevel1": "GEOCHEMICAL PROCESSES",
    "variableLevel2": "OXIDATION/REDUCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOCHEMISTRY",
    "variableLevel1": "GEOCHEMICAL PROPERTIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOCHEMISTRY",
    "variableLevel1": "GEOCHEMICAL PROPERTIES",
    "variableLevel2": "CHEMICAL CONCENTRATIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOCHEMISTRY",
    "variableLevel1": "GEOCHEMICAL PROPERTIES",
    "variableLevel2": "ISOTOPE MEASUREMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOCHEMISTRY",
    "variableLevel1": "GEOCHEMICAL PROPERTIES",
    "variableLevel2": "ISOTOPE RATIOS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOCHEMISTRY",
    "variableLevel1": "GEOCHEMICAL PROPERTIES",
    "variableLevel2": "ISOTOPES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOCHEMISTRY",
    "variableLevel1": "GEOCHEMICAL PROPERTIES",
    "variableLevel2": "ISOTOPIC AGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOCHEMISTRY",
    "variableLevel1": "GEOCHEMICAL PROPERTIES",
    "variableLevel2": "ROCK-EVAL PRYOLYSIS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOCHEMISTRY",
    "variableLevel1": "MARINE GEOCHEMICAL PROCESSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOCHEMISTRY",
    "variableLevel1": "MARINE GEOCHEMICAL PROCESSES",
    "variableLevel2": "CHEMICAL DECOMPOSITION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOCHEMISTRY",
    "variableLevel1": "MARINE GEOCHEMICAL PROCESSES",
    "variableLevel2": "DISSOLUTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOCHEMISTRY",
    "variableLevel1": "MARINE GEOCHEMICAL PROCESSES",
    "variableLevel2": "MINERAL DISSOLUTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEODETICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEODETICS",
    "variableLevel1": "COORDINATE REFERENCE SYSTEM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEODETICS",
    "variableLevel1": "COORDINATE REFERENCE SYSTEM",
    "variableLevel2": "COUNTRY/REGIONAL COORDINATE REFERENCE SYSTEM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEODETICS",
    "variableLevel1": "COORDINATE REFERENCE SYSTEM",
    "variableLevel2": "GLOBAL COORDINATE REFERENCE SYSTEM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEODETICS",
    "variableLevel1": "ELLIPSOID CHARACTERISTICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEODETICS",
    "variableLevel1": "GEOID CHARACTERISTICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMAGNETISM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMAGNETISM",
    "variableLevel1": "ELECTRICAL FIELD"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMAGNETISM",
    "variableLevel1": "ELECTRICAL FIELD",
    "variableLevel2": "ELECTRICAL ANOMALIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMAGNETISM",
    "variableLevel1": "ELECTRICAL FIELD",
    "variableLevel2": "ELECTRICAL INTENSITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMAGNETISM",
    "variableLevel1": "GEOMAGNETIC FORECASTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMAGNETISM",
    "variableLevel1": "GEOMAGNETIC FORECASTS",
    "variableLevel2": "GEOMAGNETIC ACTIVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMAGNETISM",
    "variableLevel1": "GEOMAGNETIC FORECASTS",
    "variableLevel2": "GEOMAGNETIC STORM CATEGORY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMAGNETISM",
    "variableLevel1": "GEOMAGNETIC FORECASTS",
    "variableLevel2": "TOTAL INTENSITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMAGNETISM",
    "variableLevel1": "GEOMAGNETIC INDICES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMAGNETISM",
    "variableLevel1": "GEOMAGNETIC INDICES",
    "variableLevel2": "AA INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMAGNETISM",
    "variableLevel1": "GEOMAGNETIC INDICES",
    "variableLevel2": "AE INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMAGNETISM",
    "variableLevel1": "GEOMAGNETIC INDICES",
    "variableLevel2": "AM INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMAGNETISM",
    "variableLevel1": "GEOMAGNETIC INDICES",
    "variableLevel2": "DST INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMAGNETISM",
    "variableLevel1": "GEOMAGNETIC INDICES",
    "variableLevel2": "KP INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMAGNETISM",
    "variableLevel1": "MAGNETIC FIELD"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMAGNETISM",
    "variableLevel1": "MAGNETIC FIELD",
    "variableLevel2": "GEOMAGNETIC INDUCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMAGNETISM",
    "variableLevel1": "MAGNETIC FIELD",
    "variableLevel2": "MAGNETIC ANOMALIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMAGNETISM",
    "variableLevel1": "MAGNETIC FIELD",
    "variableLevel2": "MAGNETIC DECLINATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMAGNETISM",
    "variableLevel1": "MAGNETIC FIELD",
    "variableLevel2": "MAGNETIC INCLINATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMAGNETISM",
    "variableLevel1": "MAGNETIC FIELD",
    "variableLevel2": "MAGNETIC INTENSITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMAGNETISM",
    "variableLevel1": "PALEOMAGNETISM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMAGNETISM",
    "variableLevel1": "REFERENCE FIELDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN LANDFORMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN LANDFORMS",
    "variableLevel2": "DUNES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN LANDFORMS",
    "variableLevel2": "DUNES",
    "variableLevel3": "CRESCENTIC (BARCHAN/TRANSVERSE) DUNE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN LANDFORMS",
    "variableLevel2": "DUNES",
    "variableLevel3": "DOME DUNE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN LANDFORMS",
    "variableLevel2": "DUNES",
    "variableLevel3": "LONGITUDINAL/LINEAR DUNE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN LANDFORMS",
    "variableLevel2": "DUNES",
    "variableLevel3": "PARABOLIC DUNE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN LANDFORMS",
    "variableLevel2": "DUNES",
    "variableLevel3": "STAR DUNE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN LANDFORMS",
    "variableLevel2": "RIPPLES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN PROCESSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN PROCESSES",
    "variableLevel2": "ABRASION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN PROCESSES",
    "variableLevel2": "ABRASION",
    "variableLevel3": "VENTIFACTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN PROCESSES",
    "variableLevel2": "ABRASION",
    "variableLevel3": "YARDANGS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN PROCESSES",
    "variableLevel2": "DEFLATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN PROCESSES",
    "variableLevel2": "DEGRADATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN PROCESSES",
    "variableLevel2": "SALTATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN PROCESSES",
    "variableLevel2": "SEDIMENT TRANSPORT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN PROCESSES",
    "variableLevel2": "SEDIMENT TRANSPORT",
    "variableLevel3": "LOESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN PROCESSES",
    "variableLevel2": "SEDIMENT TRANSPORT",
    "variableLevel3": "MONADNOCK"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN PROCESSES",
    "variableLevel2": "SEDIMENTATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN PROCESSES",
    "variableLevel2": "SEDIMENTATION",
    "variableLevel3": "SEDIMENT CHEMISTRY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN PROCESSES",
    "variableLevel2": "SEDIMENTATION",
    "variableLevel3": "SEDIMENT COMPOSITION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN PROCESSES",
    "variableLevel2": "SEDIMENTATION",
    "variableLevel3": "STRATIGRAPHIC SEQUENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "AEOLIAN PROCESSES",
    "variableLevel2": "WEATHERING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "BARRIER ISLANDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "BEACHES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "CORAL REEFS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "CORAL REEFS",
    "variableLevel3": "APRON REEF"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "CORAL REEFS",
    "variableLevel3": "ATOLL REEF"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "CORAL REEFS",
    "variableLevel3": "BANK REEF"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "CORAL REEFS",
    "variableLevel3": "BARRIER REEF"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "CORAL REEFS",
    "variableLevel3": "FRINGING REEF"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "CORAL REEFS",
    "variableLevel3": "PATCH REEF"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "CORAL REEFS",
    "variableLevel3": "RIBBON REEF"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "CORAL REEFS",
    "variableLevel3": "TABLE REEF"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "CUSPATE FORELANDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "DELTAS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "DUNES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "DUNES",
    "variableLevel3": "CRESCENTIC (BARCHAN/TRANSVERSE) DUNE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "DUNES",
    "variableLevel3": "DOME DUNE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "DUNES",
    "variableLevel3": "LONGITUDINAL/LINEAR DUNE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "DUNES",
    "variableLevel3": "PARABOLIC DUNE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "DUNES",
    "variableLevel3": "STAR DUNE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "ESTUARIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "FJORDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "HEADLANDS/BAYS/CAPE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "INLETS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "ISTHMUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "LAGOONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "RIA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "SALT MARSH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "SEA ARCHES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "SEA CAVES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "SEA CLIFFS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "SHOALS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "SHORELINES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "SOUNDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "SPITS AND BARS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "TOMBOLOS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL LANDFORMS",
    "variableLevel2": "WAVE-CUT NOTCH/PLATFORMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "ABRASION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "ACCRETION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "ATTRITION/WEATHERING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "CHEMICAL SOLUTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "DEPOSITION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "FLOODING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "HYDRAULIC ACTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "SALTATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "SEA LEVEL CHANGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "SEDIMENT TRANSPORT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "SEDIMENTATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "SEDIMENTATION",
    "variableLevel3": "SEDIMENT CHEMISTRY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "SEDIMENTATION",
    "variableLevel3": "SEDIMENT COMPOSITION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "SEDIMENTATION",
    "variableLevel3": "STRAITRAPHIC SEQUENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "SUBMERGENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "SUBSIDENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "SUSPENSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "WAVE BREAKING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "WAVE DIFFRACTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "WAVE EROSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "WAVE EROSION",
    "variableLevel3": "DEGRADATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "WAVE REFRACTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "COASTAL PROCESSES",
    "variableLevel2": "WAVE SHOALING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "AIT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "BAR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "BAYOU"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "CANYON"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "CONFLUENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "CUTBANK"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "DELTAS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "ENDORHERIC BASIN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "FLOOD PLAIN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "GULLY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "ISLAND"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "LACUSTRINE PLAIN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "MARSH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "MEANDER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "OX-BOW LAKE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "PINGO"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "POINT BAR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "POND"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "RIFFLE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "RIVER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "SPRING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "STREAM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "STREAM TERRACE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "SWAMP"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "VALLEY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "VALLEY",
    "variableLevel3": "V SHAPPED VALLEY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "WATERFALL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL LANDFORMS",
    "variableLevel2": "WATERSHED/DRAINAGE BASINS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL PROCESSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL PROCESSES",
    "variableLevel2": "ABRASION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL PROCESSES",
    "variableLevel2": "ATTRITION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL PROCESSES",
    "variableLevel2": "DEGRADATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL PROCESSES",
    "variableLevel2": "DOWNCUTTING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL PROCESSES",
    "variableLevel2": "ENTRAINMENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL PROCESSES",
    "variableLevel2": "HYDRAULIC ACTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL PROCESSES",
    "variableLevel2": "LANDSLIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL PROCESSES",
    "variableLevel2": "SALTATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL PROCESSES",
    "variableLevel2": "SEDIMENT TRANSPORT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL PROCESSES",
    "variableLevel2": "SEDIMENTATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL PROCESSES",
    "variableLevel2": "SEDIMENTATION",
    "variableLevel3": "SEDIMENT CHEMISTRY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL PROCESSES",
    "variableLevel2": "SEDIMENTATION",
    "variableLevel3": "SEDIMENT COMPOSITION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL PROCESSES",
    "variableLevel2": "SEDIMENTATION",
    "variableLevel3": "STRAITIGRAPHIC SEQUENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL PROCESSES",
    "variableLevel2": "SUSPENSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "FLUVIAL PROCESSES",
    "variableLevel2": "WEATHERING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "ARETES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "CIRQUES/COMBES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "CREVASSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "CREVASSES",
    "variableLevel3": "LONGITUDINAL CREVASSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "CREVASSES",
    "variableLevel3": "MARGINAL CREVASSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "CREVASSES",
    "variableLevel3": "TRANSVERSE CREVASSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "DRUMLINS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "ESKERS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "FJORDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "GLACIAL HORNS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "GLACIER STRIATIONS/GROOVES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "GLACIER/HANGING/U-SHAPED VALLEYS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "GLACIER/ICE CAVES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "ICE-DAMMED LAKES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "KAME DELTA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "KAMES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "KETTLE HOLES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "MORAINES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "MORAINES",
    "variableLevel3": "LATERAL MORAINE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "MORAINES",
    "variableLevel3": "MEDIAL MORAINE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "MORAINES",
    "variableLevel3": "RECESSIONAL MORAINE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "MORAINES",
    "variableLevel3": "RIBBED/ROGAN MORAINE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "MORAINES",
    "variableLevel3": "TERMINAL MORAINE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "NUNATAKS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "OUTWASH FANS/PLAINS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "ROCHE MOUNTONNEES/SHEEPBACK"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "ROCK GLACIERS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL LANDFORMS",
    "variableLevel2": "TILL PLAINS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "ABLATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "ABRASION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "CRUST REBOUND"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "DEGRADATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "DUMPING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "ENTRAINMENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "FIRN FORMATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "FREEZE/THAW"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "FREEZE/THAW",
    "variableLevel3": "BASAL ICE FREEZING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "GLACIAL DISPLACEMENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "GLACIAL DRIFT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "GLACIAL GROWTH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "GLACIAL STRIATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "GLACIER CRUST SUBSIDENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "PERIGLACIAL PROCESSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "PLUCKING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "SCOURING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "SEDIMENT TRANSPORT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "SEDIMENTATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "SEDIMENTATION",
    "variableLevel3": "SEDIMENT CHEMISTRY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "SEDIMENTATION",
    "variableLevel3": "STRATIGRAPHIC SEQUENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "GLACIAL PROCESSES",
    "variableLevel2": "WEATHERING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "KARST LANDFORMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "KARST LANDFORMS",
    "variableLevel2": "CAVES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "KARST LANDFORMS",
    "variableLevel2": "COCKPIT/TOWER KARST"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "KARST LANDFORMS",
    "variableLevel2": "KARST VALLEY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "KARST LANDFORMS",
    "variableLevel2": "SINKHOLES (DOLINES)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "KARST LANDFORMS",
    "variableLevel2": "UVALA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "KARST PROCESSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "KARST PROCESSES",
    "variableLevel2": "CACO3"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "KARST PROCESSES",
    "variableLevel2": "DISSOLVED CO2"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "KARST PROCESSES",
    "variableLevel2": "KARST HYDROLOGY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "KARST PROCESSES",
    "variableLevel2": "KARST HYDROLOGY",
    "variableLevel3": "SUBSURFACE DRAINAGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "KARST PROCESSES",
    "variableLevel2": "POROSITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "KARST PROCESSES",
    "variableLevel2": "WEATHERING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS",
    "variableLevel2": "CALDERA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS",
    "variableLevel2": "CINDER CONE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS",
    "variableLevel2": "FAULTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS",
    "variableLevel2": "FOLDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS",
    "variableLevel2": "GEYSER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS",
    "variableLevel2": "GRABEN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS",
    "variableLevel2": "HORST"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS",
    "variableLevel2": "LAVA DOME"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS",
    "variableLevel2": "LAVA PLAIN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS",
    "variableLevel2": "MAAR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS",
    "variableLevel2": "MOUNTAINS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS",
    "variableLevel2": "PLATEAU"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS",
    "variableLevel2": "RIDGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS",
    "variableLevel2": "RIFT VALLEY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS",
    "variableLevel2": "TUYA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC LANDFORMS",
    "variableLevel2": "VOLCANO"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC PROCESSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC PROCESSES",
    "variableLevel2": "EPEIROGENIC MOVEMENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC PROCESSES",
    "variableLevel2": "ISOSTATIC UPLIFT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC PROCESSES",
    "variableLevel2": "OROGENIC MOVEMENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC PROCESSES",
    "variableLevel2": "RIFTING"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC PROCESSES",
    "variableLevel2": "SUBDUCTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOMORPHIC LANDFORMS/PROCESSES",
    "variableLevel1": "TECTONIC PROCESSES",
    "variableLevel2": "TECTONIC UPLIFT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOTHERMAL DYNAMICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOTHERMAL DYNAMICS",
    "variableLevel1": "GEOTHERMAL ENERGY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOTHERMAL DYNAMICS",
    "variableLevel1": "GEOTHERMAL ENERGY",
    "variableLevel2": "ENERGY DISTRIBUTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOTHERMAL DYNAMICS",
    "variableLevel1": "GEOTHERMAL ENERGY",
    "variableLevel2": "ENERGY OUTPUT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOTHERMAL DYNAMICS",
    "variableLevel1": "GEOTHERMAL TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOTHERMAL DYNAMICS",
    "variableLevel1": "GEOTHERMAL TEMPERATURE",
    "variableLevel2": "AMBIENT TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOTHERMAL DYNAMICS",
    "variableLevel1": "GEOTHERMAL TEMPERATURE",
    "variableLevel2": "TEMPERATURE GRADIENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOTHERMAL DYNAMICS",
    "variableLevel1": "GEOTHERMAL TEMPERATURE",
    "variableLevel2": "TEMPERATURE GRADIENT",
    "variableLevel3": "TEMPERATURE GRADIENT RATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GEOTHERMAL DYNAMICS",
    "variableLevel1": "GEOTHERMAL TEMPERATURE",
    "variableLevel2": "TEMPERATURE PROFILES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GRAVITY/GRAVITATIONAL FIELD"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GRAVITY/GRAVITATIONAL FIELD",
    "variableLevel1": "CONTROL SURVEYS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GRAVITY/GRAVITATIONAL FIELD",
    "variableLevel1": "CRUSTAL MOTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GRAVITY/GRAVITATIONAL FIELD",
    "variableLevel1": "CRUSTAL MOTION",
    "variableLevel2": "ISOSTATIC ADJUSTMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GRAVITY/GRAVITATIONAL FIELD",
    "variableLevel1": "CRUSTAL MOTION",
    "variableLevel2": "OCEAN CRUST DEFORMATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GRAVITY/GRAVITATIONAL FIELD",
    "variableLevel1": "GRAVITATIONAL FIELD"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GRAVITY/GRAVITATIONAL FIELD",
    "variableLevel1": "GRAVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GRAVITY/GRAVITATIONAL FIELD",
    "variableLevel1": "GRAVITY ANOMALIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GRAVITY/GRAVITATIONAL FIELD",
    "variableLevel1": "POLAR MOTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GRAVITY/GRAVITATIONAL FIELD",
    "variableLevel1": "POLAR MOTION",
    "variableLevel2": "ANNUAL ELLIPTICAL COMPONENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GRAVITY/GRAVITATIONAL FIELD",
    "variableLevel1": "POLAR MOTION",
    "variableLevel2": "CHANDLER CIRCULAR COMPONENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GRAVITY/GRAVITATIONAL FIELD",
    "variableLevel1": "ROTATIONAL MOTION/VARIATIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GRAVITY/GRAVITATIONAL FIELD",
    "variableLevel1": "ROTATIONAL MOTION/VARIATIONS",
    "variableLevel2": "ROTATIONAL RATE/SPEED"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GRAVITY/GRAVITATIONAL FIELD",
    "variableLevel1": "ROTATIONAL MOTION/VARIATIONS",
    "variableLevel2": "TIDAL FRICTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GRAVITY/GRAVITATIONAL FIELD",
    "variableLevel1": "SATELLITE ORBITS/REVOLUTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GRAVITY/GRAVITATIONAL FIELD",
    "variableLevel1": "SATELLITE ORBITS/REVOLUTION",
    "variableLevel2": "ANGLE OF ELEVATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GRAVITY/GRAVITATIONAL FIELD",
    "variableLevel1": "SATELLITE ORBITS/REVOLUTION",
    "variableLevel2": "ANGLE OF INCLINATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GRAVITY/GRAVITATIONAL FIELD",
    "variableLevel1": "SATELLITE ORBITS/REVOLUTION",
    "variableLevel2": "ORBIT TYPE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GRAVITY/GRAVITATIONAL FIELD",
    "variableLevel1": "SATELLITE ORBITS/REVOLUTION",
    "variableLevel2": "ORBIT VELOCITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "GRAVITY/GRAVITATIONAL FIELD",
    "variableLevel1": "SATELLITE ORBITS/REVOLUTION",
    "variableLevel2": "ORBITAL POSITION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "AGE DETERMINATIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "BEDROCK LITHOLOGY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "ELEMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "ELEMENTS",
    "variableLevel2": "MAJOR ELEMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "ELEMENTS",
    "variableLevel2": "MINOR ELEMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "ELEMENTS",
    "variableLevel2": "RADIOACTIVE ELEMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "ELEMENTS",
    "variableLevel2": "TRACE ELEMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "GAS HYDRATES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "GAS HYDRATES",
    "variableLevel2": "GAS HYDRATES AGE DETERMINATIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "GAS HYDRATES",
    "variableLevel2": "GAS HYDRATES FORMATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "GAS HYDRATES",
    "variableLevel2": "GAS HYDRATES PHYSICAL/OPTICAL PROPERTIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "GAS HYDRATES",
    "variableLevel2": "GAS HYDRATES PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "CLEAVAGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "GAS HYDRATES",
    "variableLevel2": "GAS HYDRATES PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "COLOR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "GAS HYDRATES",
    "variableLevel2": "GAS HYDRATES PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "COMPOSITION/TEXTURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "GAS HYDRATES",
    "variableLevel2": "GAS HYDRATES PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "ELECTRICAL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "GAS HYDRATES",
    "variableLevel2": "GAS HYDRATES PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "HARDNESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "GAS HYDRATES",
    "variableLevel2": "GAS HYDRATES PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "LUMINESCENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "GAS HYDRATES",
    "variableLevel2": "GAS HYDRATES PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "LUSTER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "GAS HYDRATES",
    "variableLevel2": "GAS HYDRATES PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "REFLECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "GAS HYDRATES",
    "variableLevel2": "GAS HYDRATES PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "SPECIFIC GRAVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "GAS HYDRATES",
    "variableLevel2": "GAS HYDRATES PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "STABILITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "GAS HYDRATES",
    "variableLevel2": "GAS HYDRATES VERTICAL/GEOGRAPHIC DISTRIBUTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "IGNEOUS ROCKS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "IGNEOUS ROCKS",
    "variableLevel2": "IGNEOUS ROCK AGE DETERMINATIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "IGNEOUS ROCKS",
    "variableLevel2": "IGNEOUS ROCK FORMATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "IGNEOUS ROCKS",
    "variableLevel2": "IGNEOUS ROCK PHYSICAL/OPTICAL PROPERTIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "IGNEOUS ROCKS",
    "variableLevel2": "IGNEOUS ROCK PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "CLEAVAGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "IGNEOUS ROCKS",
    "variableLevel2": "IGNEOUS ROCK PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "COLOR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "IGNEOUS ROCKS",
    "variableLevel2": "IGNEOUS ROCK PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "COMPOSITION/TEXTURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "IGNEOUS ROCKS",
    "variableLevel2": "IGNEOUS ROCK PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "ELECTRICAL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "IGNEOUS ROCKS",
    "variableLevel2": "IGNEOUS ROCK PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "HARDNESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "IGNEOUS ROCKS",
    "variableLevel2": "IGNEOUS ROCK PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "LUMINESCENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "IGNEOUS ROCKS",
    "variableLevel2": "IGNEOUS ROCK PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "LUSTER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "IGNEOUS ROCKS",
    "variableLevel2": "IGNEOUS ROCK PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "REFLECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "IGNEOUS ROCKS",
    "variableLevel2": "IGNEOUS ROCK PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "SPECIFIC GRAVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "IGNEOUS ROCKS",
    "variableLevel2": "IGNEOUS ROCK PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "STABILITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "IGNEOUS ROCKS",
    "variableLevel2": "IGNEOUS ROCK VERTICAL/GEOGRAPHIC DISTRIBUTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METALS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METALS",
    "variableLevel2": "METALS AGE DETERMINATIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METALS",
    "variableLevel2": "METALS PHYSICAL/OPTICAL PROPERTIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METALS",
    "variableLevel2": "METALS PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "CLEAVAGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METALS",
    "variableLevel2": "METALS PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "COLOR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METALS",
    "variableLevel2": "METALS PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "COMPOSITION/STRUCTURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METALS",
    "variableLevel2": "METALS PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "ELECTRICAL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METALS",
    "variableLevel2": "METALS PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "HARDNESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METALS",
    "variableLevel2": "METALS PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "LUMINESCENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METALS",
    "variableLevel2": "METALS PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "LUSTER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METALS",
    "variableLevel2": "METALS PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "REFLECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METALS",
    "variableLevel2": "METALS PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "SPECIFIC GRAVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METALS",
    "variableLevel2": "METALS PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "STABILITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METALS",
    "variableLevel2": "METALS VERTICAL/GEOGRAPHIC DISTRIBUTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METAMORPHIC ROCKS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METAMORPHIC ROCKS",
    "variableLevel2": "METAMORPHIC ROCK AGE DETERMINATIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METAMORPHIC ROCKS",
    "variableLevel2": "METAMORPHIC ROCK FORMATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METAMORPHIC ROCKS",
    "variableLevel2": "METAMORPHIC ROCK PHYSICAL/OPTICAL PROPERTIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METAMORPHIC ROCKS",
    "variableLevel2": "METAMORPHIC ROCK PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "CLEAVAGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METAMORPHIC ROCKS",
    "variableLevel2": "METAMORPHIC ROCK PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "COLOR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METAMORPHIC ROCKS",
    "variableLevel2": "METAMORPHIC ROCK PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "COMPOSITION/TEXTURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METAMORPHIC ROCKS",
    "variableLevel2": "METAMORPHIC ROCK PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "ELECTRICIAL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METAMORPHIC ROCKS",
    "variableLevel2": "METAMORPHIC ROCK PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "HARDNESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METAMORPHIC ROCKS",
    "variableLevel2": "METAMORPHIC ROCK PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "LUMINESCENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METAMORPHIC ROCKS",
    "variableLevel2": "METAMORPHIC ROCK PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "LUSTER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METAMORPHIC ROCKS",
    "variableLevel2": "METAMORPHIC ROCK PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "REFLECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METAMORPHIC ROCKS",
    "variableLevel2": "METAMORPHIC ROCK PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "SPECIFIC GRAVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METAMORPHIC ROCKS",
    "variableLevel2": "METAMORPHIC ROCK PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "STABILITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METAMORPHIC ROCKS",
    "variableLevel2": "METAMORPHIC ROCK VERTICAL/GEOGRAPHIC DISTRIBUTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METEORITES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METEORITES",
    "variableLevel2": "METEORITE AGE DETERMINATIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METEORITES",
    "variableLevel2": "METEORITE ORIGIN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METEORITES",
    "variableLevel2": "METEORITE PHYSICAL/OPTICAL PROPERTIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METEORITES",
    "variableLevel2": "METEORITE PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "CLEAVAGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METEORITES",
    "variableLevel2": "METEORITE PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "COLOR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METEORITES",
    "variableLevel2": "METEORITE PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "COMPOSITION/STRUCTURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METEORITES",
    "variableLevel2": "METEORITE PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "ELECTRICAL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METEORITES",
    "variableLevel2": "METEORITE PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "HARDNESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METEORITES",
    "variableLevel2": "METEORITE PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "LUMINESCENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METEORITES",
    "variableLevel2": "METEORITE PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "LUSTER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METEORITES",
    "variableLevel2": "METEORITE PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "REFLECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METEORITES",
    "variableLevel2": "METEORITE PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "SPECIFIC GRAVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METEORITES",
    "variableLevel2": "METEORITE PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "STABILITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METEORITES",
    "variableLevel2": "METEORITE VERTICAL/GEOGRPAHIC DISTRIBUTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "METEORITES",
    "variableLevel2": "METEORITE VERTICAL/GEOGRPAHIC DISTRIBUTION",
    "variableLevel3": "LUSTER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "MINERALS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "MINERALS",
    "variableLevel2": "MINERAL AGE DETERMINATIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "MINERALS",
    "variableLevel2": "MINERAL FORMATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "MINERALS",
    "variableLevel2": "MINERAL PHYSICAL/OPTICAL PROPERTIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "MINERALS",
    "variableLevel2": "MINERAL PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "CLEAVAGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "MINERALS",
    "variableLevel2": "MINERAL PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "COLOR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "MINERALS",
    "variableLevel2": "MINERAL PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "COMPOSITION/TEXTURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "MINERALS",
    "variableLevel2": "MINERAL PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "ELECTRICAL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "MINERALS",
    "variableLevel2": "MINERAL PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "HARDNESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "MINERALS",
    "variableLevel2": "MINERAL PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "LUMINESCENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "MINERALS",
    "variableLevel2": "MINERAL PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "LUSTER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "MINERALS",
    "variableLevel2": "MINERAL PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "REFLECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "MINERALS",
    "variableLevel2": "MINERAL PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "SPECIFIC GRAVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "MINERALS",
    "variableLevel2": "MINERAL PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "STABILITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "MINERALS",
    "variableLevel2": "MINERAL VERTICAL/GEOGRAPHIC DISTRIBUTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "MINERALS",
    "variableLevel2": "MINERALOIDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "NON-METALLIC MINERALS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "NON-METALLIC MINERALS",
    "variableLevel2": "NON-METALLIC MINERAL AGE DETERMINATIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "NON-METALLIC MINERALS",
    "variableLevel2": "NON-METALLIC MINERAL FORMATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "NON-METALLIC MINERALS",
    "variableLevel2": "NON-METALLIC MINERAL PHYSICAL/OPTICAL PROPERTIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "NON-METALLIC MINERALS",
    "variableLevel2": "NON-METALLIC MINERAL PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "CLEAVAGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "NON-METALLIC MINERALS",
    "variableLevel2": "NON-METALLIC MINERAL PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "COLOR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "NON-METALLIC MINERALS",
    "variableLevel2": "NON-METALLIC MINERAL PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "COMPOSITION/TEXTURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "NON-METALLIC MINERALS",
    "variableLevel2": "NON-METALLIC MINERAL PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "ELECTRICAL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "NON-METALLIC MINERALS",
    "variableLevel2": "NON-METALLIC MINERAL PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "HARDNESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "NON-METALLIC MINERALS",
    "variableLevel2": "NON-METALLIC MINERAL PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "LUMINESCENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "NON-METALLIC MINERALS",
    "variableLevel2": "NON-METALLIC MINERAL PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "LUSTER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "NON-METALLIC MINERALS",
    "variableLevel2": "NON-METALLIC MINERAL PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "REFLECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "NON-METALLIC MINERALS",
    "variableLevel2": "NON-METALLIC MINERAL PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "SPECIFIC GRAVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "NON-METALLIC MINERALS",
    "variableLevel2": "NON-METALLIC MINERAL PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "STABILITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "NON-METALLIC MINERALS",
    "variableLevel2": "NON-METALLIC MINERAL VERTICAL/GEOGRAPHIC DISTRIBUTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "SEDIMENTARY ROCKS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "SEDIMENTARY ROCKS",
    "variableLevel2": "COAL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "SEDIMENTARY ROCKS",
    "variableLevel2": "SEDIMENTARY ROCK AGE DETERMINATIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "SEDIMENTARY ROCKS",
    "variableLevel2": "SEDIMENTARY ROCK FORMATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "SEDIMENTARY ROCKS",
    "variableLevel2": "SEDIMENTARY ROCK PHYSICAL/OPTICAL PROPERTIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "SEDIMENTARY ROCKS",
    "variableLevel2": "SEDIMENTARY ROCK PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "CLEAVAGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "SEDIMENTARY ROCKS",
    "variableLevel2": "SEDIMENTARY ROCK PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "COLOR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "SEDIMENTARY ROCKS",
    "variableLevel2": "SEDIMENTARY ROCK PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "COMPOSITION/TEXTURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "SEDIMENTARY ROCKS",
    "variableLevel2": "SEDIMENTARY ROCK PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "ELECTRICAL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "SEDIMENTARY ROCKS",
    "variableLevel2": "SEDIMENTARY ROCK PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "HARDNESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "SEDIMENTARY ROCKS",
    "variableLevel2": "SEDIMENTARY ROCK PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "LUMINESCENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "SEDIMENTARY ROCKS",
    "variableLevel2": "SEDIMENTARY ROCK PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "LUSTER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "SEDIMENTARY ROCKS",
    "variableLevel2": "SEDIMENTARY ROCK PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "REFLECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "SEDIMENTARY ROCKS",
    "variableLevel2": "SEDIMENTARY ROCK PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "SPECIFIC GRAVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "SEDIMENTARY ROCKS",
    "variableLevel2": "SEDIMENTARY ROCK PHYSICAL/OPTICAL PROPERTIES",
    "variableLevel3": "STABILITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "SEDIMENTARY ROCKS",
    "variableLevel2": "SEDIMENTARY ROCK VERTICAL/GEOGRAPHIC DISTRIBUTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "ROCKS/MINERALS/CRYSTALS",
    "variableLevel1": "SEDIMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "CORE PROCESSES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "EARTHQUAKES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "EARTHQUAKES",
    "variableLevel2": "EARTHQUAKE MAGNITUDE/INTENSITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "EARTHQUAKES",
    "variableLevel2": "EARTHQUAKE OCCURRENCES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "EARTHQUAKES",
    "variableLevel2": "EARTHQUAKE PREDICTIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "EARTHQUAKES",
    "variableLevel2": "SEISMIC PROFILE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "EARTHQUAKES",
    "variableLevel2": "SEISMIC PROFILE",
    "variableLevel3": "SEISMIC BODY WAVES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "EARTHQUAKES",
    "variableLevel2": "SEISMIC PROFILE",
    "variableLevel3": "SEISMIC SURFACE WAVES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "NEOTECTONICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "PLATE TECTONICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "PLATE TECTONICS",
    "variableLevel2": "CRUSTAL MOTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "PLATE TECTONICS",
    "variableLevel2": "CRUSTAL MOTION",
    "variableLevel3": "CRUSTAL MOTION DIRECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "PLATE TECTONICS",
    "variableLevel2": "CRUSTAL MOTION",
    "variableLevel3": "CRUSTAL MOTION RATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "PLATE TECTONICS",
    "variableLevel2": "FAULT MOVEMENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "PLATE TECTONICS",
    "variableLevel2": "FAULT MOVEMENT",
    "variableLevel3": "FAULT MOVEMENT DIRECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "PLATE TECTONICS",
    "variableLevel2": "FAULT MOVEMENT",
    "variableLevel3": "FAULT MOVEMENT RATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "PLATE TECTONICS",
    "variableLevel2": "FOLDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "PLATE TECTONICS",
    "variableLevel2": "ISOSTATIC REBOUND"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "PLATE TECTONICS",
    "variableLevel2": "ISOSTATIC REBOUND",
    "variableLevel3": "REBOUND DIRECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "PLATE TECTONICS",
    "variableLevel2": "ISOSTATIC REBOUND",
    "variableLevel3": "REBOUND RATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "PLATE TECTONICS",
    "variableLevel2": "LITHOSPHERIC PLATE MOTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "PLATE TECTONICS",
    "variableLevel2": "LITHOSPHERIC PLATE MOTION",
    "variableLevel3": "PLATE MOTION DIRECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "PLATE TECTONICS",
    "variableLevel2": "LITHOSPHERIC PLATE MOTION",
    "variableLevel3": "PLATE MOTION RATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "PLATE TECTONICS",
    "variableLevel2": "PLATE BOUNDARIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "PLATE TECTONICS",
    "variableLevel2": "STRAIN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "PLATE TECTONICS",
    "variableLevel2": "STRATIGRAPHIC SEQUENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "PLATE TECTONICS",
    "variableLevel2": "STRESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "VOLCANIC ACTIVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "VOLCANIC ACTIVITY",
    "variableLevel2": "ERUPTION DYNAMICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "VOLCANIC ACTIVITY",
    "variableLevel2": "ERUPTION DYNAMICS",
    "variableLevel3": "ASH/DUST COMPOSITION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "VOLCANIC ACTIVITY",
    "variableLevel2": "ERUPTION DYNAMICS",
    "variableLevel3": "ASH/DUST DISPERSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "VOLCANIC ACTIVITY",
    "variableLevel2": "ERUPTION DYNAMICS",
    "variableLevel3": "GAS/AEROSOL COMPOSITION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "VOLCANIC ACTIVITY",
    "variableLevel2": "ERUPTION DYNAMICS",
    "variableLevel3": "GAS/AEROSOL DISPERSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "VOLCANIC ACTIVITY",
    "variableLevel2": "ERUPTION DYNAMICS",
    "variableLevel3": "LAVA COMPOSITION/TEXTURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "VOLCANIC ACTIVITY",
    "variableLevel2": "ERUPTION DYNAMICS",
    "variableLevel3": "LAVA SPEED/FLOW"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "VOLCANIC ACTIVITY",
    "variableLevel2": "ERUPTION DYNAMICS",
    "variableLevel3": "MAGMA COMPOSITION/TEXTURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "VOLCANIC ACTIVITY",
    "variableLevel2": "ERUPTION DYNAMICS",
    "variableLevel3": "MAGMA SPEED/FLOW"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "VOLCANIC ACTIVITY",
    "variableLevel2": "ERUPTION DYNAMICS",
    "variableLevel3": "PYROCLASTIC PARTICAL SIZE DISTRIBUTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "VOLCANIC ACTIVITY",
    "variableLevel2": "ERUPTION DYNAMICS",
    "variableLevel3": "PYROCLASTICS COMPOSITION/TEXTURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "VOLCANIC ACTIVITY",
    "variableLevel2": "ERUPTION DYNAMICS",
    "variableLevel3": "VOLCANIC EXPLOSIVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "VOLCANIC ACTIVITY",
    "variableLevel2": "ERUPTION DYNAMICS",
    "variableLevel3": "VOLCANIC GASES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "VOLCANIC ACTIVITY",
    "variableLevel2": "VOLCANO MAGNITUDE/INTENSITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "VOLCANIC ACTIVITY",
    "variableLevel2": "VOLCANO OCCURRENCES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SOLID EARTH",
    "term": "TECTONICS",
    "variableLevel1": "VOLCANIC ACTIVITY",
    "variableLevel2": "VOLCANO PREDICTIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "GAMMA RAY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "GAMMA RAY",
    "variableLevel1": "GAMMA RAY FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "INFRARED WAVELENGTHS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "INFRARED WAVELENGTHS",
    "variableLevel1": "BRIGHTNESS TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "INFRARED WAVELENGTHS",
    "variableLevel1": "INFRARED FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "INFRARED WAVELENGTHS",
    "variableLevel1": "INFRARED IMAGERY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "INFRARED WAVELENGTHS",
    "variableLevel1": "INFRARED RADIANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "INFRARED WAVELENGTHS",
    "variableLevel1": "REFLECTED INFRARED"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "INFRARED WAVELENGTHS",
    "variableLevel1": "SENSOR COUNTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "INFRARED WAVELENGTHS",
    "variableLevel1": "THERMAL INFRARED"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "LIDAR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "LIDAR",
    "variableLevel1": "APPARENT SURFACE REFLECTIVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "LIDAR",
    "variableLevel1": "LIDAR BACKSCATTER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "LIDAR",
    "variableLevel1": "LIDAR DEPOLARIZATION RATIO"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "LIDAR",
    "variableLevel1": "LIDAR WAVEFORM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "MICROWAVE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "MICROWAVE",
    "variableLevel1": "ANTENNA TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "MICROWAVE",
    "variableLevel1": "BRIGHTNESS TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "MICROWAVE",
    "variableLevel1": "MICROWAVE IMAGERY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "MICROWAVE",
    "variableLevel1": "MICROWAVE RADIANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "MICROWAVE",
    "variableLevel1": "SENSOR COUNTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "PLATFORM CHARACTERISTICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "PLATFORM CHARACTERISTICS",
    "variableLevel1": "AIRSPEED/GROUND SPEED"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "PLATFORM CHARACTERISTICS",
    "variableLevel1": "ATTITUDE CHARACTERISTICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "PLATFORM CHARACTERISTICS",
    "variableLevel1": "CALIBRATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "PLATFORM CHARACTERISTICS",
    "variableLevel1": "DATA SYNCHRONIZATION TIME"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "PLATFORM CHARACTERISTICS",
    "variableLevel1": "FLIGHT DATA LOGS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "PLATFORM CHARACTERISTICS",
    "variableLevel1": "LINE OF SIGHT VELOCITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "PLATFORM CHARACTERISTICS",
    "variableLevel1": "ORBITAL CHARACTERISTICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "PLATFORM CHARACTERISTICS",
    "variableLevel1": "VIEWING GEOMETRY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "RADAR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "RADAR",
    "variableLevel1": "DOPPLER VELOCITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "RADAR",
    "variableLevel1": "MEAN RADIAL VELOCITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "RADAR",
    "variableLevel1": "RADAR BACKSCATTER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "RADAR",
    "variableLevel1": "RADAR BACKSCATTER",
    "variableLevel2": "COMMON MIDPOINT GATHER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "RADAR",
    "variableLevel1": "RADAR CROSS-SECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "RADAR",
    "variableLevel1": "RADAR IMAGERY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "RADAR",
    "variableLevel1": "RADAR REFLECTIVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "RADAR",
    "variableLevel1": "RADAR REFLECTIVITY",
    "variableLevel2": "Two-Way Travel Time"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "RADAR",
    "variableLevel1": "RADIAL VELOCITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "RADAR",
    "variableLevel1": "RETURN POWER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "RADAR",
    "variableLevel1": "SENSOR COUNTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "RADAR",
    "variableLevel1": "SIGMA NAUGHT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "RADAR",
    "variableLevel1": "SPECTRUM WIDTH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "RADIO WAVE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "RADIO WAVE",
    "variableLevel1": "RADIO WAVE FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "SENSOR CHARACTERISTICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "SENSOR CHARACTERISTICS",
    "variableLevel1": "DOME TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "SENSOR CHARACTERISTICS",
    "variableLevel1": "ELECTRICAL PROPERTIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "SENSOR CHARACTERISTICS",
    "variableLevel1": "GEOLOCATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "SENSOR CHARACTERISTICS",
    "variableLevel1": "PHASE AND AMPLITUDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "SENSOR CHARACTERISTICS",
    "variableLevel1": "SINK TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "SENSOR CHARACTERISTICS",
    "variableLevel1": "THERMAL PROPERTIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "SENSOR CHARACTERISTICS",
    "variableLevel1": "TOTAL PRESSURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "SENSOR CHARACTERISTICS",
    "variableLevel1": "TOTAL TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "SENSOR CHARACTERISTICS",
    "variableLevel1": "ULTRAVIOLET SENSOR TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "SENSOR CHARACTERISTICS",
    "variableLevel1": "VIEWING GEOMETRY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "ULTRAVIOLET WAVELENGTHS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "ULTRAVIOLET WAVELENGTHS",
    "variableLevel1": "SENSOR COUNTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "ULTRAVIOLET WAVELENGTHS",
    "variableLevel1": "ULTRAVIOLET FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "ULTRAVIOLET WAVELENGTHS",
    "variableLevel1": "ULTRAVIOLET RADIANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "VISIBLE WAVELENGTHS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "VISIBLE WAVELENGTHS",
    "variableLevel1": "SENSOR COUNTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "VISIBLE WAVELENGTHS",
    "variableLevel1": "VISIBLE FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "VISIBLE WAVELENGTHS",
    "variableLevel1": "VISIBLE IMAGERY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "VISIBLE WAVELENGTHS",
    "variableLevel1": "VISIBLE RADIANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "X-RAY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SPECTRAL/ENGINEERING",
    "term": "X-RAY",
    "variableLevel1": "X-RAY FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "IONOSPHERE/MAGNETOSPHERE DYNAMICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "IONOSPHERE/MAGNETOSPHERE DYNAMICS",
    "variableLevel1": "AURORAE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "IONOSPHERE/MAGNETOSPHERE DYNAMICS",
    "variableLevel1": "ELECTRIC FIELDS/ELECTRIC CURRENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "IONOSPHERE/MAGNETOSPHERE DYNAMICS",
    "variableLevel1": "GEOMAGNETIC FORECASTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "IONOSPHERE/MAGNETOSPHERE DYNAMICS",
    "variableLevel1": "GEOMAGNETIC INDICES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "IONOSPHERE/MAGNETOSPHERE DYNAMICS",
    "variableLevel1": "ION CHEMISTRY/IONIZATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "IONOSPHERE/MAGNETOSPHERE DYNAMICS",
    "variableLevel1": "MAGNETIC FIELDS/MAGNETIC CURRENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "IONOSPHERE/MAGNETOSPHERE DYNAMICS",
    "variableLevel1": "MAGNETIC STORMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "IONOSPHERE/MAGNETOSPHERE DYNAMICS",
    "variableLevel1": "PLASMA WAVES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "IONOSPHERE/MAGNETOSPHERE DYNAMICS",
    "variableLevel1": "SOLAR WIND"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ACTIVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ACTIVITY",
    "variableLevel1": "CORONA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ACTIVITY",
    "variableLevel1": "CORONA HOLES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ACTIVITY",
    "variableLevel1": "CORONAL MASS EJECTIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ACTIVITY",
    "variableLevel1": "COSMIC RAYS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ACTIVITY",
    "variableLevel1": "SOLAR ACTIVE REGIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ACTIVITY",
    "variableLevel1": "SOLAR FLARES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ACTIVITY",
    "variableLevel1": "SOLAR IMAGERY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ACTIVITY",
    "variableLevel1": "SOLAR IRRADIANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ACTIVITY",
    "variableLevel1": "SOLAR OSCILLATIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ACTIVITY",
    "variableLevel1": "SOLAR PROMINENCES/SOLAR FILAMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ACTIVITY",
    "variableLevel1": "SOLAR RADIO WAVE EMISSIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ACTIVITY",
    "variableLevel1": "SOLAR SYNOPTIC MAPS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ACTIVITY",
    "variableLevel1": "SOLAR ULTRAVIOLET EMISSIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ACTIVITY",
    "variableLevel1": "SOLAR VELOCITY FIELDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ACTIVITY",
    "variableLevel1": "SOLAR X-RAY EMISSIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ACTIVITY",
    "variableLevel1": "SUNSPOTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ENERGETIC PARTICLE FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ENERGETIC PARTICLE FLUX",
    "variableLevel1": "ALPHA PARTICLE FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ENERGETIC PARTICLE FLUX",
    "variableLevel1": "ELECTRON FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ENERGETIC PARTICLE FLUX",
    "variableLevel1": "HEAVY NUCLEI FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ENERGETIC PARTICLE FLUX",
    "variableLevel1": "ION FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ENERGETIC PARTICLE FLUX",
    "variableLevel1": "NEUTRAL PARTICLE FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ENERGETIC PARTICLE FLUX",
    "variableLevel1": "PROTON FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ENERGETIC PARTICLE FLUX",
    "variableLevel1": "SUB-ATOMIC PARTICLE FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ENERGETIC PARTICLE FLUX",
    "variableLevel1": "X-RAY FLUX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ENERGETIC PARTICLE PROPERTIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ENERGETIC PARTICLE PROPERTIES",
    "variableLevel1": "ENERGY DEPOSITION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ENERGETIC PARTICLE PROPERTIES",
    "variableLevel1": "PARTICLE COMPOSITION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ENERGETIC PARTICLE PROPERTIES",
    "variableLevel1": "PARTICLE DENSITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ENERGETIC PARTICLE PROPERTIES",
    "variableLevel1": "PARTICLE DISTRIBUTION FUNCTIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ENERGETIC PARTICLE PROPERTIES",
    "variableLevel1": "PARTICLE SPEED"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ENERGETIC PARTICLE PROPERTIES",
    "variableLevel1": "PARTICLE TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "SUN-EARTH INTERACTIONS",
    "term": "SOLAR ENERGETIC PARTICLE PROPERTIES",
    "variableLevel1": "TOTAL ELECTRON CONTENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GLACIERS/ICE SHEETS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "ABLATION ZONES/ACCUMULATION ZONES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "BASINS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "COASTLINE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "FIRN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "FIRN",
    "variableLevel2": "SNOW GRAIN SIZE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "GLACIER ELEVATION/ICE SHEET ELEVATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "GLACIER FACIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "GLACIER MASS BALANCE/ICE SHEET MASS BALANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "GLACIER MOTION/ICE SHEET MOTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "GLACIER THICKNESS/ICE SHEET THICKNESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "GLACIER TOPOGRAPHY/ICE SHEET TOPOGRAPHY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "GLACIERS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "GLACIERS",
    "variableLevel2": "GLACIER EXTENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "GLACIERS",
    "variableLevel2": "GLACIER RUNOFF"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "GLACIERS",
    "variableLevel2": "GLACIER TERMINUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "GLACIERS",
    "variableLevel2": "GROUNDING LINE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "GLACIERS",
    "variableLevel2": "ICE STUPA"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "ICE SHEETS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "ICE SHELVES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GLACIERS/ICE SHEETS",
    "variableLevel1": "ICEBERGS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER FEATURES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER FEATURES",
    "variableLevel2": "AQUIFERS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER FEATURES",
    "variableLevel2": "FRESHWATER SPRINGS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER FEATURES",
    "variableLevel2": "WATER TABLE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER FEATURES",
    "variableLevel2": "WATER TABLE",
    "variableLevel3": "WATER TABLE DEPTH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER PROCESSES/MEASUREMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "AQUIFER RECHARGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "AQUIFER RECHARGE",
    "variableLevel3": "AQUIFER DEPTH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "AQUIFER RECHARGE",
    "variableLevel3": "RECHARGE AMOUNT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "AQUIFER RECHARGE",
    "variableLevel3": "RECHARGE FREQUENCY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "DISCHARGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "DISCHARGE",
    "variableLevel3": "DISCHARGE AMOUNT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "DISCHARGE",
    "variableLevel3": "DISCHARGE RATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "DISPERSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "DISPERSION",
    "variableLevel3": "DISPERSION FREQUENCY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "DISPERSION",
    "variableLevel3": "DISPERSION RATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "DRAINAGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "DRAINAGE",
    "variableLevel3": "DRAINAGE AMOUNT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "DRAINAGE",
    "variableLevel3": "DRAINAGE DIRECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "INFILTRATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "INFILTRATION",
    "variableLevel3": "INFILTRATION AMOUNT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "INFILTRATION",
    "variableLevel3": "INFILTRATION FREQUENCY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "INFILTRATION",
    "variableLevel3": "INFILTRATION RATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "LAND SUBSIDENCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "LAND SUBSIDENCE",
    "variableLevel3": "SUBSIDENCE AMOUNT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "LAND SUBSIDENCE",
    "variableLevel3": "SUBSIDENCE RATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "PERCOLATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "PERCOLATION",
    "variableLevel3": "PERCOLATION AMOUNT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "PERCOLATION",
    "variableLevel3": "PERCOLATION RATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "SALTWATER INTRUSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "SALTWATER INTRUSION",
    "variableLevel3": "INTRUSION AMOUNT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "SALTWATER INTRUSION",
    "variableLevel3": "INTRUSION RATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "SUBSURFACE FLOW"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "SUBSURFACE FLOW",
    "variableLevel3": "AVERAGE FLOW"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "SUBSURFACE FLOW",
    "variableLevel3": "FLOW VELOCITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUND WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "SUBSURFACE FLOW",
    "variableLevel3": "PEAK FLOW"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "GROUND WATER",
    "variableLevel1": "GROUNDWATER CHEMISTRY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SNOW/ICE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "ALBEDO"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "AVALANCHE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "BLOWING SNOW"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "DEPTH HOAR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "FREEZE/THAW"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "FREEZE/THAW",
    "variableLevel2": "TRANSITION DIRECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "FROST"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "ICE DEPTH/THICKNESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "ICE EXTENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "ICE GROWTH/MELT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "ICE MOTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "ICE VELOCITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "LAKE ICE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "LIQUID WATER CONTENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "PERMAFROST"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "RIVER ICE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "SNOW COVER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "SNOW DENSITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "SNOW DEPTH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "SNOW ENERGY BALANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "SNOW FACIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "SNOW MELT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "SNOW STRATIGRAPHY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "SNOW WATER EQUIVALENT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "SNOW/ICE CHEMISTRY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "SNOW/ICE TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SNOW/ICE",
    "variableLevel1": "WHITEOUT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE MASS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE MASS",
    "variableLevel1": "LIQUID WATER EQUIVALENT THICKNESS (LWET)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE MASS",
    "variableLevel1": "MASS BALANCE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE MASS",
    "variableLevel1": "MASS TRANSPORT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER CHEMISTRY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER FEATURES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER FEATURES",
    "variableLevel2": "DRAINAGE BASINS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER FEATURES",
    "variableLevel2": "LAKES/RESERVOIRS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER FEATURES",
    "variableLevel2": "RIVERS/STREAMS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER FEATURES",
    "variableLevel2": "WATER CHANNELS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER FEATURES",
    "variableLevel2": "WETLANDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER PROCESSES/MEASUREMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "AQUIFER RECHARGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "AQUIFER RECHARGE",
    "variableLevel3": "AQUIFER DEPTH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "AQUIFER RECHARGE",
    "variableLevel3": "RECHARGE AMOUNT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "AQUIFER RECHARGE",
    "variableLevel3": "RECHARGE FREQUENCY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "DISCHARGE/FLOW"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "DISCHARGE/FLOW",
    "variableLevel3": "AVERAGE FLOW"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "DISCHARGE/FLOW",
    "variableLevel3": "BASE FLOW"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "DISCHARGE/FLOW",
    "variableLevel3": "FLOW VELOCITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "DISCHARGE/FLOW",
    "variableLevel3": "PEAK FLOW"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "DRAINAGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "DRAINAGE",
    "variableLevel3": "DRAINAGE AMOUNT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "DRAINAGE",
    "variableLevel3": "DRAINAGE DIRECTION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "FLOODS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "FLOODS",
    "variableLevel3": "FLOOD FREQUENCY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "FLOODS",
    "variableLevel3": "FLOOD LEVELS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "HYDROPATTERN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "HYDROPERIOD"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "INUNDATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "INUNDATION",
    "variableLevel3": "INUNDATION AMOUNT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "INUNDATION",
    "variableLevel3": "INUNDATION FREQUENCY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "INUNDATION",
    "variableLevel3": "INUNDATION LEVEL"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "RUNOFF"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "RUNOFF",
    "variableLevel3": "RUNOFF RATE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "RUNOFF",
    "variableLevel3": "TOTAL RUNOFF"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "STAGE HEIGHT"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "TOTAL SURFACE WATER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "WATER DEPTH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "WATER PRESSURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "SURFACE WATER PROCESSES/MEASUREMENTS",
    "variableLevel2": "WATER YIELD"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "WATERSHED CHARACTERISTICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "WATERSHED CHARACTERISTICS",
    "variableLevel2": "WATERSHED BOUNDARIES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "WATERSHED CHARACTERISTICS",
    "variableLevel2": "WATERSHED DRAINAGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "WATERSHED CHARACTERISTICS",
    "variableLevel2": "WATERSHED LENGTH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "WATERSHED CHARACTERISTICS",
    "variableLevel2": "WATERSHED SHAPE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "SURFACE WATER",
    "variableLevel1": "WATERSHED CHARACTERISTICS",
    "variableLevel2": "WATERSHED SLOPE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER BUDGET"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER BUDGET",
    "variableLevel1": "HYDROLOGIC REGIME"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER BUDGET",
    "variableLevel1": "TERRESTRIAL WATER STORAGE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "CONTAMINANTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "CONTAMINANTS",
    "variableLevel2": "ACID RAIN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "CONTAMINANTS",
    "variableLevel2": "ARSENIC"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "CONTAMINANTS",
    "variableLevel2": "BARIUM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "CONTAMINANTS",
    "variableLevel2": "CALCIUM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "CONTAMINANTS",
    "variableLevel2": "CARCINOGENS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "CONTAMINANTS",
    "variableLevel2": "CHROMIUM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "CONTAMINANTS",
    "variableLevel2": "COPPER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "CONTAMINANTS",
    "variableLevel2": "DISINFECTANTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "CONTAMINANTS",
    "variableLevel2": "HARMFUL ALGAL BLOOMS (HABs)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "CONTAMINANTS",
    "variableLevel2": "INORGANIC MATTER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "CONTAMINANTS",
    "variableLevel2": "IRON"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "CONTAMINANTS",
    "variableLevel2": "LEAD"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "CONTAMINANTS",
    "variableLevel2": "MAGNESIUM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "CONTAMINANTS",
    "variableLevel2": "METALS/MINERALS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "CONTAMINANTS",
    "variableLevel2": "ORGANIC MATTER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "CONTAMINANTS",
    "variableLevel2": "PATHOGEN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "CONTAMINANTS",
    "variableLevel2": "PESTICIDES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "CONTAMINANTS",
    "variableLevel2": "PETROLEUM HYDROCARBONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "CONTAMINANTS",
    "variableLevel2": "POTASSIUM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "CONTAMINANTS",
    "variableLevel2": "SELENIUM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "CONTAMINANTS",
    "variableLevel2": "SEWAGE OVERFLOWS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "CONTAMINANTS",
    "variableLevel2": "TITANIUM"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "CONTAMINANTS",
    "variableLevel2": "TOXIC CHEMICALS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "CONTAMINANTS",
    "variableLevel2": "TRACE METALS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "CONTAMINANTS",
    "variableLevel2": "ZINC"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "GASES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "GASES",
    "variableLevel2": "DISSOLVED CARBON DIOXIDE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "GASES",
    "variableLevel2": "DISSOLVED GASES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "GASES",
    "variableLevel2": "DISSOLVED NITROGEN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "GASES",
    "variableLevel2": "DISSOLVED OXYGEN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "ISOTOPES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "ISOTOPES",
    "variableLevel2": "RADIOISOTOPES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "ISOTOPES",
    "variableLevel2": "STABLE ISOTOPES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "NUTRIENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "NUTRIENTS",
    "variableLevel2": "HYDROCARBONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "NUTRIENTS",
    "variableLevel2": "INORGANIC MATTER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "NUTRIENTS",
    "variableLevel2": "NITROGEN"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "NUTRIENTS",
    "variableLevel2": "NITROGEN COMPOUNDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "NUTRIENTS",
    "variableLevel2": "ORGANIC MATTER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "NUTRIENTS",
    "variableLevel2": "PHOSPHOROUS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "SOLIDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "SOLIDS",
    "variableLevel2": "SEDIMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "SOLIDS",
    "variableLevel2": "SUSPENDED SOLIDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "SOLIDS",
    "variableLevel2": "TOTAL DISSOLVED SOLIDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "WATER CHARACTERISTICS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "WATER CHARACTERISTICS",
    "variableLevel2": "ALKALINITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "WATER CHARACTERISTICS",
    "variableLevel2": "BIOCHEMICAL OXYGEN DEMAND (BOD)"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "WATER CHARACTERISTICS",
    "variableLevel2": "CHLOROPHYLL CONCENTRATIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "WATER CHARACTERISTICS",
    "variableLevel2": "CONDUCTIVITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "WATER CHARACTERISTICS",
    "variableLevel2": "EUTROPHICATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "WATER CHARACTERISTICS",
    "variableLevel2": "HYDROCARBONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "WATER CHARACTERISTICS",
    "variableLevel2": "INORGANIC MATTER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "WATER CHARACTERISTICS",
    "variableLevel2": "LIGHT TRANSMISSION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "WATER CHARACTERISTICS",
    "variableLevel2": "NITROGEN COMPOUNDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "WATER CHARACTERISTICS",
    "variableLevel2": "ORGANIC MATTER"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "WATER CHARACTERISTICS",
    "variableLevel2": "PHOSPHOROUS COMPOUNDS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "WATER CHARACTERISTICS",
    "variableLevel2": "POTABILITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "WATER CHARACTERISTICS",
    "variableLevel2": "SALINE CONCENTRATION"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "WATER CHARACTERISTICS",
    "variableLevel2": "TURBIDITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "WATER CHARACTERISTICS",
    "variableLevel2": "WATER COLOR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "WATER CHARACTERISTICS",
    "variableLevel2": "WATER HARDNESS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "WATER CHARACTERISTICS",
    "variableLevel2": "WATER ION CONCENTRATIONS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "WATER CHARACTERISTICS",
    "variableLevel2": "WATER ODOR"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "WATER CHARACTERISTICS",
    "variableLevel2": "WATER TEMPERATURE"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "WATER CHARACTERISTICS",
    "variableLevel2": "WATER TRACE ELEMENTS"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "WATER CHARACTERISTICS",
    "variableLevel2": "pH"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "WATER QUALITY INDEXES"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "WATER QUALITY INDEXES",
    "variableLevel2": "GLOBAL DRINKING WATER QUALITY INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "WATER QUALITY INDEXES",
    "variableLevel2": "INDEX OF BIOTIC INTEGRITY"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "WATER QUALITY INDEXES",
    "variableLevel2": "NATIONAL SANITATION FOUNDATION WATER QUALITY INDEX"
  },
  {
    "category": "EARTH SCIENCE",
    "topic": "TERRESTRIAL HYDROSPHERE",
    "term": "WATER QUALITY/WATER CHEMISTRY",
    "variableLevel1": "WATER QUALITY INDEXES",
    "variableLevel2": "TROPHIC STATE INDEX"
  }
]
