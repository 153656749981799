import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DatasetType } from '@models';

@Component({
    selector: 'app-dataset-publish-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    standalone: false
})
export class DatasetPublishFormComponent {
  public form: FormGroup;
  public DatasetType = DatasetType;

  constructor(public dialogRef: MatDialogRef<DatasetPublishFormComponent>) {
    this.form = new FormGroup({
      type: new FormControl(DatasetType.Public, [Validators.required]),
    });
  }

  public onSubmit() {
    if (!this.form.valid) {
      return;
    }

    this.dialogRef.close(this.form.value.type);
  }

  public onCancel() {
    this.dialogRef.close();
  }
}
