import L from 'leaflet';

export class CursorPositionControl extends L.Control {
  private cursorPositionElement!: HTMLElement;
  private style!: 'latlng' | 'dms';

  override onAdd(map: L.Map) {
    this.style = 'latlng';
    this.cursorPositionElement = L.DomUtil.create(
      'div',
      'npdc-leaflet__cursor-position leaflet-bar',
    );

    this.cursorPositionElement.setAttribute(
      'style',
      'cursor: pointer; display: none',
    );
    this.cursorPositionElement.addEventListener('click', () => {
      this.style = this.style === 'dms' ? 'latlng' : 'dms';
    });

    map.addEventListener('mousemove', (event: L.LeafletMouseEvent) => {
      this.cursorPositionElement.style.display = '';

      let latString, lngString;

      if (this.style === 'dms') {
        latString = this.asDMS(
          event.latlng.lat,
          event.latlng.lat >= 0 ? 'N' : 'S',
        );
        lngString = this.asDMS(
          event.latlng.lng,
          event.latlng.lng >= 0 ? 'E' : 'W',
        );
      } else {
        latString = `Lat: ${event.latlng.lat.toFixed(3)}`;
        lngString = `Lng: ${event.latlng.lng.toFixed(3)}`;
      }

      this.cursorPositionElement.innerText = `${latString} ${lngString}`;
    });

    map.addEventListener('mouseout', () => {
      this.cursorPositionElement.style.display = 'none';
    });

    return this.cursorPositionElement;
  }

  override onRemove() {
    this.cursorPositionElement.remove();
  }

  private asDMS(coordinate: number, cardinalDirection: string): string {
    const _degrees = Math.abs(coordinate);
    const degrees = Math.floor(_degrees);

    const _minutes = (_degrees - degrees) * 60;
    const minutes = Math.floor(_minutes);

    const _seconds = (_minutes - minutes) * 60;
    const seconds = Math.floor(_seconds);

    return `${degrees}°${minutes}'${seconds}"${cardinalDirection}`;
  }
}
