import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Link } from '@models';

@Component({
    selector: 'app-dataset-form-link-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    standalone: false
})
export class DatasetFormLinkFormComponent {
  public linkForm: FormGroup;
  public isEdit: boolean;
  private _defaultRel: string;

  constructor(
    public dialogRef: MatDialogRef<DatasetFormLinkFormComponent>,
    @Inject(MAT_DIALOG_DATA) link: Link,
  ) {
    this.isEdit = !!link;
    this._defaultRel = 'related';

    this.linkForm = new FormGroup({
      href: new FormControl(link?.href, [
        Validators.required,
        Validators.pattern(
          /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#()?&//=]*)$/,
        ),
      ]),
      rel: new FormControl(link ? link.rel : this._defaultRel, [
        Validators.required,
      ]),
      title: new FormControl(link?.title),
    });
  }

  public onSubmit() {
    if (this.linkForm.pristine || !this.linkForm.valid) {
      return;
    }

    const data = {
      href: this.linkForm.value.href,
      rel: this.linkForm.value.rel,
    } as Link;

    if (this.linkForm.value.title) {
      data.title = this.linkForm.value.title;
    }

    this.dialogRef.close(data);
  }

  public onCancel() {
    this.dialogRef.close();
  }
}
