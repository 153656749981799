import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Dataset, DatasetContent, FormState, Link } from '@models';
import { DatasetFormLinkFormComponent } from '../form/form.component';
import { DatasetService, NotificationService } from '@services';
import { BehaviorSubject } from 'rxjs';
import { MatSelectionListChange } from '@angular/material/list';

@Component({
  selector: 'app-dataset-form-link-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  standalone: false,
})
export class DatasetFormLinkListComponent implements OnInit {
  @Input({ required: true })
  dataset!: Dataset;

  @Input({ required: true })
  formState!: BehaviorSubject<FormState>;

  public dataSource: MatTableDataSource<Link>;
  public selection: SelectionModel<Link>;

  constructor(
    private dialog: MatDialog,
    private datasetService: DatasetService,
    private notificationService: NotificationService,
  ) {
    this.dataSource = new MatTableDataSource<Link>();
    this.selection = new SelectionModel<Link>(true, []);
  }

  ngOnInit() {
    this.dataSource.data.push(...(this.dataset.content.links || []));
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected == numRows;
  }

  toggleAllRows() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  public openForm(link?: Link | null) {
    const dialogRef = this.dialog.open(DatasetFormLinkFormComponent, {
      data: link,
      disableClose: true,
      minWidth: '360px',
    });

    dialogRef.afterClosed().subscribe((updatedLink: Link | null) => {
      if (!updatedLink) {
        return;
      }

      const datasetContent = {
        ...this.dataset.content,
      };

      datasetContent.links = [...(datasetContent.links || [])];

      if (link) {
        const index = datasetContent.links.indexOf(link);
        if (index !== -1) {
          datasetContent.links[index] = updatedLink;
        }
      } else {
        datasetContent.links.push(updatedLink);
      }

      this.updateDataset(datasetContent);
    });
  }

  public onDelete() {
    const links = [];
    if (this.dataset.content?.links?.length) {
      for (const link of this.dataset.content.links) {
        const index = this.selection.selected.indexOf(link);
        if (index === -1) {
          links.push(link);
        }
      }
    }

    const datasetContent = {
      ...this.dataset.content,
    };

    if (!links.length) {
      delete datasetContent.links;
    } else {
      datasetContent.links = links;
    }

    this.updateDataset(datasetContent);
  }

  private updateDataset(datasetContent: DatasetContent) {
    this.datasetService
      .updateDataset(this.dataset.id, datasetContent)
      .subscribe({
        next: () => {
          this.dataSource.data = [...(datasetContent.links || [])];
          this.dataset.content = datasetContent;
          this.selection.clear();
        },
        error: (err) => {
          console.error(err);
          this.notificationService.error('Failed to save changes.');
        },
      });
  }

  onListSelectionChange(event: MatSelectionListChange) {
    for (const option of event.options) {
      if (option.selected) {
        this.selection?.select(option.value);
      } else {
        this.selection?.deselect(option.value);
      }
    }
  }
}
