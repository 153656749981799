import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService, NotificationService } from '@services';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: false
})
export class LoginComponent {
  public loginForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<LoginComponent>,
    private authService: AuthService,
    private notificationService: NotificationService,
  ) {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
    });
  }

  public onSubmit() {
    this.loginForm.markAsPending();

    this.authService
      .logIn(this.loginForm.value.email, this.loginForm.value.password)
      .subscribe({
        next: () => {
          this.dialogRef.close(true);
        },
        error: (err) => {
          console.error('login', err);

          if (err.error?.details?.issues[0]?.type === 'LockedAccount') {
            this.notificationService.error('This account is locked!');
          } else {
            this.notificationService.error(
              'Invalid user credentials. Make sure that email and password are correct.',
            );
          }

          for (const control in this.loginForm.controls) {
            this.loginForm.controls[control].setErrors({ invalid: true });
          }
        },
      });
  }
}
