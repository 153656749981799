@switch (dataset().type) {
  @case (DatasetType.Draft) {
    <p>
      <mat-chip>Draft</mat-chip>
      <span> This dataset is not published yet.</span>
    </p>
  }
  @case (DatasetType.Internal) {
    <p>
      <mat-chip>Internal</mat-chip>
      <span>
        This dataset is intended for internal use only and is not publicly
        available.</span
      >
    </p>
  }
  @default {
    <p xmlns:cc="http://creativecommons.org/ns#"
      >This work is licensed under
      <a
        href="https://creativecommons.org/licenses/by/4.0/?ref=chooser-v1"
        target="_blank"
        rel="license noopener noreferrer"
        style="display: inline-block"
        >CC BY 4.0<img
          style="
            height: 22px !important;
            margin-left: 3px;
            vertical-align: text-bottom;
          "
          src="https://mirrors.creativecommons.org/presskit/icons/cc.svg?ref=chooser-v1"
          alt="" /><img
          style="
            height: 22px !important;
            margin-left: 3px;
            vertical-align: text-bottom;
          "
          src="https://mirrors.creativecommons.org/presskit/icons/by.svg?ref=chooser-v1"
          alt="" /></a
    ></p>
  }
}
