import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { PromptComponent } from '@components/prompt/prompt.component';
import { Breadcrumb, Dataset, DatasetType, RecordListResponse } from '@models';
import {
  DatasetService,
  NotificationService,
  RecordService,
  TitleService,
} from '@services';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  map,
  switchMap,
} from 'rxjs';
import { DatasetPublishFormComponent } from '../publish-form/form.component';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-dataset-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
  standalone: false,
})
export class DatasetItemComponent implements OnInit, OnDestroy {
  @ViewChild('publishPromptTemplate')
  publishPromptTemplateRef!: TemplateRef<unknown>;

  public DatasetType = DatasetType;
  public breadcrumbs: Breadcrumb[] = [
    {
      title: 'Home',
      url: '/',
    },
    {
      title: 'Datasets',
      url: '/dataset',
    },
    {
      title: '',
      url: '',
    },
  ];
  public dataset$: BehaviorSubject<Dataset>;
  public isLandscape$?: Observable<boolean>;
  public hasRecords$: Observable<boolean>;

  private subscriptions: Subscription[];

  constructor(
    private route: ActivatedRoute,
    private clipboard: Clipboard,
    private dialog: MatDialog,
    private titleService: TitleService,
    private notificationService: NotificationService,
    private router: Router,
    private datasetService: DatasetService,
    private recordsService: RecordService,
  ) {
    this.subscriptions = [];

    const dataset = route.snapshot.data['dataset'];
    this.dataset$ = new BehaviorSubject<Dataset>(dataset);
    this.hasRecords$ = this.dataset$.pipe(
      switchMap((dataset: Dataset) => {
        return this.recordsService
          .getRecords(dataset.id, null, 0, 1, false, null)
          .pipe(
            map((response: RecordListResponse) => response.items.length !== 0),
          );
      }),
    );
  }

  ngOnInit() {
    this.subscriptions.push(
      this.route.data.subscribe((data: Data) => {
        const dataset = data['dataset'];
        this.dataset$.next(dataset);
      }),
    );

    this.subscriptions.push(
      this.dataset$.subscribe((dataset: Dataset) => {
        this.breadcrumbs[2].title = dataset.content.title;
        this.breadcrumbs[2].url = `/dataset/${dataset.id}`;

        this.titleService.setTitle(dataset.content.title);
      }),
    );
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  deleteDataset(id: string) {
    const dialogRef = this.dialog.open(PromptComponent, {
      data: {
        title: 'Delete dataset',
        content:
          'Confirm to delete this dataset. This action can not be undone and all data will be lost.',
      },
      minWidth: '360px',
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (!result) {
        return;
      }

      this.datasetService.deleteDataset(id).subscribe({
        next: () => {
          this.notificationService.success('Dataset deleted successfully.');
          this.router.navigate(['/dataset']);
        },
        error: (err) => {
          console.error(err);
          this.notificationService.error('Failed to delete dataset.');
        },
      });
    });
  }

  publishDataset(dataset: Dataset) {
    const dialogRef = this.dialog.open(DatasetPublishFormComponent, {
      minWidth: '360px',
    });

    dialogRef.afterClosed().subscribe((result: DatasetType) => {
      if (!result) {
        return;
      }

      this.datasetService
        .publishDataset(dataset.id, result, dataset.content)
        .subscribe({
          next: () => {
            this.datasetService
              .getDataset(dataset.id)
              .subscribe((updatedDataset: Dataset) => {
                this.dataset$.next(updatedDataset);
              });

            this.notificationService.success('Dataset published successfully.');
          },
          error: (err) => {
            if (err?.status === 422 && err.error?.details) {
              this.router
                .navigate(['dataset', dataset.id, 'edit'], {
                  state: {
                    issues: err.error.details?.issues || [],
                  },
                })
                .finally(() => {
                  this.notificationService.error(
                    'Failed to publish due to incomplete form data.',
                  );
                });

              return;
            }

            console.error(err);
            this.notificationService.error('Failed to publish dataset.');
          },
        });
    });
  }

  public copyToClipboard(text: string) {
    if (this.clipboard.copy(text)) {
      this.notificationService.success('Citation copied to clipboard.');
    } else {
      this.notificationService.warning('Failed to access clipboard.');
    }
  }
}
