import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { User } from '@models';
import { AuthService } from '@services';
import { Observable, Subscription, filter, map } from 'rxjs';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
    standalone: false
})
export class SidenavComponent implements OnInit {
  public currentRoute: string;
  public currentUser$: Observable<User | null>;
  public isLinksExpanded: boolean;
  public isResourcesExpanded: boolean;

  private subscriptions: Subscription[];

  constructor(
    private router: Router,
    public authService: AuthService,
  ) {
    this.subscriptions = [];
    this.currentRoute = '';
    this.isLinksExpanded = false;
    this.isResourcesExpanded = false;

    this.currentUser$ = this.authService.user$;
  }

  ngOnInit() {
    const urlSubscription = this.router.events
      .pipe(
        filter((e: Event) => e instanceof NavigationEnd),
        map((e: Event) => {
          return (e as NavigationEnd).url;
        }),
      )
      .subscribe((url: string) => {
        this.currentRoute = url;
      });

    this.subscriptions.push(urlSubscription);
  }

  parseAccessLevel(accessLevel: string): string {
    switch (accessLevel) {
      case 'admin':
        return 'Administrator';
      case 'internal':
        return 'Internal';
      default:
        return 'External';
    }
  }

  logOut() {
    this.authService.logOut();
    this.router.navigateByUrl('/dataset');
  }
}
