import L from 'leaflet';

export class FeatureCountControl extends L.Control {
  private containerElement!: HTMLElement;

  constructor() {
    super();
    this.containerElement = L.DomUtil.create(
      'div',
      'npdc-leaflet__cursor-position leaflet-bar',
    );
  }

  override onAdd() {
    return this.containerElement;
  }

  override onRemove() {
    this.containerElement.remove();
  }

  public set(value: number) {
    this.containerElement.innerText = value + '';
  }
}
