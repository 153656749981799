import { Permission } from '@models';
import geojson from 'geojson';

export enum DatasetType {
  Draft = 'draft',
  Public = 'public',
  Internal = 'internal',
}

export interface Dataset {
  id: string;
  doi: string;

  type: DatasetType;

  geometry: string;
  bbox: number[];
  isDeleted: boolean;

  content: DatasetContent;

  createdBy: string;
  modifiedBy: string;
  publishedBy: string;
  created: string;
  modified: string;
  published: string;

  permissions: Permission | null | undefined;
}

export interface DatasetContent {
  summary?: string;
  title: string;
  keywords?: ScienceKeyword[];
  harvesters?: Harvester[];
  links?: Link[];
  geojson?: geojson.FeatureCollection;
  people?: Person[];
  organisations?: Organisation[];
  timeframes?: Timeframe[];
}

export interface ScienceKeyword {
  category: string;
  topic: string;
  term: string;
  variableLevel1: string;
  variableLevel2: string;
  variableLevel3: string;
  detailedVariable: string;
}

export interface Harvester {
  set: string;
  url: string;
}

export interface Link {
  href: string;
  title?: string;
  rel: string;
}

export interface Person {
  firstName: string;
  lastName: string;
  roles: string[];
  organisation?: string;
  email?: string;
  orcid?: string;
  url?: string;
}

export interface Organisation {
  name: string;
  roles: string[];
  email?: string;
  url?: string;
}

export interface Timeframe {
  startDate: string;
  endDate: string;
}
