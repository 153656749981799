<div class="npdc-table">
  @if (records$ | async; as records) {
    @if (records.length) {
      <mat-action-list>
        @for (record of records; track record.id) {
          <mat-list-item lines="3" (click)="openRecord(record)">
            <mat-icon matListItemIcon>
              @switch (record.type) {
                @case ('station') {
                  cell_tower
                }
                @default {
                  data_object
                }
              }
            </mat-icon>

            <span matListItemTitle>{{
              record.content['title'] || record.id
            }}</span>

            <span matListItemLine>
              {{ record.type }}
            </span>
            <span matListItemLine>
              {{ record.modified | date: 'yyyy-MM-dd HH:mm:ss' }}
            </span>
          </mat-list-item>
        }
      </mat-action-list>
    } @else {
      <div class="npdc-empty-list">There are no items to diplay.</div>
    }
  }

  <mat-paginator
    [pageSizeOptions]="[10, 20, 50]"
    (page)="loadPage($event, false)"
    showFirstLastButtons></mat-paginator>
</div>
