import { Component } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import L from 'leaflet';
import geojson from 'geojson';

@Component({
  selector: 'app-geojson-form',
  templateUrl: './geojson-form.component.html',
  styleUrls: ['./geojson-form.component.scss'],
  standalone: false,
})
export class GeoJSONFormComponent {
  public form: FormGroup;

  constructor(public dialogRef: MatDialogRef<GeoJSONFormComponent>) {
    this.form = new FormGroup({
      points: new FormControl('', {
        validators: [Validators.required, this.validInputValidator()],
        updateOn: 'change',
      }),
    });

    this.form.controls['points'].markAsTouched();
  }

  public onSubmit() {
    if (this.form.pristine || !this.form.valid) {
      return;
    }

    const polygon = this.tryParseGeometry(this.form.value.points);
    this.dialogRef.close(polygon);
  }

  public onCancel() {
    this.dialogRef.close();
  }

  private tryParseGeometry(value: string): geojson.GeoJsonObject | null {
    const lines = value.split('\n');
    const coordinates: geojson.Position[] = [];
    for (let i = 0; i < lines.length; i++) {
      const [_lat, _lng] = lines[i].split(',');

      const lat = _lat ? Number(_lat) : NaN;
      const lng = _lng ? Number(_lng) : NaN;

      if (Math.abs(lat) > 90) {
        throw `Line ${i + 1}: Latitude must be within -90 to 90 range.`;
      }

      if (Math.abs(lng) > 180) {
        throw `Line ${i + 1}: Longitude must be within -180 to 180 range.`;
      }

      try {
        const point = new L.LatLng(lat, lng);
        coordinates.push(L.GeoJSON.latLngToCoords(point));
      } catch (err) {
        throw `Line ${i + 1}: Invalid latitude/longitude.`;
      }
    }

    let obj: geojson.GeoJsonObject | null;

    switch (coordinates.length) {
      case 0:
        obj = null;
        break;
      case 1:
        obj = {
          type: 'Point',
          coordinates: coordinates[0],
        } as geojson.Point;
        break;
      default:
        // Check if it's a LineString or Polygon based on the first and last coordinates
        if (
          coordinates.length === 2 ||
          coordinates[0][0] !== coordinates[coordinates.length - 1][0] ||
          coordinates[0][1] !== coordinates[coordinates.length - 1][1]
        ) {
          obj = {
            type: 'LineString',
            coordinates: coordinates,
          } as geojson.LineString;
        } else {
          // It's a Polygon if the first and last coordinates match and there are more than 2 coordinates
          obj = {
            type: 'Polygon',
            coordinates: [coordinates],
          } as geojson.Polygon;
        }
    }

    return obj;
  }

  private validInputValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      try {
        this.tryParseGeometry(control.value);
        return null;
      } catch (err) {
        return { invalidInput: err as string };
      }
    };
  }
}
