<mat-form-field appearance="outline">
  <mat-label>Title</mat-label>
  <input type="text" matInput [formControl]="title" />
  @if (title.hasError('required')) {
    <mat-error>This field is required.</mat-error>
  }
</mat-form-field>

<app-form-control-markdown label="Summary" [formControl]="summary" />

<app-form-control-autocomplete
  label="Keywords"
  [provider]="autocompleteProvider"
  [multiple]="true"
  [formControl]="keywords" />
