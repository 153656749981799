import { Component, input } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { Dataset, DatasetType } from '@models';

@Component({
  selector: 'app-dataset-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.scss'],
  imports: [MatChipsModule],
})
export class DatasetLicenseComponent {
  DatasetType = DatasetType;
  dataset = input.required<Dataset>();
}
