import { Pipe, PipeTransform } from '@angular/core';
import { Timeframe } from '@models';

// transforms a list of timeframes into a single year range string (e.g. 2020 - 2021)
@Pipe({
    name: 'yearRangeString',
    standalone: false
})
export class YearRangeStringPipe implements PipeTransform {
  public transform(values: Timeframe[]): string {
    if (!values?.length) {
      return '';
    }

    const minYear = Math.min(
      ...values.map((v) => new Date(v.startDate).getFullYear()),
    );
    const maxYear = Math.max(
      ...values.map((v) => new Date(v.endDate).getFullYear()),
    );

    if (minYear === maxYear) {
      return String(minYear);
    }
    if (Number.isNaN(maxYear)) {
      return `${minYear} –`;
    }
    return `${minYear} – ${maxYear}`;
  }
}
