import { Component } from '@angular/core';
import { Breadcrumb } from '@models';
import { environment } from '@environment';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss'],
    standalone: false
})
export class AboutComponent {
  public buildId: string;
  public breadcrumbs: Breadcrumb[] = [
    {
      title: 'Home',
      url: '/',
    },
    {
      title: 'About',
      url: '/about',
    },
  ];

  constructor() {
    this.buildId = environment.buildId;
  }
}
