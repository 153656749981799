import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, forwardRef } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { AutocompleteOption, AutocompleteOptionProvider } from '@models';
import {
  BehaviorSubject,
  Observable,
  filter,
  map,
  of,
  switchMap,
  tap,
} from 'rxjs';

@Component({
    selector: 'app-form-control-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormControlAutocompleteComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: FormControlAutocompleteComponent,
            multi: true,
        },
    ],
    standalone: false
})
export class FormControlAutocompleteComponent
  implements OnInit, ControlValueAccessor, Validator
{
  @Input({ required: true })
  label = '';

  @Input({ required: true })
  provider!: AutocompleteOptionProvider<unknown>;

  @Input()
  multiple = false;

  public chipGridFormControl: FormControl;
  public inputFormControl: FormControl;

  public selectedOptions: AutocompleteOption<unknown>[];
  public filteredOptions$: Observable<AutocompleteOption<unknown>[]>;
  public isRequired$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private onChange: ((value: AutocompleteOption<unknown>[]) => void) | null;
  private onTouched: (() => void) | null;

  constructor() {
    this.filteredOptions$ = of([]);
    this.chipGridFormControl = new FormControl([]);
    this.inputFormControl = new FormControl();
    this.selectedOptions = [];

    this.onChange = null;
    this.onTouched = null;
  }

  ngOnInit() {
    this.filteredOptions$ = this.inputFormControl.valueChanges.pipe(
      filter((value: string) => {
        return typeof value === 'string';
      }),
      tap(() => {
        if (this.onTouched) {
          this.onTouched();
        }
      }),
      switchMap((value: string) => {
        if (value.length < 3) {
          return [];
        }

        return this.provider(value);
      }),
      map((values: AutocompleteOption<unknown>[]) => {
        return values.filter(
          (option: AutocompleteOption<unknown>) =>
            this.findIndex(option) === -1,
        );
      }),
    );
  }

  onOptionSelected(option: AutocompleteOption<unknown>) {
    const index = this.findIndex(option);
    if (index !== -1) {
      return;
    }

    if (this.multiple) {
      this.selectedOptions.push(option);
    } else {
      this.selectedOptions[0] = option;
    }
    this.onValueChange();
  }

  onOptionRemoved(option: AutocompleteOption<unknown>) {
    const index = this.findIndex(option);
    if (index === -1) {
      return;
    }

    this.selectedOptions.splice(index, 1);
    this.onValueChange();
  }

  onOptionDropped(event: CdkDragDrop<AutocompleteOption<unknown>[]>) {
    const otherOption = this.selectedOptions[event.currentIndex];
    const thisOption = this.selectedOptions[event.previousIndex];

    this.selectedOptions[event.currentIndex] = thisOption;
    this.selectedOptions[event.previousIndex] = otherOption;

    this.onValueChange();
  }

  private findIndex(option: AutocompleteOption<unknown>): number {
    for (let i = 0; i < this.selectedOptions.length; i++) {
      if (this.selectedOptions[i].id === option.id) {
        return i;
      }
    }

    return -1;
  }

  // implements ControlValueAccessor
  writeValue(values: AutocompleteOption<unknown>[]): void {
    this.selectedOptions = [];
    this.chipGridFormControl.setValue([]);

    for (const value of values) {
      if (!value?.displayValue || !value?.displayValue) {
        continue;
      }

      this.selectedOptions.push(value);
      this.chipGridFormControl.value.push(value.displayValue);
    }
  }

  registerOnChange(fn: (value: AutocompleteOption<unknown>[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  // end implements ControlValueAccessor

  // implements Validator
  validate(control: AbstractControl): ValidationErrors | null {
    if (control.hasValidator(Validators.required)) {
      this.isRequired$.next(true);
    }

    this.chipGridFormControl.setValidators(control.validator);

    if (control.touched) {
      this.chipGridFormControl.markAllAsTouched();
    }

    return null;
  }
  // end implements Validator

  private onValueChange() {
    if (this.onChange) {
      this.onChange(this.selectedOptions);
    }

    this.chipGridFormControl.setValue(
      this.selectedOptions.map((o) => o.displayValue),
    );
  }
}
