import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { EmailDomainValidator } from '@validators';

@Component({
    selector: 'app-attachment-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    standalone: false
})
export class UserFormComponent {
  public form: FormGroup;

  constructor(public dialogRef: MatDialogRef<UserFormComponent>) {
    this.form = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        EmailDomainValidator,
      ]),
    });
  }

  public onSubmit() {
    if (this.form.pristine || !this.form.valid) {
      return;
    }

    this.dialogRef.close(this.form.value.email);
  }

  public onCancel() {
    this.dialogRef.close(null);
  }
}
