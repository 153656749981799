import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PrefixValidator } from '@validators';
import { Attachment } from '@models';

@Component({
    selector: 'app-attachment-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    standalone: false
})
export class AttachmentFormComponent {
  public form: FormGroup;
  public isEdit: boolean;

  constructor(
    public dialogRef: MatDialogRef<AttachmentFormComponent>,
    @Inject(MAT_DIALOG_DATA) private attachment: Attachment,
  ) {
    this.isEdit = !!attachment;

    this.form = new FormGroup({
      filename: new FormControl(attachment?.filename, [Validators.required]),
      prefix: new FormControl(attachment?.prefix, [
        Validators.required,
        PrefixValidator,
      ]),
      title: new FormControl(attachment?.title, []),
      description: new FormControl(attachment?.description, []),
      releaseDate: new FormControl(attachment?.released, []),
    });
  }

  public onSubmit() {
    if (this.form.pristine || !this.form.valid) {
      return;
    }

    const values = this.form.value;

    this.dialogRef.close({
      ...this.attachment,
      filename: values.filename,
      prefix: values.prefix,
      title: values.title,
      description: values.description,
      released: values.releaseDate,
    } as Attachment);
  }

  public onCancel() {
    this.dialogRef.close();
  }
}
