import { ThemeStyle } from '@services';
import L from 'leaflet';
import 'proj4leaflet';

export enum ProjectionCode {
  Arctic = 'EPSG3575',
  Mercator = 'EPSG3857',
  Antarctic = 'EPSG3031',
}

export interface Projection {
  code: ProjectionCode;
  tilesetSource: string;
  tilesetStyle: TilesetStyle;
  tileSize: number;
  className: string;
  center: L.LatLngExpression;
  minZoom: number;
  maxZoom: number;
  tileBounds: L.LatLngBounds | undefined;
  viewBounds: L.LatLngBoundsExpression;
  zoomOffset: number;
  crs: L.CRS;
}

const minZoom = 2;
const maxZoom = 15;
const tileSize = 512;

const getResolutions = (extent: number): number[] => {
  const resolutions = [];
  for (let i = 0; i < maxZoom; i++) {
    const resolution = extent / tileSize / Math.pow(2, i - 1);
    resolutions.push(resolution);
  }
  return resolutions;
};

// extents are from the table at https://tile.gbif.org/ui/
const arcticExtent = 9009964.78821664;
const arcticResolutions = getResolutions(arcticExtent);
const antarcticExtent = 12367396.2185;
const antarcticResolutions = getResolutions(antarcticExtent);

export enum TilesetStyle {
  Dark = 'gbif-tuatara',
  Light = 'osm-bright-en',
}

export function getProjection(
  code: ProjectionCode,
  theme: ThemeStyle = ThemeStyle.Light,
): Projection {
  const style =
    theme === ThemeStyle.Light ? TilesetStyle.Light : TilesetStyle.Dark;

  switch (code) {
    case ProjectionCode.Arctic:
      return {
        code: code,
        tilesetSource: `https://tile.gbif.org/3575/omt/{z}/{x}/{y}@1x.png?style=${style}`,
        tilesetStyle: style,
        tileSize: tileSize,
        className: 'npdc-leaflet__arctic_projection',
        center: [90, 0] as L.LatLngExpression,
        minZoom: minZoom,
        maxZoom: 14,
        zoomOffset: 0,
        viewBounds: [
          [-75, -35],
          [-78, 145],
        ] as L.LatLngBoundsExpression,
        tileBounds: undefined,
        crs: new L.Proj.CRS(
          'EPSG:3575',
          '+proj=laea +lat_0=90 +lon_0=10 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs',
          {
            origin: [-arcticExtent, arcticExtent],
            bounds: L.bounds([
              [-arcticExtent, arcticExtent],
              [arcticExtent, -arcticExtent],
            ]),
            resolutions: arcticResolutions,
          },
        ),
      };
    case ProjectionCode.Antarctic:
      return {
        code: code,
        tilesetSource: `https://tile.gbif.org/3031/omt/{z}/{x}/{y}@1x.png?style=${style}`,
        tilesetStyle: style,
        tileSize: tileSize,
        className: 'npdc-leaflet__antarctic_projection',
        center: [-90, -90] as L.LatLngExpression,
        minZoom: minZoom,
        maxZoom: 14,
        zoomOffset: 0,
        viewBounds: [
          [19.5, -135],
          [19.5, 45],
        ] as L.LatLngBoundsExpression,
        tileBounds: undefined,
        crs: new L.Proj.CRS(
          'EPSG:3031',
          '+proj=stere +lat_0=-90 +lat_ts=-71 +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs',
          {
            origin: [-antarcticExtent, antarcticExtent],
            bounds: L.bounds([
              [-antarcticExtent, antarcticExtent],
              [antarcticExtent, -antarcticExtent],
            ]),
            resolutions: antarcticResolutions,
          },
        ),
      };
    default:
      return {
        code: ProjectionCode.Mercator,
        tilesetSource: `https://tile.gbif.org/3857/omt/{z}/{x}/{y}@1x.png?style=${style}`,
        tilesetStyle: style,
        tileSize: tileSize,
        className: 'npdc-leaflet__mercator_projection',
        center: [0, 0] as L.LatLngExpression,
        minZoom: minZoom,
        maxZoom: maxZoom,
        tileBounds: L.latLngBounds(L.latLng(85, 180), L.latLng(-85, -180)),
        viewBounds: [
          [85, 180],
          [-85, -180],
        ] as L.LatLngBoundsExpression,
        zoomOffset: -1, // required for the tiles to work properly
        crs: L.CRS.EPSG3857,
      } as Projection;
  }
}
