@if (dataset$ | async; as dataset) {
  <div class="npdc-layout">
    <div class="npdc-layout__toolbar">
      <app-breadcrumb [breadcrumbs]="breadcrumbs">
        @if (dataset.permissions?.mayUpdate) {
          @if (dataset.type === DatasetType.Draft) {
            <button
              class="npdc-toolbar-button npdc-important__badge"
              mat-flat-button
              (click)="publishDataset(dataset)"
              >Publish</button
            >
            <button
              class="npdc-toolbar-button"
              mat-stroked-button
              [routerLink]="'edit'"
              >Edit</button
            >
          } @else {
            <button
              class="npdc-toolbar-button"
              mat-flat-button
              [routerLink]="'edit'"
              >Edit</button
            >
          }
          @if (dataset.permissions?.mayDelete) {
            <button
              mat-icon-button
              [matMenuTriggerFor]="optionsMenu"
              matTooltip="More actions">
              <mat-icon>more_vert</mat-icon>
            </button>
          }
        }
      </app-breadcrumb>
    </div>

    <div class="npdc-layout__content">
      <h1 class="npdc-title">{{ dataset.content.title }}</h1>

      <mat-tab-group
        animationDuration="0ms"
        dynamicHeight
        disablePagination="true">
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="npdc-tab__icon">article_outlined</mat-icon>
            Metadata
          </ng-template>

          <ng-template matTabContent>
            <div class="grid">
              <div class="grid__top">
                <app-dataset-citation
                  [dataset$]="dataset$"></app-dataset-citation>
              </div>

              @if (dataset.content.geojson; as geometry) {
                <div class="grid__mid">
                  <h2 class="grid__mid__title">Geographical coverage</h2>
                  <div class="npdc-leaflet" style="max-width: 960px">
                    <app-leaflet-group
                      [geometry]="geometry"></app-leaflet-group>
                  </div>
                </div>
              }

              <div class="grid__bot">
                <h2>Summary</h2>
                <div
                  class="npdc-markdown"
                  [innerHtml]="dataset.content.summary | markdown"></div>

                <ng-container [ngTemplateOutlet]="datasetDetails">
                </ng-container>
              </div>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="npdc-tab__icon">database</mat-icon>
            Files
          </ng-template>

          <ng-template matTabContent>
            <app-attachment-list [dataset$]="dataset$"></app-attachment-list>
          </ng-template>
        </mat-tab>
        @if ((hasRecords$ | async) === true) {
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon class="npdc-tab__icon">account_tree</mat-icon>
              Records
            </ng-template>

            <ng-template matTabContent>
              <app-record-list [dataset$]="dataset$"></app-record-list>
            </ng-template>
          </mat-tab>
        }
      </mat-tab-group>
    </div>
  </div>

  <ng-template #datasetDetails>
    <!-- links -->
    @if (dataset.content.links?.length) {
      <h2>Links</h2>
      <mat-list>
        @for (link of dataset.content.links; track link) {
          <mat-list-item>
            <mat-icon matListItemIcon>link</mat-icon>
            <span matListItemTitle>
              <a href="{{ link.href }}" target="_blank">{{
                link.title || link.href
              }}</a>
              ({{ link.rel | titlecase }})</span
            >
          </mat-list-item>
        }
      </mat-list>
    }

    <!-- keywords -->
    @if (dataset.content.keywords?.length) {
      <h2>Keywords</h2>
      <mat-list>
        @for (keyword of dataset.content.keywords; track keyword) {
          <mat-list-item>
            <mat-icon matListItemIcon>label</mat-icon>
            <div matListItemTitle>
              @if (keyword.category) {
                <span>{{ keyword.category }}</span>
              }
              @if (keyword.topic) {
                <span> > {{ keyword.topic }}</span>
              }
              @if (keyword.term) {
                <span> > {{ keyword.term }}</span>
              }
              @if (keyword.variableLevel1) {
                <span> > {{ keyword.variableLevel1 }}</span>
              }
              @if (keyword.variableLevel2) {
                <span> > {{ keyword.variableLevel2 }}</span>
              }
              @if (keyword.variableLevel3) {
                <span> > {{ keyword.variableLevel3 }}</span>
              }
            </div>
          </mat-list-item>
        }
      </mat-list>
    }

    <!-- timeframes -->
    @if (dataset.content.timeframes?.length) {
      <h2>Timeframes</h2>
      <mat-list>
        @for (timeframe of dataset.content.timeframes; track timeframe) {
          <mat-list-item>
            <mat-icon matListItemIcon>timeline</mat-icon>
            <div matListItemTitle>
              <b>Start</b>: {{ timeframe.startDate }}
            </div>
            @if (timeframe.endDate) {
              <div matListItemLine> <b>End</b>: {{ timeframe.endDate }} </div>
            }
          </mat-list-item>
        }
      </mat-list>
    }

    <!-- contributors -->
    @if (dataset.content.people?.length) {
      <h2>Contributors</h2>
      <mat-list>
        @for (person of dataset.content.people; track person) {
          <mat-list-item>
            <mat-icon matListItemIcon>person</mat-icon>
            <div matListItemTitle
              >{{ person.firstName }} {{ person.lastName }}</div
            >
            @if (person.email) {
              <div matListItemLine>{{ person.email }}</div>
            }
            <div matListItemLine>
              @for (role of person.roles; track role; let last = $last) {
                @switch (role) {
                  @case ('author') {
                    <span>Author</span>
                  }
                  @case ('editor') {
                    <span>Editor</span>
                  }
                  @case ('pointOfContact') {
                    <span>Point of Contact</span>
                  }
                  @case ('principalInvestigator') {
                    <span>Principal Investigator</span>
                  }
                  @case ('processor') {
                    <span>Processor</span>
                  }
                  @default {
                    <span>{{ role }}</span>
                  }
                }
                @if (!last) {
                  <span>, </span>
                }
              }
            </div>
            <div matListItemMeta>
              <button
                mat-icon-button
                matTooltip="Actions"
                [matMenuTriggerFor]="contributorActionsMenu"
                [matMenuTriggerData]="{ person: person }">
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
          </mat-list-item>
        }
      </mat-list>
    }

    <!-- organisations -->
    @if (dataset.content.organisations?.length) {
      <h2>Organisations</h2>
      <mat-list>
        @for (
          organisation of dataset.content.organisations;
          track organisation
        ) {
          <mat-list-item>
            <mat-icon matListItemIcon>group</mat-icon>
            <div matListItemTitle>{{ organisation.name }}</div>
            @if (organisation.email) {
              <div matListItemLine>{{ organisation.email }}</div>
            }
            <div matListItemLine>
              @for (role of organisation.roles; track role; let last = $last) {
                @switch (role) {
                  @case ('author') {
                    <span>Author</span>
                  }
                  @case ('originator') {
                    <span>Originator</span>
                  }
                  @case ('owner') {
                    <span>Owner</span>
                  }
                  @case ('pointOfContact') {
                    <span>Point of Contact</span>
                  }
                  @case ('principalInvestigator') {
                    <span>Principal Investigator</span>
                  }
                  @case ('processor') {
                    <span>Processor</span>
                  }
                  @case ('resourceProvider') {
                    <span>Resource Provider</span>
                  }
                  @default {
                    <span>{{ role }}</span>
                  }
                }
                @if (!last) {
                  <span>, </span>
                }
              }
            </div>
            <div matListItemMeta>
              <button
                mat-icon-button
                matTooltip="Actions"
                [matMenuTriggerFor]="organisationActionsMenu"
                [matMenuTriggerData]="{ organisation: organisation }">
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
          </mat-list-item>
        }
      </mat-list>
    }

    <!-- metadata -->
    <h2>Other</h2>
    <ng-container [ngTemplateOutlet]="datasetMetadata"> </ng-container>
  </ng-template>

  <ng-template #datasetMetadata>
    <mat-list>
      @if (dataset.type !== DatasetType.Internal) {
        <mat-list-item>
          <div matListItemTitle>DOI</div>
          <div matListItemMeta>
            @if (dataset.type === DatasetType.Draft) {
              <mat-chip style="display: inline-flex !important"
                >Inactive</mat-chip
              >
            }
          </div>
          <div matListItemLine>
            <a href="https://doi.org/{{ dataset.doi }}" target="_blank">{{
              dataset.doi
            }}</a>
          </div>
          @if (dataset.type === DatasetType.Draft) {
            <div matListItemLine
              >This DOI will be assigned to the dataset when published.</div
            >
          }
        </mat-list-item>
      }
      @if (dataset.type !== DatasetType.Draft) {
        <mat-list-item>
          <div matListItemTitle>Published</div>
          <div matListItemLine
            >{{ dataset.published | date: 'yyyy-MM-dd' }} by
            @if (dataset.publishedBy | resolveUser | async; as user) {
              <a href="mailto:{{ user.email }}">{{ user.email }}</a>
            } @else {
              {{ dataset.publishedBy }}
            }
          </div>
        </mat-list-item>
      }
      <mat-list-item>
        <div matListItemTitle>Created</div>
        <div matListItemLine
          >{{ dataset.created | date: 'yyyy-MM-dd' }} by
          @if (dataset.createdBy | resolveUser | async; as user) {
            <a href="mailto:{{ user.email }}">{{ user.email }}</a>
          } @else {
            {{ dataset.createdBy }}
          }
        </div>
      </mat-list-item>
      <mat-list-item>
        <div matListItemTitle>Modified</div>
        <div matListItemLine
          >{{ dataset.modified | date: 'yyyy-MM-dd' }} by
          @if (dataset.modifiedBy | resolveUser | async; as user) {
            <a href="mailto:{{ user.email }}">{{ user.email }}</a>
          } @else {
            {{ dataset.modifiedBy }}
          }
        </div>
      </mat-list-item>
    </mat-list>
  </ng-template>

  <mat-menu #contributorActionsMenu>
    <ng-template matMenuContent let-person="person">
      @if (person.url) {
        <a mat-menu-item href="{{ person.url }}" target="_blank"
          >Go to website</a
        >
      }
      @if (person.orcid) {
        <a
          mat-menu-item
          href="https://orcid.org/{{ person.orcid }}"
          target="_blank"
          >Go to ORCID page</a
        >
      }
      @if (person.email) {
        <a mat-menu-item href="mailto:{{ person.email }}" target="_blank"
          >Send email</a
        >
      }
      <a
        mat-menu-item
        [routerLink]="['/dataset']"
        [queryParams]="{ q: person.firstName + ' ' + person.lastName }"
        >Show datasets from this person</a
      >
    </ng-template>
  </mat-menu>
  <mat-menu #organisationActionsMenu>
    <ng-template matMenuContent let-organisation="organisation">
      @if (organisation.url) {
        <a mat-menu-item href="{{ organisation.url }}" target="_blank">
          Go to website
        </a>
      }
      @if (organisation.email) {
        <a mat-menu-item href="mailto:{{ organisation.email }}" target="_blank">
          Send email
        </a>
      }
      <a
        mat-menu-item
        [routerLink]="['/dataset']"
        [queryParams]="{ q: organisation.name }">
        Show datasets from this organisation
      </a>
    </ng-template>
  </mat-menu>
  <mat-menu #optionsMenu>
    <button mat-menu-item [routerLink]="['/dataset', dataset.id, 'permission']"
      >Manage permissions</button
    >
    @if (dataset.type === DatasetType.Draft) {
      <button mat-menu-item (click)="deleteDataset(dataset.id)">Delete</button>
    }
  </mat-menu>
}
