import { Component, computed, input } from '@angular/core';
import { Dataset, Person } from '@models';

@Component({
  selector: 'app-dataset-citation',
  templateUrl: './citation.component.html',
  styleUrls: ['./citation.component.scss'],
})
export class DatasetCitationComponent {
  type = input<'apa' | 'short'>('apa');
  dataset = input.required<Dataset>();
  url = computed(() => `https://doi.org/${this.dataset().doi.toUpperCase()}`);
  publicationYear = computed(() => this.dataset().published.substring(0, 4));

  authors = computed(() => {
    const dataset = this.dataset();
    const people = dataset.content.people
      ?.filter((p) => p.roles.includes('author'))
      .map((p: Person) => {
        const initials = p.firstName
          .split(' ')
          .filter((name) => name)
          .map((name) => name[0] + '.')
          .join(' ');

        return `${p.lastName}, ${initials}`;
      });

    if (people?.length) {
      return people;
    }

    return (
      dataset.content.organisations
        ?.filter((o) => o.roles.includes('author'))
        .map((o) => o.name) || []
    );
  });

  authorsString = computed(() => {
    const authors = this.authors();
    if (authors.length < 2) {
      return authors.at(0) || '';
    }

    const lim = 20;
    const separator = authors.length > lim ? '...' : '&';
    const last = authors.at(-1);

    const end = authors.length > lim ? lim - 1 : authors.length - 1;
    const result = `${authors.slice(0, end).join(', ')}, ${separator} ${last}`;

    return result;
  });

  public apa(): string {
    return `${this.authorsString()} (${this.publicationYear}). ${this.dataset().content.title} [Dataset]. Norwegian Polar Institute. ${this.url()}`;
  }
}
