import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { marked, Tokens } from 'marked';
import katex from 'katex';

class CustomRenderer extends marked.Renderer {
  public latex: { [key: string]: string };

  constructor() {
    super();

    this.latex = {};
  }

  override heading(heading: Tokens.Heading): string {
    return `<h${heading.depth + 2}>${heading.text}</h${heading.depth + 2}>`;
  }

  override code(code: Tokens.Code): string {
    if (code.lang !== 'latex') {
      return super.code(code);
    }

    return this.generateLatexSelector(code.text, true);
  }

  override codespan(codespan: Tokens.Codespan): string {
    if (!codespan.text.startsWith('latex ')) {
      return super.codespan(codespan);
    }

    return this.generateLatexSelector(codespan.text.substring(6), false);
  }

  private generateLatexSelector(text: string, displayMode: boolean): string {
    const selector = `<br class="latex_${new Date().getTime()}_${Object.keys(this.latex).length}">`;
    const html = katex.renderToString(text, {
      displayMode: displayMode,
      throwOnError: false,
      errorColor: '',
      output: 'html',
      trust: false,
    });

    this.latex[selector] = html;

    return selector;
  }
}

// transforms a string containing Markdown syntax to SafeHtml
@Pipe({
    name: 'markdown',
    standalone: false
})
export class MarkdownPipe implements PipeTransform {
  private renderer: CustomRenderer;

  constructor(private sanitizer: DomSanitizer) {
    this.renderer = new CustomRenderer();
  }

  transform(value: string | null | undefined): SafeHtml {
    if (!value) {
      return '';
    }

    const html = marked.parse(value, {
      renderer: this.renderer,
    }) as string;

    let sanitizedHtml =
      this.sanitizer.sanitize(SecurityContext.HTML, html) || '';

    // replace latex selectors with HTML
    for (const selector of Object.keys(this.renderer.latex)) {
      const latexHtml = this.renderer.latex[selector];
      sanitizedHtml = sanitizedHtml.replace(selector, latexHtml);
    }

    // bypass any additional sanitation performed by angular
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedHtml);
  }
}
