import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { Breadcrumb, Dataset } from '@models';
import { TitleService } from '@services';
import { Observable, of, Subscription, switchMap } from 'rxjs';

@Component({
    selector: 'app-permission-page',
    templateUrl: './page.component.html',
    styleUrls: ['./page.component.scss'],
    standalone: false
})
export class PermissionPageComponent implements OnInit, OnDestroy {
  public breadcrumbs: Breadcrumb[] = [
    {
      title: 'Home',
      url: '/',
    },
    {
      title: 'Datasets',
      url: '/dataset',
    },
  ];

  public dataset$: Observable<Dataset | null>;

  private datasetSubscription: Subscription | null;

  constructor(
    private route: ActivatedRoute,
    private titleService: TitleService,
  ) {
    this.dataset$ = this.route.data.pipe(
      switchMap((data: Data) => {
        return of(data['dataset'] || null);
      }),
    );

    this.datasetSubscription = null;
  }

  ngOnInit() {
    this.datasetSubscription = this.dataset$.subscribe(
      (dataset: Dataset | null) => {
        if (this.breadcrumbs.length > 2) {
          this.breadcrumbs = this.breadcrumbs.slice(0, 2);
        }

        if (dataset) {
          this.breadcrumbs.push({
            title: dataset.content.title,
            url: '/dataset/' + dataset.id,
          });

          this.breadcrumbs.push({
            title: 'Permissions',
            url: '/dataset/' + dataset.id + '/permissions',
          });

          this.titleService.setTitle('Permissions', dataset.content.title);
        }

        return of(dataset);
      },
    );
  }

  ngOnDestroy(): void {
    this.datasetSubscription?.unsubscribe();
  }
}
